/**
 * @file      NotFound.js
 *
 * @brief     404 route controller.
 *
 * @copyright Copyright Dexdyne Ltd. 2023-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { Component, forwardRef } from 'react';

import { withStyles } from '@material-ui/core';

import { PAGE_TITLE_PREFIX } from 'helpers/globalConstants';

import AppHeader from 'global_components/AppHeader';

import styles from 'global_styles/PageRootStyles';

import NotFoundCard from './NotFoundCard';

const ForwardReferencedAppHeader = forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AppHeader scrollRef={ref} {...props} />
));

/**
 * React class-based component.
 */
class NotFound extends Component {
    constructor() {
        super();

        /* Create ref for parent div. */
        this.myRef = React.createRef();
    }

    /**
     * Lifecycle methods.
     */
    componentDidMount() {
        document.title = `Not Found | ${PAGE_TITLE_PREFIX}`;
    }

    render() {
        const { classes, pageTitle, userPrivileges } = this.props;
        const { dashboard } = userPrivileges;

        return (
            <div ref={this.myRef} className={classes.root}>
                <div className={classes.header}>
                    <ForwardReferencedAppHeader
                        ref={this.myRef}
                        titleMsg={pageTitle}
                        userLoggedIn
                        dashName={dashboard}
                    />
                </div>

                <div className={classes.main}>
                    <NotFoundCard />
                </div>
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}   pageTitle       Browser tab title.
 * @param {Array}    userPrivileges  Parsed token claims.
 * @param {Object}   classes         JSS classes from withStyles.
 */
NotFound.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    userPrivileges: PropTypes.shape({
        dashboard: PropTypes.string.isRequired,
        installations: PropTypes.arrayOf(PropTypes.number).isRequired,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,

    classes: PropTypes.shape({
        root: PropTypes.string,
        header: PropTypes.string,
        main: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(NotFound);
