/**
 * @file      ParameterTableStyles.js
 *
 * @brief     ParameterTable component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    const PARAM_TABLE_MIN_WIDTH = 320;
    const PARAM_TABLE_MAX_HEIGHT = 463; // 7 Rows

    return {
        root: {
            marginTop: 16,
            marginBottom: 16,
            marginLeft: 16,
        },

        titleText: {
            textTransform: 'uppercase',
            paddingBottom: 8,
        },

        container: {
            minHeight: 200,
            maxHeight: PARAM_TABLE_MAX_HEIGHT,

            overflowX: 'hidden',
            // overflowY: 'overlay',

            /**
             * Media query-based width.
             * Extra Large devices (>=1600px) is the default. Order is important.
             */
            // table head plus 6 rows
            width: 480,
            [sizes.widthMax('xs')]: {
                width: PARAM_TABLE_MIN_WIDTH,
            },
        },

        table: {
            backgroundColor: theme.palette.background.paper,

            '& .MuiTableCell-body': {
                color: theme.palette.text.primary,
                fontSize: theme.typography.sizes.tableBody,
            },

            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
                backgroundColor: theme.palette.table.darkRow,
            },
            '& .MuiTableRow-root:hover': {
                backgroundColor: theme.palette.table.darkRow,
            },
        },
        tableInnerShadow: {
            position: 'relative',
            zIndex: 10,

            height: 20,
            minHeight: 20,
            marginTop: -20,
            width: '100%',

            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            /* Define table bottom on tablet/mobile. */
            [sizes.widthMax('sm')]: {
                background: `
                    linear-gradient(0deg, rgba(0,0,0,0.16), rgba(0,0,0,0) 8%),
                    linear-gradient(0deg, rgba(0,0,0,0.14), rgba(0,0,0,0) 25%),
                    linear-gradient(0deg, rgba(0,0,0,0.12), rgba(0,0,0,0) 52%)
                    `,
            },
            // transform: 'skewX(-10deg)',
            // mixBlendMode: 'multiply',
        },

        tableHeadRow: {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',

            '& .MuiTableCell-head': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.table.headRow,
                // backgroundColor: '#fafafa', // material-ui default table head
                fontSize: theme.typography.sizes.tableHead,
            },
        },

        /* Head cell formatting. */
        tickboxHead: {
            width: 84,
            minWidth: 84,

            '& span:nth-of-type(1) svg': {
                color: theme.palette.primary.main,
            },

            '& span:nth-of-type(2) svg': {
                marginLeft: -10,
            },

            '& .MuiButtonBase-root': {
                padding: 8,
            },

            '& .MuiTouchRipple-root': {
                color: theme.palette.primary.transparent,
            },
        },
        parameterHead: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            borderBottom: 'none',
        },

        filterForm: {
            // Try to account for scrollbar
            paddingRight: 12,

            [sizes.widthMax('xs')]: {
                width: 200,
                paddingRight: 16,
                marginLeft: '-12px',
            },

            '& input': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.table.darkRow,
                [sizes.widthMax('xs')]: {
                    paddingLeft: 0,
                },
            },

            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },

            '& input::placeholder': {
                textTransform: 'uppercase',
            },

            '& .MuiFilledInput-underline:after': {
                borderBottomWidth: '0.2em',
            },

            '& .Mui-focused:after': {
                borderBottomColor: theme.palette.primary.main,
            },
        },

        /* Cell formatting. */
        tickboxCell: {
            width: 84,
            minWidth: 84,

            '& span:nth-of-type(1) svg': {
                color: theme.palette.primary.main,
            },

            '& span:nth-of-type(2) svg': {
                marginLeft: -10,
            },

            '& .MuiButtonBase-root': {
                padding: 8,
            },

            '& .MuiTouchRipple-root': {
                color: theme.palette.primary.transparent,
            },
        },
        parameterCell: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        /* Hidden aria label for column ordering. */
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    };
};

export default styles;
