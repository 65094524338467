/**
 * @file      EventLog.js
 *
 * @brief     EventLog route controller.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { Component, forwardRef } from 'react';

import { withStyles } from '@material-ui/core';

import {
    PAGE_TITLE_PREFIX,
    API_ROUTES,
    utcStringFromDate,
    apiRoutesForInstallation,
    fetchAuthorisedResource,
    tokenErrorsReturned,
    parseResponseErrorsToString,
} from 'helpers/globalConstants';

import AppHeader from 'global_components/AppHeader';
import FlashMessage from 'global_components/FlashMessage';

import styles from 'global_styles/PageRootStyles';

import EventLogGrid from './EventLogGrid';

const ForwardReferencedAppHeader = forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AppHeader scrollRef={ref} {...props} />
));

/**
 * React class-based component.
 */
class EventLog extends Component {
    constructor(props) {
        super(props);
        /* Cancel all subscriptions and asynchronous tasks in componentWillUnmount method.
         * https://stackoverflow.com/questions/52061476/
         */
        this._isMounted = false;

        /* Create ref for parent div. */
        this.myRef = React.createRef();

        /**
         * URL parameter 'id' is an integer - validated by react-router.
         * If it does not correspond to an (authorised) installation,
         *   the API request will fail and we can display an error.
         */
        const { match } = this.props;
        const installationId = parseInt(match.params.id, 10);
        this.API_ROUTES_FOR_INSTALLATION = { ...API_ROUTES, ...apiRoutesForInstallation(installationId) };

        this.state = {
            id: installationId,
            name: null,
            rctType: null,
            /* Objects returned directly from API calls. */
            installation: {},
            eventlogs: [],
            types: [],
            /* API requests status. */
            loaded: false,
            flashError: '',
        };
        this.fetchInitialState = this.fetchInitialState.bind(this);
        this.fetchEventlogData = this.fetchEventlogData.bind(this);
        this.handleFlashMsgUpdate = this.handleFlashMsgUpdate.bind(this);
        this.clearFlashErrorMsg = this.clearFlashErrorMsg.bind(this);
    }

    /**
     * Lifecycle methods.
     */
    componentDidMount() {
        const { pageTitle } = this.props;
        document.title = `${pageTitle} | ${PAGE_TITLE_PREFIX}`;
        this._isMounted = true;
        this.fetchInitialState();
    }

    componentDidUpdate(prevProps, prevState) {
        const { pageTitle } = this.props;
        const { name, loaded } = this.state;

        /* Successfully loaded initial state. */
        if (loaded && !prevState.loaded) {
            document.title = `${name} ${pageTitle} | ${PAGE_TITLE_PREFIX}`;
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Fetch initial data from API. Set Component state.
     */
    fetchInitialState = () => {
        const {
            initialApiRoutes,
            token,
            history,
        } = this.props;

        /**
         * Make multiple requests in parallel.
         * Promise.all() will resolve when all of the input promises have resolved.
         */
        const apiResponses = Promise.all(initialApiRoutes.map((route) => {
            const apiRoutesKey = Object.values(route)[0]; // each apiRoute object contains exactly one key-value pair.
            return fetchAuthorisedResource(this.API_ROUTES_FOR_INSTALLATION[apiRoutesKey], token);
        }));

        apiResponses.then((responses) => {
            /* Create arrays parallel to apiResponses. */
            const stateKeys = initialApiRoutes.map((route) => Object.keys(route)[0]);
            const apiRouteKeys = initialApiRoutes.map((route) => Object.values(route)[0]);

            const newComponentState = { flashError: '', loaded: false };
            let tokenNotValid = false;

            responses.forEach((res, idx) => {
                const stateKey = stateKeys[idx];
                const routeKey = apiRouteKeys[idx];
                const { dataKey } = this.API_ROUTES_FOR_INSTALLATION[routeKey];

                if (res.success) {
                    // *** DEBUG ***
                    // console.log(`${dataKey}`);
                    // console.log(res.data[dataKey]);
                    newComponentState[stateKey] = res.data[dataKey];
                } else {
                    if (tokenErrorsReturned(res.data)) {
                        tokenNotValid = true;
                    }

                    const errorsSummary = parseResponseErrorsToString(res.data, true); // include debug console output
                    /* If multiple requests fail, we only save the errors summary from the final failed request. */
                    newComponentState.flashError = errorsSummary;
                    newComponentState[stateKey] = {};
                }
            });

            if (tokenNotValid) {
                /**
                 * Token has expired OR user is not authorised OR installation does not exist.
                 * Go back to overview and let AuthenticatedRoute test token expiration status.
                 */
                history.push('/overview');
            }

            if (!newComponentState.flashError.length) {
                newComponentState.loaded = true;
                /* Parse data. */
                const { installation } = newComponentState;
                newComponentState.name = installation.entity.name;
                newComponentState.rctType = installation.netrixserialnumber.split('/')[0].toLowerCase();
            }

            /* If component is no longer mounted we probably navigated away from the page mid-fetch. */
            if (this._isMounted) {
                this.setState(newComponentState);
            }
        });
    }

    /**
     * Fetch eventlogs from API.
     *
     * Set Component state: parameterLogs
     *
     * @param {Object} dateRange     Date objects for range start and end.
     *
     */
    fetchEventlogData = (dateRange) => {
        if (this._isMounted) {
            this.setState({ flashError: 'Fetching eventlog data...' });
        }

        /* Date objects are local and contain timezone. Convert to UTC string. */
        const { start, end } = dateRange;

        const utcStart = utcStringFromDate(start);
        const utcEnd = utcStringFromDate(end);

        // *** DEBUG ***
        // console.log(`Fetch eventlogs from ${utcStart} - ${utcEnd}`);

        const { token, history } = this.props;

        const internalStateKey = 'eventlogs';
        const apiRoutesKey = 'eventlogForInstallation';

        const route = this.API_ROUTES_FOR_INSTALLATION[apiRoutesKey];
        const postData = {
            startDatetime: utcStart,
            endDatetime: utcEnd,
        };

        fetchAuthorisedResource(route, token, postData).then((res) => {
            const newComponentState = { [internalStateKey]: [], flashError: '' };
            let tokenNotValid = false;

            if (res.success) {
                const { dataKey } = route;
                newComponentState[internalStateKey] = res.data[dataKey];
            } else {
                if (tokenErrorsReturned(res.data)) {
                    tokenNotValid = true;
                }

                const errorsSummary = parseResponseErrorsToString(res.data, true); // include debug console output
                newComponentState.flashError = errorsSummary;
            }

            if (tokenNotValid) {
                /**
                 * Token became invalid sometime after first load and graph render.
                 * OR something completely unexpected happened on the backend.
                 * Reload this page. If the token is invalid we will end back at /login.
                 */
                console.error('Fetch eventlogs token not valid');
                history.go(0);
            }

            if (!newComponentState.flashError.length) {
                /**
                 * Show message if eventlogs array is empty.
                 * Handled here rather than allowing child component to call handleFlashMsgUpdate
                 *   to avoid setting state during render function.
                 */
                const fetchedDataIsEmpty = (newComponentState[internalStateKey].length === 0);
                const emptyLogsMsg = (fetchedDataIsEmpty) ? 'No eventlogs found for time range.' : '';

                newComponentState.flashError = emptyLogsMsg;
            }

            /* If component is no longer mounted we probably navigated away from the page mid-fetch. */
            if (this._isMounted) {
                this.setState(newComponentState);
            }
        });
    }

    /* FlashMessage callback passed down to EventLogGrid. */
    handleFlashMsgUpdate = (message) => {
        if (this._isMounted) {
            this.setState((st) => ({ ...st, flashError: message }));
        }
    }

    /* FlashMessage callback. */
    clearFlashErrorMsg = () => {
        if (this._isMounted) {
            this.setState((st) => ({ ...st, flashError: '' }));
        }
    }

    render() {
        const { classes, pageTitle, userPrivileges } = this.props;
        const { dashboard } = userPrivileges;
        const {
            name,
            rctType,
            eventlogs,
            types,
            loaded,
            flashError,
        } = this.state;

        return (
            <div ref={this.myRef} className={classes.root}>
                <div className={classes.header}>
                    <ForwardReferencedAppHeader
                        ref={this.myRef}
                        titleMsg={pageTitle}
                        userLoggedIn
                        dashName={dashboard}
                    />
                </div>
                <div className={classes.main}>
                    {loaded
                        && (
                            <EventLogGrid
                                name={name}
                                model={rctType}
                                logs={eventlogs}
                                types={types}
                                fetchLogData={this.fetchEventlogData}
                                updateFlashMsg={this.handleFlashMsgUpdate}
                            />
                        )}
                    {/* Loading message. */}
                    <FlashMessage
                        isOpenCondition={!loaded}
                        onCloseCallback={null}
                        flashType="loading"
                        messageNode="Loading"
                    />
                    {/* Error messages. */}
                    <FlashMessage
                        isOpenCondition={!!flashError}
                        autoHideAfterMs={5000}
                        onCloseCallback={this.clearFlashErrorMsg}
                        flashType="info"
                        messageNode={flashError}
                    />
                </div>
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {string} pageTitle           Browser tab title.
 * @param {string} token               Dashboard API token.
 * @param {Array}  userPrivileges      Parsed token claims.
 * @param {Object} history             react-router-dom history.
 * @param {Object} match               react-router-dom match.
 * @param {Array}  initialApiRoutes    routeKey lookup array.
 * @param {Object} classes             JSS classes from withStyles.
 */
EventLog.propTypes = {
    pageTitle: PropTypes.string.isRequired,

    token: PropTypes.string.isRequired,
    userPrivileges: PropTypes.shape({
        dashboard: PropTypes.string.isRequired,
        installations: PropTypes.arrayOf(PropTypes.number).isRequired,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        go: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.objectOf(PropTypes.string),
    }).isRequired,

    initialApiRoutes: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.string),
    ),

    classes: PropTypes.shape({
        root: PropTypes.string,
        header: PropTypes.string,
        main: PropTypes.string,
    }).isRequired,
};

/**
 * Default props are resolved by React before PropTypes typechecking.
 */
EventLog.defaultProps = {
    /* Keys are members of this.state, Values (routeKeys) from this.API_ROUTES_FOR_INSTALLATION. */
    initialApiRoutes: [
        // { eventlogs: 'eventlogForInstallation' },
        { installation: 'singleInstallation' },
        { types: 'eventlogTypes' },
    ],
};

export default withStyles(styles)(EventLog);
