/* eslint-disable no-unused-vars */
/**
 * @file      LogTable.js
 *
 * @brief     Component to display eventlog entries as a list.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { LOCALISED_TIME } from 'helpers/globalConstants';

// import DateSlider from './DateSlider'; // Needs more work.

import styles from './styles/LogTableStyles';

const useStyles = makeStyles(styles);

/**
 * Given an array of eventlog data, calculate an appropriate
 *   spacing (in seconds) for element markers/indexes.
 *
 * @param {Array}    logs    Objects containing eventlog rows.
 *
 * @returns {number}  Index spacing in seconds.
 */
function preferredLogRefDelta(logs) {
    if (!logs.length) {
        return 0;
    }

    const logTimeRangeSeconds = Math.abs(logs[logs.length - 1].time - logs[0].time);
    let indexDelta;

    /* Less than 1 hour. */
    if (logTimeRangeSeconds < 3600) {
        indexDelta = 900; // 15 mins
    /* Less than 4 hours. */
    } else if (logTimeRangeSeconds < 14400) {
        indexDelta = 1800; // 30 mins
    /* Less than 12 hours. */
    } else if (logTimeRangeSeconds < 43200) {
        indexDelta = 7200; // 2 hours
    /* Less than 4 days. */
    } else if (logTimeRangeSeconds < 345600) {
        indexDelta = 43200; // 12 hours
    } else {
        indexDelta = 86400; // 24 hours
    }

    return indexDelta;
}

/**
 * Generate log row indices for DOM element ref assignment.
 *
 * @param {Array}    logs           Objects containing eventlog rows.
 * @param {Number}   stepSizeSec    Minimum time gap between indexed eventlog rows. Seconds.
 *
 * @returns {Array}  An array of log indexes.
 */
function generateLogRowIndexes(logs, stepSizeSec) {
    const logIndexes = [];

    /**
     * Logs are ordered chronologically descending.
     * Start from 'now' and work backwards.
     */
    const now = new Date();
    let nextIdxSec = now.getTime() / 1000;

    logs.forEach((log, idx) => {
        if (log.time <= nextIdxSec) {
            logIndexes.push(idx);
            nextIdxSec = log.time - stepSizeSec;
        }
    });

    return logIndexes;
}

/**
 * Generate eventlog type selection nodes.
 * Side-effect. Pushes DOM refs onto rowRefs.current.
 *
 * @param {Object}   classes             A hook returned by makeStyles to access custom classes.
 * @param {Array}    types               EventLog type enum-friendly name associative array.
 * @param {Object}   icons               Friendly name-material-ui icon associative array.
 * @param {Array}    selected            Selected event type (friendly) names array.
 * @param {Array}    logs                Eventlog rows. Length greater than zero.
 * @param {Array}    logIndexes          Log row indices.
 * @param {Array}    rowRefs             DOM element ref array returned by useRef(). Empty.
 *
 * @returns {Obejct}  An array of React nodes.
 */
function generateLogTableRows(
    classes,
    types,
    icons,
    selected,
    logs,
    logRowIndexes,
    rowRefs,
) {
    const {
        longDateFormat,
        shortDateFormat,
        timeFormat,

    } = LOCALISED_TIME;

    const visibleEnumTypes = Object.keys(types).filter((t) => selected.includes(types[t])); // enum types e.g. DATAPOST
    let dayCounter = null;

    const visibleStyles = [classes.logTableRow];
    const hiddenStyles = [classes.logTableRow, classes.logTableRowHidden];

    const tableRows = logs.map((log, idx) => {
        const { text, type, time } = log;

        const rowVisible = visibleEnumTypes.includes(type);
        const friendlyType = types[type];
        const icon = icons[friendlyType];

        const logEpochMilliSecs = time * 1000;
        /* Display Unix Epoch for invalid time string. This should never fail. */
        const logDate = Number.isNaN(logEpochMilliSecs) ? new Date() : new Date(logEpochMilliSecs);
        const logDateElem = (
            <div className={classes.logDateContainer}>
                <div>{ `${timeFormat.format(logDate)}` }</div>
                <div>{ `${shortDateFormat.format(logDate)}`}</div>
            </div>
        );

        const rowStyles = (rowVisible) ? visibleStyles : hiddenStyles;
        const logRowKey = `ev-row-${idx}-${type}`;

        /**
         * @FIXME MWP Jul-10-2021 DateSlider suspended pending more work.
         *
         * Rather than using <TableRow ref={rowRef}>
         *   would it be better to lookup DOM table row elements Nth of type
         *   in handleScrollToLogRow() ?
         */
        // let rowRef = null;
        // if (logRowIndexes.includes(idx)) {
        //     // eslint-disable-next-line no-param-reassign
        //     rowRef = (ref) => { rowRefs.current[idx] = ref; };
        // }

        const logRowNode = (
            <TableRow key={logRowKey} className={clsx([rowStyles])}>
                <TableCell align="center" className={classes.logIconCell}>{icon}</TableCell>
                <TableCell align="left" className={classes.logDateCell}>{logDateElem}</TableCell>
                <TableCell align="left" className={classes.logTextCell}>{text}</TableCell>
            </TableRow>
        );

        /* Add rows for the first entry of each day. */
        let returnedNode = logRowNode;
        if (logDate.getDay() !== dayCounter && rowVisible) {
            dayCounter = logDate.getDay();
            const logFragmentKey = `ev-row-${idx}-frag`;

            returnedNode = (
                <React.Fragment key={logFragmentKey}>
                    <TableRow className={classes.logTableRowDayDivider}>
                        <TableCell align="left" />
                        <TableCell align="left" colSpan={2}>
                            {`${longDateFormat.format(logDate)}`}
                        </TableCell>
                    </TableRow>
                    {logRowNode}
                </React.Fragment>
            );
        }

        return returnedNode;
    });

    return tableRows;
}

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function LogTable(props) {
    const { shortTzName } = LOCALISED_TIME;
    const {
        types,
        typeIcons,
        selected,
        logs,
    } = props;

    const classes = useStyles();

    /**
     * DOM element refs.
     */
    /* Scroll to chart after log fetch. */
    const tableRef = useRef();
    useEffect(() => {
        if (tableRef && tableRef.current) {
            tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [logs, tableRef]);

    /* Table row refs. */
    const rowRefs = useRef([]);
    /* stepSizeSec strictly positive. Log array length checked before rendering this component. */
    const stepSizeSec = preferredLogRefDelta(logs);
    const logIndexes = generateLogRowIndexes(logs, stepSizeSec);

    const tableRows = generateLogTableRows(
        classes,
        types,
        typeIcons,
        selected,
        logs,
        logIndexes,
        rowRefs,
    );

    const handleScrollToLogRow = (event, value) => {
        console.log(`handleScrollToLogRow(). Scroll to idx ${value}`);

        // const ref = rowRefs.current?.[value];
        // if (ref) {
        //     ref.scrollIntoView({
        //         behavior: 'smooth',
        //         block: 'start',
        //     });
        // }
    };

    /* *** DEBUG *** */
    // console.log('LogTable generated rowRefs:');
    // console.log(rowRefs);

    return (
        <div className={classes.root}>
            {(logs.length)
                ? (
                    <>
                        <Typography className={classes.titleText} variant="h5">
                            Logs
                        </Typography>
                        {/* <DateSlider
                            logs={logs}
                            rowRefIndexes={logIndexes}
                            stepSizeSec={stepSizeSec}
                            scrollToRow={handleScrollToLogRow}
                        /> */}
                        <TableContainer className={classes.logTableContainer} ref={tableRef}>
                            <Table className={classes.logTable} aria-label="eventlog table">
                                <TableHead>
                                    <TableRow className={classes.timeZoneRow}>
                                        <TableCell />
                                        <TableCell align="left">
                                            {`Time (${shortTzName})`}
                                        </TableCell>
                                        <TableCell align="left">
                                            Description
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )
                : (
                    <></>
                )}
        </div>
    );
}

/**
 * @param {Object}    types        EventLog type enum-friendly name associative array.
 * @param {Object}    typeIcons    Material-UI icon nodes associative array.
 * @param {Array}     selected     Selected event type (friendly) names array.
 * @param {Array}     logs         Eventlog rows.
 */
LogTable.propTypes = {
    types: PropTypes.objectOf(PropTypes.string).isRequired,
    typeIcons: PropTypes.objectOf(PropTypes.node).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    logs: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        time: PropTypes.number,
        type: PropTypes.string,
    })).isRequired,
};

export default LogTable;
