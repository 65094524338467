/**
 * @file      StatusContentStyles.js
 *
 * @brief     StatusContent component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            backgroundColor: theme.palette.background.paper,
        },

        alarmsItem: {
            /* Relative to root. */
            // flex: '2 2 150px',
            flexGrow: 3,
            margin: '8px 16px',
            /* Remove table margin on portrait phone. */
            [sizes.widthMax('xs')]: {
                margin: '0 0 16px 0',
                '& .MuiTypography-root': {
                    marginLeft: 16,
                },
            },
            minHeight: 110,

            display: 'flex',
            flexDirection: 'column',
        },

        statusItem: {
            /* Relative to root. */
            flex: '1 1 150px',
            margin: '16px 16px',
            minHeight: 110,

            /* Lower margin on portrait phone. */
            [sizes.widthMax('xs')]: {
                flex: '1 1 145px',
                marginBottom: 0,
            },

            display: 'flex',
            flexDirection: 'column',
        },

        statusItemTitle: {
            textTransform: 'uppercase',
            paddingBottom: 8,
        },

        vpnStatus: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            height: 70,
        },

        vpnStatusLamp: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

            width: '80%',
            minWidth: 180,
            maxWidth: 200,
            // height equal to mimic button
            height: 42,
            minHeight: 42,
            // boxShadow: `0 0 20px #999 inset, 0 3px 6px 0 ${theme.palette.text.disabled}`,
            boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,
        },

        vpnStatusText: {
            textTransform: 'uppercase',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.sizes.button,
            fontWeight: 400,
            textAlign: 'center',
            color: theme.palette.text.primary,
        },

        vpnConnected: {
            backgroundColor: theme.palette.vpn.sherbertLive,
            // background: '#03a430',
            // backgroundSize: '5px 5px',
            // backgroundImage: `radial-gradient(${theme.palette.vpn.sherbertLive}, transparent)`,
        },
        vpnDisconnected: {
            backgroundColor: theme.palette.vpn.sherbertDead,
            // background: 'yellow',
            // backgroundSize: '5px 5px',
            // backgroundImage: `radial-gradient(${theme.palette.vpn.sherbertDead}, transparent)`,
        },
        vpnPermanentDisconnect: {
            backgroundColor: theme.palette.vpn.goneAway,
            // background: 'red',
            // backgroundSize: '5px 5px',
            // backgroundImage: 'radial-gradient(brown, transparent)',
        },
        vpnNeverConnected: {
            backgroundColor: theme.palette.vpn.neverConnected,
        },

        datapostDate: {
            maxWidth: 300,
        },

        mimicButton: {
            color: theme.palette.primary.contrastText,
            boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,

            minWidth: 64,
            maxWidth: 128,

            // transition change after mimic uri check
            transition: '200ms background-color ease',
            transitionDelay: '100ms',

            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:disabled': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
                boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,
            },
        },

        eventlogButton: {
            color: theme.palette.primary.contrastText,
            boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,

            minWidth: 64,
            maxWidth: 128,

            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:disabled': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
                boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,
            },
        },

        /* Active alarm table. */
        alarmTable: {
            maxWidth: 700,
            marginBottom: 16,

            '& .MuiTableCell-head': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.table.headRow,
                fontSize: theme.typography.sizes.tableHead,
                /* Reduce padding on portrait phone. */
                [sizes.widthMax('xs')]: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
            '& .MuiTableCell-body': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                fontSize: theme.typography.sizes.tableBody,
                /* Reduce padding on portrait phone. */
                [sizes.widthMax('xs')]: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
        },
        alarmTriggerHead: {
            /* Limit width and wrap timezone on portrait phone. */
            whiteSpace: 'normal',
            [sizes.widthMax('xs')]: {
                width: 100,
            },
        },
        alarmTypeHead: {
            /* Hide alarm type on portrait phone. */
            [sizes.widthMax('xs')]: {
                display: 'none',
            },
        },
        alarmDateCell: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            '& span:nth-of-type(1)': {
                width: 160,
            },
            '& span:nth-of-type(2)': {
                width: 100,
                display: 'none',
            },
            '& span:nth-of-type(3)': {
                width: 70,
            },

            /* Switch date format on portrait phone. */
            [sizes.widthMax('xs')]: {
                '& span:nth-of-type(1)': {
                    display: 'none',
                },
                '& span:nth-of-type(2)': {
                    display: 'inline',
                },
            },
        },
        alarmTypeCell: {
            textTransform: 'capitalize',
            /* Hide alarm type on portrait phone. */
            [sizes.widthMax('xs')]: {
                display: 'none',
            },
        },
    };
};

export default styles;
