/**
 * @file      FlashMessage.js
 *
 * @brief     Popup messages to inform users when status changes.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @NOTE      See VerticalStepLogin.js for module import ordering.
 *
 * @author    Malcolm Padley
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import styles from './styles/FlashMessageStyles';

const useStyles = makeStyles(styles);

/**
 * Use forwardRef to obtain the ref passed down to an Alert
 *   and then forward it to the DOM node.
 * Enables TransitionComponent in Snackbar to slide-up alert messages.
 */
// eslint-disable-next-line react/jsx-props-no-spreading
const Alert = React.forwardRef((props, ref) => <MuiAlert {...props} ref={ref} />);

function SlideTransition(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Slide {...props} direction="up" />;
}

/**
 * @param {array}    flashClasses       Array of JSS classes from useStyles.
 * @param {string}   alertSeverity      Material-UI alert severity in ['error', 'info', 'success', 'warning'].
 * @param {Function} onCloseCallback    Callback when Snackbar or Alert components closed.
 * @param {string}   messageNode        React node containing alert message.
 *
 * @return {Object}  A JSX node.
 */
function generateAlertNode(flashClasses, alertSeverity, onCloseCallback, messageNode) {
    return (
        (
            <Alert
                className={clsx(flashClasses)}
                variant="filled"
                severity={alertSeverity}
                onClose={onCloseCallback}
            >
                {messageNode}
            </Alert>
        )
    );
}

/**
 * @param {array}  flashClasses    Array of JSS classes from useStyles.
 * @param {string} message         Loading message string.
 *
 * @return {Object}  A JSX node.
 */
function generateLoadingNode(flashClasses, message) {
    return (
        (
            <div className={clsx(flashClasses)}>
                <div />
                <Typography variant="h5">
                    {message}
                </Typography>
            </div>
        )
    );
}

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function FlashMessage(props) {
    const classes = useStyles();
    const {
        isOpenCondition,
        autoHideAfterMs,
        onCloseCallback,
        flashType,
        messageNode,
    } = props;

    const flashClasses = [classes.alert];
    let flashNode = null;

    switch (flashType) {
    case 'error':
        flashClasses.push(classes.alertError);
        flashNode = generateAlertNode(flashClasses, 'error', onCloseCallback, messageNode);
        break;
    case 'loading':
        flashClasses.push(classes.alertLoading);
        flashNode = generateLoadingNode(flashClasses, messageNode);
        break;
    case 'info':
        flashClasses.push(classes.alertInfo);
        flashNode = generateAlertNode(flashClasses, 'info', onCloseCallback, messageNode);
        break;
    case 'success':
        flashClasses.push(classes.alertSuccess);
        flashNode = generateAlertNode(flashClasses, 'success', onCloseCallback, messageNode);
        break;
    default:
        /* We should never arrive here. flashType prop checked in development mode. */
    }

    return (
        <div className={classes.root}>
            <Snackbar
                key={`${flashType}_${Math.floor((Math.random() * 1000) + 1)}`}
                open={isOpenCondition}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                autoHideDuration={autoHideAfterMs}
                onClose={onCloseCallback}
                TransitionComponent={SlideTransition}
            >
                {flashNode}
            </Snackbar>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {boolean}  isOpenCondition    Determines whether component is visible.
 * @param {number}   autoHideAfterMs    Auto-hide component after time (ms).
 * @param {Function} onCloseCallback    Callback when Snackbar or Alert components closed.
 * @param {string}   flashType          The type of alert in {'error', 'loading', 'info'}.
 * @param {Object}   messageNode        React node containing message.
 *                                      Anything that can be rendered: number, string, component.
 */
FlashMessage.propTypes = {
    isOpenCondition: PropTypes.bool.isRequired,
    autoHideAfterMs: PropTypes.number,
    onCloseCallback: PropTypes.func,
    flashType: PropTypes.oneOf(['error', 'loading', 'info', 'success']).isRequired,
    messageNode: PropTypes.node.isRequired,
};

/**
 * Default props are resolved by React before PropTypes typechecking.
 */
FlashMessage.defaultProps = {
    autoHideAfterMs: null, // auto-hide disabled
    onCloseCallback: null, // no callback
};

export default FlashMessage;
