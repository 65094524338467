/**
 * @file      MimicPopover.js
 *
 * @brief     Display a fullscreen popover containing an installation mimic.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import { sleep } from 'helpers/globalConstants';

import styles from './styles/MimicPopoverStyles';

const useStyles = makeStyles(styles);

/**
 * Slightly improve performance by allowing React to bypass iframe content
 *   while checking for differences between virtual/real DOMs.
 * Iframe source must be trusted.
 *
 * @param {string} mimicUrl  An absolute URI pointing to a mimic.
 *
 * @returns Object containing raw html for consumption by dangerouslySetInnerHTML().
 */
function createIframeMarkup(mimicUrl) {
    return {
        __html: `<iframe id="mimicFrame" title="Live Mimic" width="100%" height="90%" src="${mimicUrl}" />`,
    };
}

/**
 * Return a (memoized) React functional component.
 *
 * @param {Object} props    React props.
 */
const MimicPopover = memo((props) => {
    const { name, url, toggleMimicOpen } = props;
    const classes = useStyles();

    /**
     * Apply css scaling to mimic div based on the dimensions of a containing element.
     * Fired on window resize events.
     *
     * @param {boolean} forceScaling    Force refresh of all mimic pages.
     *
     * @sideEffect Mutates globally-scoped variable window.globalMimicProps.
     */
    const transformMimic = (forceScaling = false) => {
        const {
            panelDiv,
            pageContentDivs,
            scaleFactor,
            width,
        } = window.globalMimicProps;

        if (!width) {
            console.error('Initial scale not defined from dynamic mimic div.');
            return;
        }

        const oldScaleFactor = scaleFactor;
        const panelDivWidth = panelDiv.offsetWidth;
        let newScaleFactor;

        /* Only scale down. */
        if (panelDivWidth >= width) {
            newScaleFactor = 1.0;
        } else {
            newScaleFactor = panelDivWidth / width;
        }

        /* Check height. Doesn't work even with overflow hidden. */
        // const panelDivHeight = panelDiv.offsetHeight;
        // if (height * newScaleFactor > currentHeight) {
        //     newScaleFactor = panelDivHeight / height;
        // }

        if (forceScaling || newScaleFactor !== oldScaleFactor) {
            window.globalMimicProps.scaleFactor = newScaleFactor;

            pageContentDivs.forEach((elem) => {
                /* This should always be the case. */
                if (elem.style) {
                    /* ESLint justify. Allow assignment to function parameters. */
                    // eslint-disable-next-line no-param-reassign
                    elem.style.transform = `scale(${newScaleFactor})`;
                }
            });
        }
    };

    /**
     * Find a dynamic DOM element containing our mimic
     *   and possibly add it to an array of divs which are to be scaled.
     * Fired on containing div mutation events.
     *
     * @sideEffect Mutates globally-scoped variable window.globalMimicProps.
     */
    const locateCurrentMimicContentDiv = () => {
        try {
            const { pageContentDivs } = window.globalMimicProps;
            /**
             * Inner div created dynamically by MIStudio JS. Expected to have inline-styled width and height.
             * Searching through nested, unnamed divs isn't ideal but that's what MIStudio outputs.
             *
             * The ESLint prefer-destructuring rule below is rubbish. Expected form:
             *   ({ 0: window.globalMimicProps.panelDiv } = doc.getElementsByTagName('div'));
             */
            // eslint-disable-next-line max-len, prefer-destructuring
            const innerDiv = window.globalMimicProps.panelDiv
                .getElementsByTagName('div')[0]
                .getElementsByTagName('div')[1]
                .getElementsByTagName('div')[0];

            /* This is the initial/main mimic page. */
            if (!pageContentDivs.length) {
                const initialStyledWidth = innerDiv.style.width ?? '600px';
                const initialStyledHeight = innerDiv.style.height ?? '600px';

                /* Initialise scale variables. */
                window.globalMimicProps.width = parseInt(initialStyledWidth, 10);
                window.globalMimicProps.height = parseInt(initialStyledHeight, 10);
                window.globalMimicProps.scaleFactor = 1.0;
            }

            if (pageContentDivs.some((elem) => elem.isEqualNode(innerDiv))) {
                // console.log('We have seen this mimic page before. Nothing to do.');
            } else {
                /* Style this mimic div, overriding inline, and add to array. */
                innerDiv.style.transformOrigin = 'top left';
                window.globalMimicProps.pageContentDivs.push(innerDiv);

                /* Perform initial scaling on this new mimic page. */
                transformMimic(true);
            }
        } catch (e) {
            if (e instanceof TypeError) {
                console.error('Mimic div unexpectedly not found. No element registered for scaling.');
            } else {
                console.error(e);
            }
        }
    };

    /**
     * Mimic scaling.
     *
     * @NOTE MWP 17-Jun-2021 This is all rather horrid.
     * If the mimic iframe is same origin, we can inject styling for elements inside.
     * Everything in this class assumes a specific HTML layout as produced by MIStudio.
     * It is extremely fragile.
     */
    useEffect(() => {
        /* Allow generous iframe loading time. Mimic spinner gif lasts 7 seconds. */
        sleep(3000).then(() => {
            /* Our iframe with id from createIframeMarkup. */
            const mimicFrame = document.getElementById('mimicFrame');
            if (!mimicFrame) {
                /* Mimic popover closed before we fired. Nothing to do. */
                return;
            }

            /**
             * The active Document in the iframe's nested browsing context.
             * Null if the iframe and its parent document are not Same Origin.
             */
            const sameOriginDoc = mimicFrame.contentDocument;
            const sameOriginWin = mimicFrame.contentWindow;
            if (!sameOriginDoc) {
                console.log('Mimic iframe is not Same Origin. Assume dev environment. No scaling applied.');
                return;
            }

            try {
                /**
                 * An outer mimic div with static id '#<mimic-name>Panel' defined in MIStudio HTML.
                 * querySelector returns null if no matches are found, throws SyntaxError for invalid selectors.
                 *
                 * I have seen both of these work?
                 */
                const panelDiv = sameOriginWin?.frames?.[0]?.document?.querySelector('[id$="Panel"]')
                    || sameOriginDoc.querySelector('[id$="Panel"]');
                if (!panelDiv) {
                    throw new Error('MimicPanel div not found. Cannot scale.');
                }

                /* Align mimic container. */
                panelDiv.style.display = 'flex';
                panelDiv.style.flexDirection = 'row';
                panelDiv.style.justifyContent = 'center';

                /* Attach a MutationObserver to watch for child div additions/removals (containing the actual mimic). */
                const panelDivObserver = new MutationObserver(locateCurrentMimicContentDiv);
                panelDivObserver.observe(panelDiv, {
                    childList: true,
                    subtree: true, // required
                });

                /* Attach properties to the global object. */
                window.globalMimicProps = {
                    panelDiv,
                    panelDivObserver,
                    pageContentDivs: [],
                    scaleFactor: null,
                    width: null,
                    height: null,
                };

                /* Find the initial mimic div (dynamically generated by MIStudio JS). */
                locateCurrentMimicContentDiv();

                /* Listen for resize changes. */
                window.addEventListener('resize', transformMimic);
            } catch (e) {
                console.error(e.message);
            }
        });

        /* Cleanup event listener. */
        return () => {
            /**
             * Testing (chrome only)
             *   const list = getEventListeners(window);
             *   console.log(list['resize'][3]['listener'].toString());
             */
            console.log('Mimic cleanup.');
            window.removeEventListener('resize', transformMimic);

            if (window.globalMimicProps) {
                window.globalMimicProps.panelDivObserver.disconnect();
                window.globalMimicProps = undefined;
            }
        };
    /* ESLint justify. First render only. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Popover
            open
            className={classes.popover}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 0, left: 0 }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transitionDuration={800}
            TransitionComponent={Fade}

        >
            <div className={classes.mimicContent}>
                <div className={classes.mimicTitle}>
                    <div className={classes.titleText}>
                        <Typography variant="h5">
                            {`${name} - Live Mimic`}
                        </Typography>
                    </div>
                    <Button
                        variant="text"
                        size="large"
                        className={classes.mimicCloseButton}
                        onClick={toggleMimicOpen}
                        endIcon={<CloseIcon />}
                    >
                        Close
                    </Button>
                </div>
                <div
                    className={classes.mimicFrameContainer}
                    /* ESLint justify. Inner HTML is controlled by dexdyne. */
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={createIframeMarkup(url)}
                />
            </div>
        </Popover>
    );
});

/**
 * Typecheck props in development mode.
 *
 * @param {string}    name               Name string for title.
 * @param {string}    url                Mimic URL.
 * @param {Function}  toggleMimicOpen    Close mimic button callback.
 */
MimicPopover.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    toggleMimicOpen: PropTypes.func.isRequired,
};

export default MimicPopover;
