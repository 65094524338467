/**
 * @file      RelDateRangeSelect.js
 *
 * @brief     Relative date range selection component.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';

import styles from './styles/RelDateRangeSelectStyles';

const useStyles = makeStyles(styles);

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function RelDateRangeSelect(props) {
    const { dateRange, setDateRange } = props;

    const classes = useStyles();

    const { start, end } = dateRange;
    const currentDeltaMs = end - start;
    const dateTimeNow = new Date();
    const rangeEndsNow = (dateTimeNow - end) < 300000; // 5 minutes

    const intervals = [
        {
            name: 'Minutes',
            durationMs: 60000,
            lookback: [15, 30, 45],
        },
        {
            name: 'Hours',
            durationMs: 3600000,
            lookback: [1, 2, 6, 12],
        },
        {
            name: 'Days',
            durationMs: 86400000,
            lookback: [1, 2, 3, 4, 5, 6],
        },
        {
            name: 'Weeks',
            durationMs: 604800000,
            lookback: [1, 2, 4, 6],
        },
    ];

    return (
        intervals.map((intervalType) => {
            const { name, durationMs, lookback } = intervalType;
            return (
                <div key={`lookback-${name}`}>
                    <ListItem className={classes.intervalRow}>
                        <ListItemText className={classes.intervalDesc} primary={name} />
                        <ListItemSecondaryAction>
                            {lookback.map((delta) => {
                                const deltaMs = durationMs * delta;
                                const currentlySelected = rangeEndsNow && (currentDeltaMs === deltaMs);
                                return (
                                    <Button
                                        key={`lookback-${name}-${delta}`}
                                        className={classes.intervalButton}
                                        variant={currentlySelected ? 'contained' : 'outlined'}
                                        color="primary"
                                        disableElevation
                                        onClick={() => setDateRange(deltaMs)}
                                    >
                                        {delta}
                                    </Button>
                                );
                            })}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider
                        key={`lookback-${name}-divide`}
                        className={classes.listDivider}
                        variant="inset"
                        component="li"
                    />
                </div>
            );
        })
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}    dateRange     Start and end JS Date objects.
 * @param {Function}  setDateRange  Update parent date range state.
 */
RelDateRangeSelect.propTypes = {
    dateRange: PropTypes.shape({
        start: PropTypes.instanceOf(Object),
        end: PropTypes.instanceOf(Object),
    }).isRequired,
    setDateRange: PropTypes.func.isRequired,
};

export default RelDateRangeSelect;
