/**
 * @file      DXHeroBanner.js
 *
 * @brief     Model banner to display at top of page.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import Typography from '@material-ui/core/Typography';

import stylesWithHero from './styles/DXHeroBannerStyles';

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
const DXHeroBanner = memo((props) => {
    const {
        name,
        model,
    } = props;
    const classes = stylesWithHero(model)();

    return (
        <div className={classes.banner}>
            <div>
                <Typography variant="h5" className={classes.bannerTitle}>
                    {name}
                </Typography>
            </div>
        </div>
    );
});

/**
 * Typecheck props in development mode.
 *
 * @param {string}    name     Installation name.
 * @param {string}    model    RTU model.
 */
DXHeroBanner.propTypes = {
    name: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
};

export default DXHeroBanner;
