/**
 * @file      Logout.js
 *
 * @brief     Logout route controller.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
    API_ROUTES,
    fetchAuthorisedResource,
    tokenErrorsReturned,
    parseResponseErrorsToString,
} from 'helpers/globalConstants';

/**
 * React class-based component.
 */
class Logout extends Component {
    constructor() {
        super();
        this.handleLogout = this.handleLogout.bind(this);
    }

    /**
     * Lifecycle methods.
     */
    componentDidMount() {
        this.handleLogout();
    }

    /**
     * Make a get request to logout of the large dashboard.
     * On success/failure redirect to Login.
     */
    async handleLogout() {
        const { token, history, setApiToken } = this.props;
        const route = API_ROUTES.dashboardLogout;

        let errorsSummary = '';

        fetchAuthorisedResource(route, token).then((res) => {
            if (res.success) {
                /* Successful responses contain no data. */
            } else {
                const tokenNotValid = tokenErrorsReturned(res.data);

                if (tokenNotValid) {
                    /* Ignore invalid token errors. We're logging out. */
                } else {
                    errorsSummary = parseResponseErrorsToString(res.data, true);
                    console.error(errorsSummary);
                }
            }

            /**
             * @TODO MWP 30-Apr-2023
             * Give some user feedback on success or for response errors.
             */

            /* Clearing the API token should cause immediate redirect to /login. */
            setApiToken(null);
            history.push('/login');
        });
    }

    render() {
        return (
            <div>
                You have logged out
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}   token          Dashboard API token.
 * @param {Function} setApiToken    Dashboard API token state setter hook.
 * @param {Object}   history        react-router-dom history.
 */
Logout.propTypes = {
    token: PropTypes.string.isRequired,
    setApiToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default Logout;
