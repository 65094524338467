/**
 * @file      HistoricAlarmGridRow.js
 *
 * @brief     Row container for historic alarms table.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

import { LOCALISED_TIME } from 'helpers/globalConstants';

import HistoricAlarmTable from './HistoricAlarmTable';

import styles from './styles/RowStyles';

const useStyles = makeStyles(styles);

/**
 * An array of column headings.
 * 'id's reference historicAlarms API data keys.
 */
const columnHeadings = [
    {
        id: 'param',
        label: 'Parameter',
        sortable: true,
        className: 'alarmParamHeadCell',
    },
    {
        /* 'virtual' column to combine datetimes. No data with this id returned from API. */
        id: 'startStop',
        label: `Trigger-Clear (${LOCALISED_TIME.shortTzName})`,
        sortable: true,
        className: 'alarmStartStopHeadCell',
    },
    {
        id: 'start',
        label: 'Triggered',
        sortable: true,
        className: 'alarmTriggerHeadCell',
    },
    {
        id: 'end',
        label: 'Cleared',
        sortable: false,
        className: 'alarmClearHeadCell',
    },
    {
        id: 'type',
        label: 'Type',
        sortable: true,
        className: 'alarmTypeHeadCell',
    },
    {
        id: 'detail',
        label: 'Detail',
        sortable: false,
        className: 'alarmDetailHeadCell',
    },
    // {
    //     id: 'triggerVal',
    //     label: 'Value',
    // },
];

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function HistoricAlarmGridRow(props) {
    const {
        title,
        historicAlarms,
        parameters,
        showHideTimeMs,
    } = props;
    const classes = useStyles();

    const [rowVisible, setRowVisible] = useState(false);
    const toggleRowVisible = () => setRowVisible(!rowVisible);

    return (
        <div className={classes.root}>
            <div className={classes.rowHeader}>
                <Typography
                    variant="button"
                    align="left"
                    display="inline"
                    className={classes.rowHeaderText}
                >
                    {title}
                </Typography>
                <Button
                    variant="text"
                    size="large"
                    className={classes.rowShowHideButton}
                    onClick={toggleRowVisible}
                    endIcon={(rowVisible) ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                >
                    {(rowVisible) ? 'Hide' : 'Show'}
                </Button>
            </div>
            <Collapse
                in={rowVisible}
                timeout={showHideTimeMs}
                collapsedHeight="0px"
                className={classes.content}
            >
                <HistoricAlarmTable
                    parameters={parameters}
                    historicAlarms={historicAlarms}
                    columnHeadings={columnHeadings}
                />
            </Collapse>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}    title              Top banner display title.
 * @param {Array}     historicAlarms     Historic alarms list.
 * @param {Array}     parameters         Parameters id=>name associative array.
 * @param {number}    showHideTimeMs     Collapse animation time (ms).
 */
HistoricAlarmGridRow.propTypes = {
    title: PropTypes.string.isRequired,

    historicAlarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,

    parameters: PropTypes.instanceOf(Object).isRequired,

    showHideTimeMs: PropTypes.number.isRequired,
};

export default HistoricAlarmGridRow;
