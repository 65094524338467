/**
 * @file      DateRangeSelector.js
 *
 * @brief     Components to select a relative or absolute date range.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import TocIcon from '@material-ui/icons/Toc';

import { makeStyles } from '@material-ui/core/styles';

import AbsDateRangeSelect from 'global_components/AbsDateRangeSelect';
import RelDateRangeSelect from 'global_components/RelDateRangeSelect';

import styles from './styles/DateRangeSelectorStyles';

const useStyles = makeStyles(styles);

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function DateRangeSelector(props) {
    const {
        dateRange,
        setDateRange,
        plotEnabled,
        fetchLogData,
        updateFlashMsg,
    } = props;

    const classes = useStyles();

    /* Reserve state. 0 is relative, 1 is absolute. */
    const [tabIdx, setTabIdx] = useState(0);

    /* Set DatePicker inputs readonly on tab change to prevent mobile keyboard popup. */
    useEffect(() => {
        const datePickers = document.getElementsByClassName('react-datepicker__input-container');
        Array.from(datePickers).forEach((elem) => elem.childNodes[0].setAttribute('readOnly', true));
    }, [tabIdx]);

    const handleRelativeDateChange = (deltaMs) => {
        /* Any timezone conversion for DB query will happen in EventLog parent. */
        const end = new Date();
        const start = new Date(end.valueOf() - deltaMs);

        if (start >= end) {
            updateFlashMsg('Range start must come before end.');
        } else {
            setDateRange({ start, end });
        }
    };

    const handleAbsoluteDateChange = (newRange) => {
        const { start, end } = newRange;
        if (start >= end) {
            updateFlashMsg('Range start must come before end.');
        } else {
            setDateRange(newRange);
        }
    };

    const handleTabIdxChange = (event, newIdx) => {
        if ([0, 1].includes(newIdx)) {
            setTabIdx(newIdx);
        }
    };

    const handleLogRequest = () => {
        fetchLogData(dateRange);
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.titleText} variant="h5">
                Time Range
            </Typography>

            <AppBar className={classes.rangeTypeTab} position="static" color="default" elevation={0}>
                <Tabs
                    value={tabIdx}
                    onChange={handleTabIdxChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Relative" />
                    <Tab label="Absolute" />
                </Tabs>
            </AppBar>

            <List className={classes.datePickerContainer}>
                {(tabIdx === 0)
                    ? (
                        <RelDateRangeSelect
                            dateRange={dateRange}
                            setDateRange={handleRelativeDateChange}
                        />
                    )
                    : (
                        <AbsDateRangeSelect
                            dateRange={dateRange}
                            setDateRange={handleAbsoluteDateChange}
                        />
                    )}

                <ListItem key="list-plot-data" className={classes.intervalRow}>
                    <ListItemText
                        className={classes.intervalDesc}
                        primary="Fetch eventlog data"
                    />
                    <ListItemSecondaryAction>
                        <Button
                            className={classes.plotButton}
                            disabled={!plotEnabled}
                            variant="contained"
                            size="large"
                            startIcon={<TocIcon />}
                            onClick={handleLogRequest}
                        >
                            Logs
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider key="plot-button-end-divide" className={classes.listDivider} variant="inset" component="li" />
            </List>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}      dateRange           Start and end Date objects.
 * @param {Function}    setDateRange        useState hook for date start-end range.
 * @param {boolean}     plotEnabled         Selected parameters and date range are OK.
 * @param {Function}    fetchLogData        Request log data to display.
 * @param {Function}    updateFlashMsg      Flash message callback.
 */
DateRangeSelector.propTypes = {
    dateRange: PropTypes.shape({
        start: PropTypes.instanceOf(Object),
        end: PropTypes.instanceOf(Object),
    }).isRequired,
    setDateRange: PropTypes.func.isRequired,
    plotEnabled: PropTypes.bool.isRequired,
    fetchLogData: PropTypes.func.isRequired,
    updateFlashMsg: PropTypes.func.isRequired,
};

export default DateRangeSelector;
