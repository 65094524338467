/**
 * @file      ChartGridRowStyles.js
 *
 * @brief     ChartGridRow component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
// import sizes from 'global_styles/windowSizes';

const MAT_UI_TABLE_HEAD_HEIGHT = 58;

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            width: '100%',
            display: 'grid',
            placeItems: 'start center',
        },

        rowHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',

            width: '100%',
            height: MAT_UI_TABLE_HEAD_HEIGHT,

            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,

            '& .MuiTypography-button': {
                fontSize: theme.typography.sizes.rowHeading,
            },
        },

        rowHeaderText: {
            marginLeft: 16,
        },

        rowShowHideButton: {
            width: 96,
            padding: '8px 16px 8px 8px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,

            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },

            /* flex for label and icon */
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            fontSize: theme.typography.sizes.rowHeading,
        },

        content: {
            width: '100%',
            marginBottom: theme.space.margin.defaultVertical,

            '& .MuiCollapse-wrapper': {
                width: '100%',
            },
        },

        flexContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',

            backgroundColor: theme.palette.background.paper,
        },
    };
};

export default styles;
