/**
 * @file      InstallationGridStyles.js
 *
 * @brief     InstallationGrid component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    const MARGIN_DOUBLE = theme.space.margin.defaultVertical * 2;
    return {
        gridRoot: {
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexWrap: 'nowrap',
            alignItems: 'center',
        },

        gridContent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            marginTop: theme.space.margin.defaultVertical,
            marginBottom: MARGIN_DOUBLE,
            /**
             * Media query-based width.
             * Extra Large devices (>=1600px) is the default. Order is important.
             */
            width: '76%',
            maxWidth: 1400,
            [sizes.widthMax('xl')]: {
                width: '85%',
            },
            [sizes.widthMax('lg')]: {
                width: '92%',
            },
            [sizes.widthMax('md')]: {
                width: '95%',
            },
            [sizes.widthMax('sm')]: {
                width: '100%',
            },
            [sizes.widthMax('xs')]: {
                marginTop: 0,
            },
        },
    };
};

export default styles;
