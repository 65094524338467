/**
 * @file      AbsDateRangeSelectStyles.js
 *
 * @brief     AbsDateRangeSelect component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
/* eslint-disable max-len */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        intervalRow: {
            height: 80,
            [sizes.widthMax('xs')]: {
                /* Reduce padding on portrait phone. */
                paddingLeft: 4,
                paddingRight: 4, // matches MuiListItemSecondaryAction-root
            },
        },
        intervalDesc: {
            color: theme.palette.text.primary,

            '& .MuiTypography-root': {
                fontSize: theme.typography.sizes.para,
            },
            /* Hide descriptions on portrait phone. */
            '& p': {
                [sizes.widthMax('xs')]: {
                    display: 'none',
                },
            },
        },
        listDivider: {
            //
        },

        /**
         * Absolute.
         * react-datepicker styling adapted from https://github.com/chakra-ui/chakra-ui/issues/580
         */
        calendar: {
            // containers
            '& .react-datepicker-popper': {
                zIndex: 100,
            },
            '& .react-datepicker-wrapper': {
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
            '& .react-datepicker__input-container': {
                width: 240,

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',

                borderRadius: 4,
                border: `1px solid ${theme.palette.button.outline}`,
                transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

                '&:hover': {
                    border: `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: theme.palette.button.hoverFill,
                },
                '&:focus': {
                    outline: 'none',
                },

                '& input': {
                    cursor: 'default',
                    caretColor: 'transparent',
                },
            },
            '& .react-datepicker__month-container': {
                [sizes.widthMax('xs')]: {
                    width: 240,
                },
            },

            // date input text box
            '& input': {
                height: 38,
                width: '100%',

                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.sizes.formInput,
                fontWeight: 'inherit',
                textAlign: 'center',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: theme.palette.primary.main,

                border: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    border: 'none',
                },

                '&:focus': {
                    outline: 'none',
                },
            },

            // the picker
            '& .react-datepicker': {
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.sizes.calendar,
                fontWeight: 400,
                color: theme.palette.text.primary,
                overflow: 'hidden',
            },

            // border
            '& .react-datepicker, .react-datepicker__header, .react-datepicker__time-container': {
                // borderColor: theme.palette.primary.main,
                borderColor: theme.palette.button.outline,
            },

            // header
            '& .react-datepicker__header': {
                borderRadius: 0,
                background: theme.palette.primary.main,
            },

            // header title text
            '& .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header': {
                fontSize: theme.typography.sizes.calendar,
                fontWeight: 400,
                color: theme.palette.common.white,
                textTransform: 'uppercase',
            },

            '& .react-datepicker__day-names div': {
                color: theme.palette.common.white,
            },

            // header month-navigation arrows
            '& .react-datepicker__navigation--previous, .react-datepicker__navigation--next': {
                height: 12,
            },

            '& .react-datepicker__navigation--previous': {
                borderRightColor: theme.palette.common.white,
                '&:hover': {
                    borderRightColor: theme.palette.primary.dark,
                },
                '&:focus': {
                    outline: 'none',
                },
            },

            '& .react-datepicker__navigation--next': {
                borderLeftColor: theme.palette.common.white,
                '&:hover': {
                    borderLeftColor: theme.palette.primary.dark,
                },
                '&:focus': {
                    outline: 'none',
                },
            },

            // time of day container
            '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item': {
                margin: '0 1px 0 0',
                height: 'auto',
                padding: '7px 10px',

                '&:hover': {
                    background: theme.palette.background.transparent,
                },
            },

            // days of the month container
            '& .react-datepicker__month-container, .react-datepicker__time': {
                background: theme.palette.background.dexdyneGrey,
            },

            '& .react-datepicker__day': {
                '&:hover': {
                    background: theme.palette.background.transparent,
                },
                '&:focus': {
                    outline: 'none',
                },
            },

            '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected': {
                background: theme.palette.primary.main,
                fontWeight: 'normal',
                outline: 'none',

                '&:hover': {
                    background: theme.palette.primary.main,
                },
            },

            '& .react-datepicker__day--keyboard-selected': {
                color: theme.palette.text.primary,
                background: 'transparent',
            },
        },
    };
};

export default styles;
