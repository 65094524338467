/**
 * @file      EventLogGrid.js
 *
 * @brief     EventLog view top level container.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContactlessIcon from '@material-ui/icons/Contactless';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import GridOnIcon from '@material-ui/icons/GridOn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MemoryIcon from '@material-ui/icons/Memory';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import PowerIcon from '@material-ui/icons/Power';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import StorageIcon from '@material-ui/icons/Storage';
import SyncIcon from '@material-ui/icons/Sync';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import VpnLockIcon from '@material-ui/icons/VpnLock';

import { makeStyles } from '@material-ui/core/styles';

import DXHeroBanner from '../installation/DXHeroBanner'; // *** MOVE ME TO GLOBAL COMPONENTS. ***
import DateRangeSelector from './DateRangeSelector';
import EventLogTypeList from './EventLogTypeList';
import LogList from './LogTable';

import styles from './styles/EventLogGridStyles';

/**
 * Map friendly eventlog types to material-ui icons.
 *
 * Assumes some knowledge of event types stored in DB and API endpoint.
 * Types are stored as an enum, so should not change.
 */
const eventTypeIcons = {
    'Alarm Off': <NotificationsOffIcon />,
    'Alarm On': <NotificationImportantIcon />,
    'Alarm Sync': <SyncIcon />,
    Datapost: <CloudUploadIcon />,
    Eventsnap: <FlashOnIcon />,
    Failover: <SyncAltIcon />,
    Geofence: <GridOnIcon />,
    Health: <MemoryIcon />,
    LoRaWAN: <ContactlessIcon />,
    Middleware: <ClearAllIcon />,
    'Mobile Data': <SignalCellularAltIcon />,
    'Mobile Network': <SignalCellularAltIcon />,
    Modbus: <SettingsInputComponentIcon />,
    SMS: <MailOutlineIcon />,
    'Start-up': <PowerIcon />,
    'System Event': <StorageIcon />,
    Update: <SystemUpdateAltIcon />,
    VPN: <VpnLockIcon />,
    WAN: <AccountTreeIcon />,
    Webcore: <BlurCircularIcon />, // LanguageIcon
};

const useStyles = makeStyles(styles);

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function EvengLogGrid(props) {
    const {
        name,
        model,
        logs,
        types,
        fetchLogData,
        updateFlashMsg,
    } = props;
    const classes = useStyles();

    /* Selected date range. */
    const dateNow = new Date();
    const [dateRange, setDateRange] = useState({
        start: new Date(dateNow.valueOf() - 7200000), // 2 hours ago
        end: dateNow,
    });

    /* Eventlog types. */
    const friendlyTypeNames = Object.values(types);
    const uniqueFriendlyTypeNames = [...new Set(friendlyTypeNames)].sort();
    const [selectedLogTypes, setSelectedLogTypes] = useState([]);

    return (
        <div className={classes.gridRoot}>
            <DXHeroBanner
                name={name}
                model={model}
            />
            <div className={classes.gridContent}>
                <DateRangeSelector
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    plotEnabled
                    fetchLogData={fetchLogData}
                    updateFlashMsg={updateFlashMsg}
                />
                <EventLogTypeList
                    typeNames={uniqueFriendlyTypeNames}
                    typeIcons={eventTypeIcons}
                    selected={selectedLogTypes}
                    setSelected={setSelectedLogTypes}
                />
                <LogList
                    types={types}
                    typeIcons={eventTypeIcons}
                    selected={selectedLogTypes}
                    logs={logs}
                />
            </div>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}      name             Installation name.
 * @param {string}      model            RTU model.
 * @param {Array}       logs             Eventlog rows.
 * @param {Object}      types            EventLog type enum-friendly name associative array.
 * @param {Function}    fetchLogData     Request eventlog data.
 * @param {Function}    updateFlashMsg   Flash message callback.
 */
EvengLogGrid.propTypes = {
    name: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    logs: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,
    types: PropTypes.instanceOf(Object).isRequired,

    fetchLogData: PropTypes.func.isRequired,
    updateFlashMsg: PropTypes.func.isRequired,
};

export default EvengLogGrid;
