/**
 * @file      VerticalStepLogin.js
 *
 * @brief     Container for all login elements.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */

// https://github.com/benmosher/eslint-plugin-import/blob/v2.22.1/docs/rules/order.md
// 1  node "builtin" modules
//
// 2  "external" modules
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// 2.5 "external" modules (material-ui)
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Check from '@material-ui/icons/Check';
import LockIcon from '@material-ui/icons/Lock';

import { withStyles } from '@material-ui/core/styles';
// 3  "internal" modules
import { sleep } from 'helpers/globalConstants';
// 4  modules from a "parent" directory (../ syntax)
import FlashMessage from 'global_components/FlashMessage';
// 5  "sibling" modules from the same or a sibling's directory
import UsernameForm from './UsernameForm';
import PasswordForm from './PasswordForm';
// 5.5 "sibling" modules (styles)
import styles, { stepConnectorStyles } from './styles/VerticalStepLoginStyles';

/**
 * React class-based component.
 */
class VerticalStepLogin extends Component {
    constructor() {
        super();
        this.state = {
            activeStep: 0,
            completedSteps: {},
            stepFormValues: {},
            invalidInput: false,
            alertMsg: '',
        };

        this.getStepContent = this.getStepContent.bind(this);
        this.totalSteps = this.totalSteps.bind(this);
        this.numCompletedSteps = this.numCompletedSteps.bind(this);
        this.allStepsCompleted = this.allStepsCompleted.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleStep = this.handleStep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getStepIcon = this.getStepIcon.bind(this);
    }

    getStepContent = (step) => {
        const { steps } = this.props;
        return steps[step].description ?? 'Unknown Step';
    }

    totalSteps = () => {
        const { steps } = this.props;
        return steps.length;
    }

    numCompletedSteps = () => {
        const { completedSteps } = this.state;
        return Object.values(completedSteps).filter((step) => step).length;
    }

    allStepsCompleted = () => (this.numCompletedSteps() === this.totalSteps());

    handleBack = () => {
        const { activeStep } = this.state;
        const prevStep = (activeStep === 0) ? 0 : activeStep - 1;
        this.setState({ activeStep: prevStep });
    }

    handleStep = (step) => () => {
        this.setState({ activeStep: step });
    }

    handleSubmit = (data) => {
        const { index, formValue } = data;
        const { activeStep, completedSteps, stepFormValues } = this.state;
        const { steps } = this.props;
        const totalSteps = this.totalSteps();

        /* Should not occur absent hostile user interaction. */
        if (index !== activeStep) {
            console.log('handleSubmit ERROR: Invalid step index');
            return;
        }

        /* Update steps and values. */
        const updatedCompletedSteps = { ...completedSteps, [activeStep]: true };
        const updatedFormValues = { ...stepFormValues, [activeStep]: formValue };

        /* Find the next step to make active. */
        const currentStepIsLastSequentially = (activeStep === totalSteps - 1);
        // this.numCompletedSteps() value from current state will be out-of-date.
        const numCompletedSteps = Object.values(updatedCompletedSteps).filter((step) => step).length;
        const allStepsAreComplete = (numCompletedSteps === totalSteps);

        let newActiveStep = activeStep + 1;
        if (allStepsAreComplete) {
            newActiveStep = totalSteps;
        } else if (currentStepIsLastSequentially) {
            /**
             * Last step but not all steps have been completed.
             * Find the first incomplete step. Anonymous function returns Boolean or undefined
             */
            newActiveStep = steps.findIndex((step, i) => !(updatedCompletedSteps[i])); // Boolean or undefined
        }

        const updatedState = {
            completedSteps: updatedCompletedSteps,
            stepFormValues: updatedFormValues,
            activeStep: newActiveStep,
            invalidInput: false,
        };

        if (allStepsAreComplete) {
            /* Send login request. Updates this component state on login failure. */
            this.handleLogin(updatedState);
        } else {
            /* Update (incomplete) login state. */
            this.setState(updatedState);
        }
    }

    handleLogin = (updatedState) => {
        /* All steps are complete, call handleLogin to POST a request for an API token. */
        const { loginSuccess } = this.props;
        const formValues = updatedState.stepFormValues;
        /**
         * Update (completed but unverified) login state.
         * Setting state here enables the loading spinner.
         */
        this.setState(updatedState);

        const username = formValues[0];
        const password = formValues[1];
        loginSuccess(username, password).then((errorMsg) => {
            /* If login was successful, parent has already have moved onto new route and we will be unmounted. */
            if (errorMsg) {
                /* Dramatic pause. */
                sleep(200).then(() => {
                    this.setState({
                        completedSteps: {},
                        stepFormValues: formValues,
                        activeStep: 0,
                        invalidInput: true,
                        alertMsg: errorMsg,
                    });
                });
            }
        });
    }

    handleAlertClose = () => {
        this.setState({ invalidInput: false });
    }

    getStepIcon = (stepIdx) => {
        const { classes, steps } = this.props;
        const { activeStep, completedSteps } = this.state;

        const iconClasses = [classes.stepIcon];

        if (activeStep === stepIdx) {
            iconClasses.push(classes.stepIconActive);
        } else if (completedSteps[stepIdx]) {
            iconClasses.push(classes.stepIconComplete);
        } else {
            iconClasses.push(classes.stepIconIncomplete);
        }

        return (
            <div className={clsx(iconClasses)}>
                {completedSteps[stepIdx] ? <Check /> : steps[stepIdx].icon}
            </div>
        );
    }

    render() {
        const { classes, steps } = this.props;
        const {
            activeStep,
            completedSteps,
            invalidInput,
            alertMsg,
        } = this.state;

        const oneStepLeftToComplete = (this.numCompletedSteps() === this.totalSteps() - 1);
        const zeroStepsLeftToComplete = this.allStepsCompleted();

        const activeStepFormContainerClasses = [
            classes.allFormsParentContainerUsername,
            classes.allFormsParentContainerPassword,
            classes.allFormsParentContainerSpinner,
        ];
        const allFormsParentAppliedClasses = [
            classes.allFormsParentContainer,
            activeStepFormContainerClasses[activeStep],
        ];

        const StepConnectors = withStyles(stepConnectorStyles)(StepConnector);
        return (
            <div className={classes.root}>
                <Paper elevation={4} className={classes.loginPaper}>
                    <Stepper
                        nonLinear
                        activeStep={activeStep}
                        orientation="vertical"
                        connector={<StepConnectors />}
                        className={classes.loginStepperContainer}
                    >
                        {steps.map((step, index) => (
                            <Step
                                key={step.name}
                                completed={completedSteps[index]}
                            >
                                <StepLabel
                                    onClick={this.handleStep(index)}
                                    StepIconComponent={() => this.getStepIcon(index)}
                                />
                            </Step>
                        ))}
                    </Stepper>

                    {/**
                     * This div contains three elements: Username form, Password form, Waiting spinner.
                     * Only one of which should be visible at any given time.
                     */}
                    <div className={clsx(allFormsParentAppliedClasses)}>
                        {steps.map((step, index) => {
                            const stepIsActive = (index === activeStep);
                            const isLastStepToComplete = oneStepLeftToComplete
                                && stepIsActive
                                && !(Object.prototype.hasOwnProperty.call(completedSteps, index));

                            const formContainerAppliedClasses = [classes.formContainer];
                            if (stepIsActive) {
                                formContainerAppliedClasses.push(classes.formContainerVisible);
                            } else {
                                formContainerAppliedClasses.push(classes.formContainerHidden);
                            }

                            const formProps = {
                                index,
                                description: step.description,
                                // value: (stepFormValues[index] ?? ''),
                                placeholderValue: step.placeholder,
                                isActive: stepIsActive,
                                isFirstStep: (index === 0),
                                isLastStepToComplete,
                                handleSubmit: this.handleSubmit,
                                handleBack: this.handleBack,
                            };
                            return (
                                <div key={step.name} className={clsx(formContainerAppliedClasses)}>
                                    {(step.name === 'username')
                                        /* Shared props for form components explicitly detailed above. */
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        ? (<UsernameForm {...formProps} />)
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        : (<PasswordForm {...formProps} />)}
                                </div>
                            );
                        })}
                        <Fade in={zeroStepsLeftToComplete} timeout={1800}>
                            <div className={classes.loginSpinner} />
                        </Fade>
                    </div>
                </Paper>

                <FlashMessage
                    isOpenCondition={invalidInput}
                    autoHideAfterMs={5000}
                    onCloseCallback={this.handleAlertClose}
                    flashType="error"
                    messageNode={alertMsg}
                />
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {Function} loginSuccess    Make a post request for an API token.
 * @param {Array}    steps           The steps to complete (forms to fill) before making login request.
 * @param {Object}   classes         JSS classes from withStyles.
 */
VerticalStepLogin.propTypes = {
    loginSuccess: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            placeholder: PropTypes.string.isRequired,
            icon: PropTypes.node.isRequired,
        }),
    ),

    classes: PropTypes.shape({
        root: PropTypes.string,
        loginPaper: PropTypes.string,
        loginStepperContainer: PropTypes.string,
        allFormsParentContainer: PropTypes.string,
        allFormsParentContainerUsername: PropTypes.string,
        allFormsParentContainerPassword: PropTypes.string,
        allFormsParentContainerSpinner: PropTypes.string,
        formContainer: PropTypes.string,
        formContainerVisible: PropTypes.string,
        formContainerHidden: PropTypes.string,
        loginSpinner: PropTypes.string,
        errorAlert: PropTypes.string,
        stepIcon: PropTypes.string,
        stepIconActive: PropTypes.string,
        stepIconComplete: PropTypes.string,
        stepIconIncomplete: PropTypes.string,
    }).isRequired,
};

/**
 * Default props are resolved by React before PropTypes typechecking.
 */
VerticalStepLogin.defaultProps = {
    steps: [
        {
            name: 'username',
            description: 'Sign in',
            placeholder: 'Username',
            icon: <AccountCircleIcon />,
        },
        {
            name: 'password',
            description: 'Enter password',
            placeholder: 'Password',
            icon: <LockIcon />,
        },
    ],
};

export default withStyles(styles)(VerticalStepLogin);
