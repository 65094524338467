/**
 * @file      LogTableStyles.js
 *
 * @brief     LogTable component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            // relative to EventLogGrid.flexContainer
            minWidth: 360,
            maxWidth: 1400,
            width: '100%',
            marginTop: 16,
            marginBottom: 16,
        },

        titleText: {
            textTransform: 'uppercase',
            marginLeft: 16,
            paddingBottom: 8,
        },

        logTableContainer: {
            // backgroundColor: theme.palette.background.paper,
            width: 'calc(100% - 32px)', // 32px margin
            margin: 16,
            marginTop: 0,
            [sizes.widthMax('sm')]: {
                width: '100%',
                marginLeft: 0,
                marginRight: 0,
            },
        },

        logTable: {
            marginBottom: theme.space.margin.defaultVertical,

            /**
             * Extra Large devices (>=1600px) are the default.
             * Limit max table width on hi-res monitors.
             */
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
            maxWidth: 1200,

            [sizes.widthMax('xl')]: {
                marginTop: 0,
                // width: 'calc(100% - 32px)', // 16px padding left-right
                width: '100%',
            },
            [sizes.widthMax('lg')]: {
                width: '100%',
            },

            '& .MuiTableCell-head': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.table.headRow,
                fontSize: theme.typography.sizes.tableHead,
                /* Reduce padding on portrait phone. */
                [sizes.widthMax('xs')]: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
            '& .MuiTableCell-body': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                fontSize: theme.typography.sizes.tableBody,
                /* Reduce padding on portrait phone. */
                [sizes.widthMax('xs')]: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
        },

        logTableRow: {},

        logTableRowHidden: {
            display: 'none',
        },

        logTableRowDayDivider: {
            '& .MuiTableCell-body': {
                // backgroundColor: 'goldenrod',
                color: theme.palette.primary.contrastText,
                background: `
                    linear-gradient(
                        to top, ${theme.palette.primary.main},
                        ${theme.palette.primary.main} 56px,
                        rgba(0, 0, 0, 0) 56px
                    )`,
                paddingTop: 32,
                border: 'none',
            },
        },

        logIconCell: {
            '& svg': {
                width: 34,
                height: 34,
            },
        },

        logDateContainer: {
            display: 'flex',
            flexDirection: 'column',

            '& div:nth-of-type(1)': {
                paddingBottom: 6,
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                marginBottom: 6,
            },
            '& div:nth-of-type(2)': {
                color: 'rgba(0, 0, 0, 0.54)', // List secondary text colour.
            },
        },

        logTextCell: {
            wordBreak: 'break-all',
        },
    };
};

export default styles;
