/**
 * @file      useCookieState.js
 *
 * @brief     React hook to manipulate browser cookies.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import Cookies from 'js-cookie';
import { useState, useEffect, useCallback } from 'react';

/**
 * Cookie state hook. Used exactly like useState() - intercepted to also update a browser cookie.
 *
 * @param {string} cookieName   Cookie key.
 * @param {string} domain       Domain for which the cookie is valid.
 * @param {number} expireHours  Cookie expiry time in hours.
 * @param {string} initialVal   Initial value. Takes precedence over any previously stored cookie.
 */
export default function useCookieState(cookieName, domain, expireHours = 168, initialVal = null) {
    // const internalCookies = Cookies.noConflict(); // Removing global reference to window.Cookies not necessary

    const storeStateAsCookie = useCallback((cookieValue) => {
        const expireDays = expireHours / 24;

        const newlySetCookie = Cookies.set(cookieName, cookieValue, {
            expires: expireDays,
            domain,
            httpOnly: false, // We need access to cookie using client-side JS.
            secure: true, // https only (except on localhost)
            sameSite: 'strict',
        });

        if (newlySetCookie === undefined) {
            console.error(`Error encountered while setting cookie '${cookieName}'`);
        }
    }, [cookieName, domain, expireHours]);

    /* useState to reserve a piece of state */
    const [stateValue, setStateValue] = useState(() => {
        /* Update any existing cookie with initial value. */
        if (initialVal != null) {
            storeStateAsCookie(initialVal);
            return initialVal;
        }

        /* Initial value is null/undefined, check for previously set cookie. */
        const storedValue = Cookies.get(cookieName, { domain }); // js-cookie returns undefined on error
        return storedValue || null;
    });

    /* useEffect to update cookie when state changes */
    useEffect(() => {
        /* Re-sets the same cookie value on browser page load. Infrequent, not an issue. */
        // *** DEBUG ***
        // eslint-disable-next-line max-len
        // console.log('%cuseCookieState.useEffect()%c - set cookie.', 'background:paleturquoise; color:black;', 'color:mediumturquoise;');
        storeStateAsCookie(stateValue);
    }, [stateValue, storeStateAsCookie]);

    return [stateValue, setStateValue];
}
