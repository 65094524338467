/**
 * @file      StatusContent.js
 *
 * @brief     Installation status indicators and mimic launch button.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { LOCALISED_TIME, dateFromUtcString, dtToHumanReadableDescription } from 'helpers/globalConstants';

import styles from './styles/StatusContentStyles';

const VPN_DEAD_TIME_SECONDS = 600;

const useStyles = makeStyles(styles);

/**
 * Generate a colourful VPN status indicator.
 *
 * @param {Object} classes    A hook returned by makeStyles to access custom classes.
 * @param {Object} vpnStatus  VPN last contact information.
 *
 * @returns {Obejct}  A React node.
 */
function generateVpnStatusItem(classes, vpnStatus) {
    const {
        vpnSecondsSinceContact,
        vpnConnectEpochSeconds,
        vpnLastContactEpochSeconds,
    } = vpnStatus;

    const vpnHasNeverWorked = !vpnLastContactEpochSeconds;
    const vpnContactFormatted = dtToHumanReadableDescription(vpnSecondsSinceContact, vpnLastContactEpochSeconds);
    const vpnStatusClasses = [classes.vpnStatusLamp];
    let vpnStatusText = '';

    if (vpnConnectEpochSeconds) {
        /* VPN connection in the not-too-distant past. */
        if (vpnSecondsSinceContact > VPN_DEAD_TIME_SECONDS) {
            vpnStatusText = 'Disconnected';
            vpnStatusClasses.push(classes.vpnDisconnected);
        } else {
            vpnStatusText = 'Connected';
            vpnStatusClasses.push(classes.vpnConnected);
        }
    } else if (vpnHasNeverWorked) {
        /* Never had a VPN connection. */
        vpnStatusText = 'Never Connected';
        vpnStatusClasses.push(classes.vpnNeverConnected);
    } else {
        /* VPN connection in the dim and distant past. */
        vpnStatusText = 'Gone Away';
        vpnStatusClasses.push(classes.vpnPermanentDisconnect);
    }

    return (
        <div className={classes.statusItem}>
            <Typography className={classes.statusItemTitle} variant="h5">
                vpn
            </Typography>
            <div className={classes.vpnStatus}>
                <Tooltip
                    disableFocusListener
                    enterDelay={200}
                    placement="bottom"
                    title={`Last seen: ${vpnContactFormatted}`}
                >
                    <div className={clsx(vpnStatusClasses)}>
                        <Typography className={classes.vpnStatusText} variant="button">
                            {vpnStatusText}
                        </Typography>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}

/**
 * Generate a text element containing information about the last datapost.
 *
 * @param {Object} classes         A hook returned by makeStyles to access custom classes.
 * @param {Object} datapostStatus  Datapost contact information.
 *
 * @returns {Obejct}  A React node.
 */
function generateDatapostStatusItem(classes, datapostStatus) {
    const { datapostEpochSeconds } = datapostStatus;

    const { shortTzName, dateFormat, timeFormat } = LOCALISED_TIME;
    const d = new Date(datapostEpochSeconds * 1000);
    /* Access to Installation page requires unit to have dataposted. */
    const lastDatapostLocalisedHumanReadable = (datapostEpochSeconds)
        ? `${dateFormat.format(d)} @ ${timeFormat.format(d)} (${shortTzName})`
        : 'No datapost yet';

    return (
        <div className={classes.statusItem}>
            <Typography className={classes.statusItemTitle} variant="h5">
                Datapost
            </Typography>
            <Typography className={classes.datapostDate} noWrap variant="body1">
                {lastDatapostLocalisedHumanReadable}
            </Typography>
        </div>
    );
}

/**
 * Generate a button to open an Installation mimic popover.
 *
 * @param {Object}   classes          A hook returned by makeStyles to access custom classes.
 * @param {Object}   vpnStatus        VPN last contact information.
 * @param {string}   model            RTU model type.
 * @param {Boolean}  mimicAvailable   Mimic URI exists.
 * @param {Function} handleOpenMimic  Open mimic popover.
 *
 * @returns {Obejct}  A React node.
 */
function generateMimicStatusItem(classes, vpnStatus, model, mimicAvailable, handleOpenMimic) {
    const { vpnActive } = vpnStatus;

    const enableMimic = mimicAvailable && (vpnActive === 'true' || model === 'lora');

    return (
        <div className={classes.statusItem}>
            <Typography className={classes.statusItemTitle} variant="h5">
                Mimic
            </Typography>
            <Button
                disabled={!enableMimic}
                variant="contained"
                size="large"
                className={classes.mimicButton}
                onClick={handleOpenMimic}
            >
                {(enableMimic) ? 'Go Live' : 'Unavailable'}
            </Button>
        </div>
    );
}

/**
 * Generate a button which will navigate browser to eventlog view.
 *
 * @param {Object}   classes            A hook returned by makeStyles to access custom classes.
 * @param {Function} handleEventlogNav  Navigate to Eventlogs for installation.
 *
 * @returns {Obejct}  A React node.
 */
function generateEventLogItem(classes, handleEventlogNav) {
    return (
        <div className={classes.statusItem}>
            <Typography className={classes.statusItemTitle} variant="h5">
                Eventlog
            </Typography>
            <Button
                variant="contained"
                size="large"
                className={classes.eventlogButton}
                onClick={handleEventlogNav}
            >
                View Logs
            </Button>
        </div>
    );
}

/**
 * Generate a table containing currently active alarms.
 *
 * @param {Object} classes  A hook returned by makeStyles to access custom classes.
 * @param {Array}  alarms   Active alarm list.
 * @param {Object} params   Parameter id-name associative array.
 *
 * @returns {Obejct}  A React node.
 */
function generateAlarmStatusItem(classes, alarms, params) {
    const {
        shortTzName,
        dateFormat,
        shortDateFormat,
        timeFormat,
    } = LOCALISED_TIME;

    const rows = alarms.map((alarm, idx) => {
        const {
            param,
            start,
            type,
            value,
        } = alarm;

        const localDate = dateFromUtcString(start);
        const dateTimeDiv = (
            <div className={classes.alarmDateCell}>
                <span>{ `${dateFormat.format(localDate)}` }</span>
                <span>{ `${shortDateFormat.format(localDate)}` }</span>
                <span>{ `@ ${timeFormat.format(localDate)}` }</span>
            </div>
        );

        /* Using array index as key is not ideal. */
        const key = `alarm${idx}`;
        return (
            <TableRow key={key}>
                <TableCell component="th" scope="row">
                    {params[param]}
                </TableCell>
                <TableCell align="left">{dateTimeDiv}</TableCell>
                <TableCell align="left" className={classes.alarmTypeCell}>{type}</TableCell>
                <TableCell align="right">{value}</TableCell>
            </TableRow>
        );
    });

    /* Default node to return is empty div. */
    let tableNode = (<div className={classes.alarmsItem} />);

    if (alarms.length) {
        tableNode = (
            <div className={classes.alarmsItem}>
                <Typography className={classes.statusItemTitle} variant="h5">
                    Active Alarms
                </Typography>
                <TableContainer>
                    <Table className={classes.alarmTable} aria-label="active alarms table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Parameter</TableCell>
                                <TableCell align="left" className={classes.alarmTriggerHead}>
                                    {`Trigger Time (${shortTzName})`}
                                </TableCell>
                                <TableCell align="left" className={classes.alarmTypeHead}>Type</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    return tableNode;
}

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function StatusContent(props) {
    const classes = useStyles();
    const {
        model,
        alarms,
        parameters,
        vpn,
        mimicAvailable,
        toggleMimicOpen,
        handleEventlogNav,
    } = props;

    return (
        <div className={classes.root}>
            {generateVpnStatusItem(classes, vpn)}
            {generateMimicStatusItem(classes, vpn, model, mimicAvailable, toggleMimicOpen)}
            {generateEventLogItem(classes, handleEventlogNav)}
            {generateDatapostStatusItem(classes, vpn)}
            {generateAlarmStatusItem(classes, alarms, parameters)}
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}    model              RTU model.
 * @param {Array}     alarms             Active alarm list.
 * @param {Object}    parameters         Parameter id-name associative array.
 * @param {Object}    vpn                VPN pulse object.
 * @param {Boolean}   mimicAvailable     Mimic URI exists.
 * @param {Function}  toggleMimicOpen    Close mimic button callback.
 * @param {Function}  handleEventlogNav  Navigate to eventlogs for installation.
 */
StatusContent.propTypes = {
    model: PropTypes.string.isRequired,

    alarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,

    parameters: PropTypes.instanceOf(Object).isRequired,

    vpn: PropTypes.shape({
        datapostEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnActive: PropTypes.oneOf(['true', 'false']).isRequired,
        vpnConnectEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnLastContactEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnSecondsSinceContact: PropTypes.number.isRequired,
    }).isRequired,

    mimicAvailable: PropTypes.bool.isRequired,
    toggleMimicOpen: PropTypes.func.isRequired,
    handleEventlogNav: PropTypes.func.isRequired,
};

export default StatusContent;
