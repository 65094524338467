/**
 * @file      App.js
 *
 * @brief     Mobile dashboard root component.
 *            Responsible for client-side routing of requests.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @NOTE      See VerticalStepLogin.js for module import ordering.
 *
 * @author    Malcolm Padley
 */
import React, { useState } from 'react';
import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import { APP_BASENAME } from 'helpers/globalConstants';
import useJwtState from 'hooks/useJwtState';

import AuthenticatedRoute from 'global_components/AuthenticatedRoute';
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';

import EventLog from 'views/eventlog/EventLog';
import Installation from 'views/installation/Installation';
import Login from 'views/login/Login';
import Logout from 'views/logout/Logout';
import NotFound from 'views/not_found/NotFound';
import Overview from 'views/overview/Overview';

/**
 * Return a React functional component.
 */
function App() {
    const [urlState] = useState(window.document.domain);

    const {
        getApiToken,
        setApiToken,
        currentTokenIsSuperficiallyValid,
        getUserPrivilegesFromToken,
    } = useJwtState(urlState);

    return (
        /**
         * Annoyingly, 'basename' allows matching Route URLs *without* the basename.
         * In production, Apache reverse proxy will be configured to
         *   only forward requests prefixed with this string.
         *
         * All redirects and history.push() calls from these Routes will automatically prefix basename.
         */
        <BrowserRouter basename={APP_BASENAME}>
            <div className="App">
                {/* ThemeProvider required for global font setting. */}
                <ThemeProvider theme={globalDexMaterialTheme}>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => (<Redirect to="/overview" />)}
                        />
                        <Route
                            exact
                            path="/login"
                            render={(routeProps) => {
                                const { history, classes } = routeProps;
                                return (
                                    <Login
                                        pageTitle="Login"
                                        history={history}
                                        classes={classes}
                                        setApiToken={setApiToken}
                                    />
                                );
                            }}
                        />
                        <AuthenticatedRoute
                            exact
                            path="/overview"
                            component={Overview}
                            getApiToken={getApiToken}
                            getUserPrivilegesFromToken={getUserPrivilegesFromToken}
                            currentTokenIsSuperficiallyValid={currentTokenIsSuperficiallyValid}
                            componentProps={
                                { pageTitle: 'Overview' }
                            }
                        />
                        <AuthenticatedRoute
                            exact
                            path="/installation/:id(\d+)"
                            component={Installation}
                            getApiToken={getApiToken}
                            getUserPrivilegesFromToken={getUserPrivilegesFromToken}
                            currentTokenIsSuperficiallyValid={currentTokenIsSuperficiallyValid}
                            componentProps={
                                { pageTitle: 'Installation' }
                            }
                        />
                        <AuthenticatedRoute
                            exact
                            path="/installation/:id(\d+)/eventlog"
                            component={EventLog}
                            getApiToken={getApiToken}
                            getUserPrivilegesFromToken={getUserPrivilegesFromToken}
                            currentTokenIsSuperficiallyValid={currentTokenIsSuperficiallyValid}
                            componentProps={
                                { pageTitle: 'Eventlog' }
                            }
                        />
                        <AuthenticatedRoute
                            exact
                            path="/logout"
                            component={Logout}
                            getApiToken={getApiToken}
                            getUserPrivilegesFromToken={getUserPrivilegesFromToken}
                            currentTokenIsSuperficiallyValid={currentTokenIsSuperficiallyValid}
                            componentProps={
                                { setApiToken }
                            }
                        />
                        { /* Catch-all 404 route. */ }
                        <AuthenticatedRoute
                            path="*"
                            component={NotFound}
                            getApiToken={getApiToken}
                            getUserPrivilegesFromToken={getUserPrivilegesFromToken}
                            currentTokenIsSuperficiallyValid={currentTokenIsSuperficiallyValid}
                            status={404}
                            componentProps={
                                { pageTitle: '404' }
                            }
                        />
                    </Switch>
                </ThemeProvider>
            </div>
        </BrowserRouter>
    );
}

export default App;
