/**
 * @file      MimicPopoverStyles.js
 *
 * @brief     MimicPopover component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        popover: {
            height: '90%',
            marginTop: theme.space.margin.defaultVertical,
            padding: 0,

            width: '90%',
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
            [sizes.widthMax('md')]: {
                width: '95%',
                height: '90%',
            },
            [sizes.widthMax('sm')]: {
                width: '100%',
            },

            '& .MuiPaper-root': {
                width: '100%',
                height: '100%',
            },
        },
        mimicContent: {
            // flex for label and icon
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',

            backgroundColor: theme.palette.background.default,
        },

        mimicTitle: {
            height: 80,
            maxHeight: 80,
            // relative to popover
            flexShrink: 2,
            textTransform: 'uppercase',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap',

            width: '100%',
            paddingTop: 16,
        },
        titleText: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',

            maxWidth: 'calc(100% - 124px)',
            color: theme.palette.text.primary,

            '& h5': {
                paddingLeft: 16,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        mimicCloseButton: {
            width: 96,
            padding: '8px 16px 8px 8px',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,

            '&:hover': {
                backgroundColor: theme.palette.background.transparent,
            },

            // flex for label and icon
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            fontSize: theme.typography.sizes.rowHeading,
        },

        mimicFrameContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },

        /* Global styles to access mimic iframe. */
        '@global': {
            iframe: {
                border: 0,
                overflow: 'scroll',
            },
        },
    };
};

export default styles;
