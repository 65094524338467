/**
 * @file      FlashMessageStyles.js
 *
 * @brief     FlashMessage component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            alignSelf: 'start',
        },

        alert: {
            width: '100%',
            border: '1px solid',

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            fontSize: theme.typography.sizes.flashMessage,
            fontWeight: 400,
            textTransform: 'uppercase',

            '& .MuiAlert-message': {
                flexGrow: 2,
                fontSize: theme.typography.sizes.flashMessage,
            },
            '& .MuiAlert-action': {
                marginLeft: 0,
            },

            /* Default Material-UI table box-shadow. */
            boxShadow: `
                0px 2px 4px -1px rgba(0,0,0,0.2),
                0px 4px 5px 0px rgba(0,0,0,0.14),
                0px 1px 10px 0px rgba(0,0,0,0.12)`,

            /* Large devices (>=1200px) is the default. Order is important. */
            [sizes.widthMax('xs')]: {
                borderRadius: 0,
                borderLeft: 0,
                borderRight: 0,
                borderBottom: 0,
                boxShadow: `
                    0px -2px 4px -1px rgba(0,0,0,0.2),
                    0px -4px 5px 0px rgba(0,0,0,0.14),
                    0px -1px 10px 0px rgba(0,0,0,0.12)`,
            },
        },

        alertError: {
            backgroundColor: theme.palette.flash.errorBackground,
            borderColor: theme.palette.flash.errorBorder,
            color: theme.palette.flash.errorText,

            '& svg': {
                color: theme.palette.flash.errorText,
            },
        },

        alertInfo: {
            backgroundColor: theme.palette.flash.infoBackground,
            borderColor: theme.palette.flash.infoBorder,
            color: theme.palette.flash.infoText,

            '& svg': {
                color: theme.palette.flash.infoText,
            },
        },

        alertSuccess: {
            backgroundColor: theme.palette.flash.successBackground,
            borderColor: theme.palette.flash.successBorder,
            color: theme.palette.flash.successText,

            '& svg': {
                color: theme.palette.flash.successText,
            },
        },

        alertLoading: {
            backgroundColor: theme.palette.flash.loadingBackground,
            borderColor: theme.palette.flash.loadingBorder,
            color: theme.palette.flash.loadingText,

            height: 80,
            width: '100%',
            paddingRight: 24,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',

            '& div': {
                height: 80,
                width: 80,
                backgroundImage: theme.svg.loadingSpinner,
                backgroundSize: '60% 60%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            },
        },

    };
};

export default styles;
