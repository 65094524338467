/**
 * @file      VerticalStepLoginStyles.js
 *
 * @brief     VerticalStepLogin component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const stepConnectorStyles = {
    active: {
        '& $line': {
            borderColor: globalDexMaterialTheme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: globalDexMaterialTheme.palette.primary.main,
        },
    },
    line: {
        borderColor: globalDexMaterialTheme.palette.secondary.dark,
        height: '100%',
        minHeight: 32,
        borderLeft: '0.4em solid',
        marginLeft: -19,
        marginTop: 4,
    },
};

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    const inputFormHeight = 350;
    return {
        root: {
            width: '100%',
            height: '100%',
            /* Align loginPaper. */
            display: 'grid',
            placeItems: 'center',

            [sizes.widthMax('xs')]: {
                backgroundColor: theme.palette.background.paper,
                placeItems: 'start center',
            },
        },
        loginPaper: {
            /* 1x2 row for stepper/forms. */
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',

            backgroundColor: theme.palette.common.white,
            borderRadius: '0.4em',

            /* Height fixed for slide transitions. */
            height: inputFormHeight,
            minHeight: inputFormHeight,
            overflow: 'hidden',

            /* Media query-based width. */
            width: 650,
            minWidth: 320,
            /* Large devices (>=1200px) is the default. Order is important. */
            [sizes.widthMax('lg')]: {
                width: 600,
            },
            [sizes.widthMax('md')]: {
                width: 500,
            },
            [sizes.widthMax('xs')]: {
                width: '100%',
                borderRadius: 0,
                boxShadow: 'none',
            },
        },
        loginStepperContainer: {
            height: '80%',
            flexBasis: '10%',
            flexShrink: 2,
            alignSelf: 'center',

            '&.MuiPaper-root': {
                backgroundColor: 'transparent',
            },

            '& div': {
                display: 'flex',
                justifyContent: 'center',
            },

            /* Do not display stepper on portrait phone. */
            [sizes.widthMax('xs')]: {
                visibility: 'collapse',
                width: 0,
            },
        },

        /* Input form containers. */
        allFormsParentContainer: {
            // relative to loginPaper
            flexBasis: '90%',
            alignSelf: 'flex-start',
            margin: 0,
            paddingRight: 24,
            flexGrow: 2,

            /* 3x1 column for login forms. */
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center',
        },
        allFormsParentContainerUsername: {
            transform: 'translateY(0)',
            transition: 'transform 500ms cubic-bezier(.41, .01, .25, 1.01)',

        },
        allFormsParentContainerPassword: {
            transform: 'translateY(-33.33%)',
            transition: 'transform 500ms cubic-bezier(.41, .01, .25, 1.01)',
        },
        allFormsParentContainerSpinner: {
            transform: 'translateY(-66.67%)',
            transition: 'transform 500ms cubic-bezier(.41, .01, .25, 1.01)',
        },

        formContainer: {
            width: '100%',
            height: inputFormHeight - 20,
            minHeight: inputFormHeight - 20,
            paddingBottom: 20,
        },
        formContainerVisible: {
            opacity: 1,
            transition: 'opacity 200ms ease-out 25ms',
        },
        formContainerHidden: {
            opacity: 0.1,
            transition: 'opacity 200ms ease-in 25ms',
        },

        loginSpinner: {
            width: '100%',
            height: inputFormHeight,
            marginRight: 50,
            backgroundImage: theme.svg.loadingSpinner,
            backgroundSize: '40% 40%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },

        /* Stepper icons. */
        stepIcon: {
            zIndex: 1,
            width: 50,
            height: 50,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'none',

            '& svg': {
                color: theme.palette.common.white,
                height: '70%',
                width: '70%',
            },

            '&:hover': {
                cursor: 'pointer',
                boxShadow: `inset 0 3px 6px 0 ${theme.palette.text.disabled}`,
            },
        },
        stepIconActive: {
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,
        },
        stepIconIncomplete: {
            backgroundColor: theme.palette.secondary.dark,
        },
        stepIconComplete: {
            backgroundColor: theme.palette.primary.main,
        },
        stepIconError: {
            backgroundColor: theme.palette.error.light,
        },
    };
};

export default styles;
export { stepConnectorStyles };
