/**
 * @file      NotFoundCard.js
 *
 * @brief     404 Not Found content.
 *
 * @copyright Copyright Dexdyne Ltd. 2023-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import React, { memo } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import styles from './styles/NotFoundCardStyles';

const useStyles = makeStyles(styles);

/**
 * Return a React functional component.
 */
const NotFoundCard = memo(() => {
    const classes = useStyles();

    return (
        <div className={classes.cardHolder}>
            <Card className={classes.notFoundCard}>
                <CardHeader
                    avatar={
                        (
                            <Avatar aria-label="recipe" variant="square" className={classes.avatarSquare}>
                                404
                            </Avatar>
                        )
                    }
                />
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Sorry,
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="h2">
                        The page you requested was not found.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        component={Link}
                        to="/overview"
                        size="large"
                        color="primary"
                        className={classes.backButton}
                    >
                        Go Back
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
});

export default NotFoundCard;
