/**
 * @file      AppHeaderStyles.js
 *
 * @brief     AppHeader component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            // flexGrow: 1,
            // flexBasis: '10%',
        },

        appBar: {
            height: theme.space.appbar.height,
            maxHeight: theme.space.appbar.height,
            textTransform: 'uppercase',

            transition: 'all 180ms linear',

            '&.MuiAppBar-colorPrimary': {
                backgroundColor: theme.palette.appbar.background.solid,
                color: theme.palette.appbar.text.solid,
            },
        },

        appBarTransparent: {
            height: theme.space.appbar.height,
            maxHeight: theme.space.appbar.height,
            textTransform: 'uppercase',

            // transition: 'color 400ms ease-out, background 400ms ease-out, top 300ms linear',
            transition: 'all 350ms linear 50ms',

            '&.MuiAppBar-colorPrimary': {
                backgroundColor: theme.palette.appbar.background.translucent,
                color: theme.palette.appbar.text.translucent,
                boxShadow: 'none',
            },

            '&.MuiAppBar-positionFixed': {
                top: -100,
            },
        },

        menuButton: {
            marginRight: theme.spacing(2),

            '& .MuiSvgIcon-root:hover': {
                color: theme.palette.primary.main,
            },
        },

        flexContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },

        dexdyneText: {
            height: 70,
            width: 200,
            marginTop: 4,
            flexGrow: 0, // Do not grow.
            flexShrink: 0, // Do not shrink.
            backgroundImage: theme.svg.dexdyneText,
            backgroundSize: '140px 400px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',

            filter: 'drop-shadow(2px 2px 1px rgba(0,0,0,0.2))',
        },

        locationText: {
            /* Do not display location text on portrait phone. */
            [sizes.widthMax('sm')]: {
                visibility: 'hidden',
                width: 0,
            },
        },

        // Menu Drawer Start
        drawerContent: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 22px',
            width: 'calc(100% - 44px)', // 100% of .MuiDrawer .MuiPaper minus padding.
        },

        drawerTitle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
        },

        logoHolder: {
            display: 'flex',
            flexDirection: 'row',
        },

        drawerDexdyneLogo: {
            width: 40,
            height: 40,
            marginTop: 13,
            marginRight: 26,
            flexGrow: 0, // Do not grow.
            flexShrink: 0, // Do not shrink.
            backgroundImage: theme.svg.dexdyneLogo,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
        },

        drawerDexdyneText: {
            marginTop: '0px !important',
            [sizes.widthMax('xs')]: {
                visibility: 'hidden',
                width: 0,
            },
        },

        drawerClose: {
            width: 70,
            height: 70,

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: theme.palette.text.primary,

            '& .MuiButtonBase-root': {
                width: '60%',
                height: '60%',
            },
            '& .MuiSvgIcon-root': {
                width: 32,
                height: 32,
                '&:hover': {
                    color: theme.palette.primary.main,
                },
            },

        },

        listHolder: {
            width: '100%',
        },

        listIcon: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },

        link: {
            color: theme.palette.text.primary,
            textDecoration: 'none',

            '& .MuiTypography-root': {
                fontSize: theme.typography.sizes.flashMessage,

            },
            '&:hover': {
                color: theme.palette.primary.main,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
        },
        linkActive: {
            color: theme.palette.primary.main,
            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            },
        },

        drawerFooter: {
            width: '100%',
            flexGrow: 2,
            display: 'flex',
            alignItems: 'flex-end',
            fontSize: theme.typography.sizes.flashMessage,
        },
    };
};

export default styles;
