/**
 * @file      HistoricAlarmTableStyles.js
 *
 * @brief     HistoricAlarmTable component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        container: {
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            backgroundColor: theme.palette.background.paper,
        },

        noAlarmsText: {
            // relative to container
            alignSelf: 'flex-start',
            padding: 16,
        },

        alarmTable: {
            marginBottom: theme.space.margin.defaultVertical,

            /**
             * Extra Large devices (>=1600px) are the default.
             * Limit max table width on hi-res monitors.
             */
            marginTop: 16,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
            maxWidth: 1200,

            [sizes.widthMax('xl')]: {
                marginTop: 0,
                width: 'calc(100% - 32px)', // 16px padding left-right
            },
            [sizes.widthMax('lg')]: {
                width: '100%',
            },

            '& .MuiTableCell-head': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.table.headRow,
                fontSize: theme.typography.sizes.tableHead,
                /* Reduce padding on portrait phone. */
                [sizes.widthMax('xs')]: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
            '& .MuiTableCell-body': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                fontSize: theme.typography.sizes.tableBody,
                /* Reduce padding on portrait phone. */
                [sizes.widthMax('xs')]: {
                    paddingLeft: 8,
                    paddingRight: 8,
                },
            },
        },

        /**
         * Table head formatting.
         * Extra row containing timezone.
         */
        timeZoneRow: {
            /* Switch to single start-stop cell at lower width. */
            [sizes.widthMax('md')]: {
                display: 'none',
            },
        },
        spanningCellEmpty: {
            borderBottom: 0,
        },

        /* Column headings. */
        alarmStartStopHeadCell: {
            display: 'none',
            /* Switch to single start-stop cell at lower width. */
            [sizes.widthMax('md')]: {
                display: 'table-cell',
            },
        },
        alarmTriggerHeadCell: {
            [sizes.widthMax('md')]: {
                display: 'none',
            },
        },
        alarmClearHeadCell: {
            [sizes.widthMax('md')]: {
                display: 'none',
            },
        },
        alarmDetailHeadCell: {
            /* Hide detail column on portrait phone. */
            [sizes.widthMax('sm')]: {
                display: 'none',
            },
        },

        /**
         * Table cell formatting.
         */
        alarmStartStopCell: {
            display: 'none',
            /* Switch to single start-stop cell at lower width. */
            [sizes.widthMax('md')]: {
                display: 'table-cell',
            },
        },
        alarmTriggerCell: {
            [sizes.widthMax('md')]: {
                display: 'none',
            },
        },
        alarmClearCell: {
            [sizes.widthMax('md')]: {
                display: 'none',
            },
        },
        alarmTypeCell: {
            textTransform: 'capitalize',
        },
        alarmDetailCell: {
            /* Hide detail column on portrait phone. */
            [sizes.widthMax('sm')]: {
                display: 'none',
            },
        },

        /**
         * Date formatting.
         */
        alarmStartStopContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',

            '& div:nth-of-type(1)': {
                paddingBottom: 6,
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                marginBottom: 6,
            },
        },
        alarmDateContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            '& span:nth-of-type(1)': {
                width: 160,
            },
            '& span:nth-of-type(2)': {
                width: 70,
            },
        },
        shortDate: {
            '& span:nth-of-type(1)': {
                width: 100,
            },
        },
        alarmActiveSpan: {
            color: theme.palette.error.main,
        },

        /* Hidden aria label for column ordering. */
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    };
};

export default styles;
