/**
 * @file      LoginFormStyles.js
 *
 * @brief     UsernameForm and PasswordForm component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },

        title: {
            display: 'flex',
            flexDirection: 'row',
        },

        titleLogo: {
            width: 45,
            height: 'auto',
            marginTop: 13,
            marginRight: 6,
            backgroundImage: theme.svg.dexdyneLogo,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
        },

        titleText: {
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            marginTop: 12,
        },

        form: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(4),

            /* Media query-based width. */
            width: '100%',
            /* Large devices (>=1200px) is the default. Order is important. */
            [sizes.widthMax('xs')]: {
                width: '90%',
                minWidth: 220,
            },

            '& input': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.table.darkRow,
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& input::placeholder': {
                textTransform: 'uppercase',
            },
            '& label.MuiFormLabel-root.Mui-error': {
                color: theme.palette.error.light,
                textTransform: 'uppercase',
            },
            '& .MuiFilledInput-underline:after': {
                borderBottomWidth: '0.2em',
            },
            '& .MuiFilledInput-underline.Mui-error:after': {
                borderBottomColor: theme.palette.error.light,
            },
            '& .Mui-focused:after': {
                borderBottomColor: theme.palette.primary.main,
            },

            '& .MuiInputBase-root': {
                backgroundColor: theme.palette.table.darkRow,
            },
        },

        buttonHolder: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '50%',
            minWidth: 300,
        },

        button: {
            marginRight: theme.spacing(2),
            color: theme.palette.primary.contrastText,
            boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,

            flexBasis: '50%',
            minWidth: 64,
            maxWidth: 96,

            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:disabled': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
                boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,
            },
        },
    };
};

export default styles;
