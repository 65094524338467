/**
 * @file      DXHeroBannerStyles.js
 *
 * @brief     DXHeroBanner component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import { makeStyles } from '@material-ui/core/styles';

import theme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

import netrixHero from 'static/images/7000_hero.webp';
import dx3Hero from 'static/images/dx3_hero.webp';
import dx2Hero from 'static/images/dx2_hero.webp';
import loraHero from 'static/images/r3000_hero.webp';

/**
 * Generate hero banner styles for specific model.
 *
 * @param {string} modelType  RTU model.
 *
 * @returns {Object}  A makeStyles hook to access custom classes.
 */
const stylesWithHero = (modelType) => {
    let heroUrl = '';

    const heroImages = {
        lora: loraHero,
        dx2: dx2Hero,
        dx3: dx3Hero,
        7000: netrixHero, // string variable modelType works for this key
    };
    if (Object.keys(heroImages).includes(modelType)) {
        heroUrl = `url(${heroImages[modelType]})`;
    }

    const styles = makeStyles({
        banner: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',

            width: '100%',
            height: 140,
            [sizes.widthMax('xs')]: {
                height: 100,
            },
            backgroundColor: theme.palette.background.transparent,

            backgroundImage: heroUrl,
            backgroundPosition: '-80px center',
            backgroundRepeat: 'no-repeat',
            animation: '$banner-fade-in-animation ease 2s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            // filter: 'grayscale(100%) brightness(1.05)',
            [sizes.widthMax('sm')]: {
                backgroundSize: '865px 500px',
            },

            '& div': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',

                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.transparent,
                height: 80,
                width: 400,

                /**
                 * Media query-based margin.
                 * Margins are 0.5*(100 - width) for widths in InstallationGridStyles.
                 * Extra Large devices (>=1600px) is the default. Order is important.
                 */
                marginLeft: 'max(12%, calc(50% - 700px))', // maximum width is 1400px
                [sizes.widthMax('xl')]: {
                    marginLeft: '7.5%',
                },
                [sizes.widthMax('lg')]: {
                    marginLeft: '4%',
                },
                [sizes.widthMax('md')]: {
                    marginLeft: '2.5%',
                },
                [sizes.widthMax('sm')]: {
                    marginLeft: 0,
                },
                [sizes.widthMax('xs')]: {
                    width: '100%',
                },
            },
        },
        '@keyframes banner-fade-in-animation': {
            '0%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },

        bannerTitle: {
            marginLeft: 15,
            textTransform: 'uppercase',
        },
    });
    return styles;
};

export default stylesWithHero;
