/**
 * @file      PageRootStyles.js
 *
 * @brief     Top level styles applied to the page before any Component.
 *            JSS format.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */

import globalDexMaterialTheme from './globalDexMaterialTheme';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            margin: 0,
            padding: 0,
            height: '100vh',
            minHeight: 480,
            minWidth: 360,
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'overlay',
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            backgroundColor: theme.palette.background.dexdyneGrey,
            transition: '400ms filter linear',
        },
        header: {},
        main: {
            paddingTop: theme.space.appbar.height,
        },
        popoverBlur: {
            filter: 'brightness(50%)',
        },
    };
};

export default styles;
