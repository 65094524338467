/**
 * @file      EventLogTypeListStyles.js
 *
 * @brief     EventLogTypeList component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            // relative to EventLogGrid.flexContainer
            flexGrow: 2,
            margin: 16,
            minWidth: 344,
            width: 'calc(100% - 32px)',
            maxWidth: 1400,

            [sizes.widthMax('sm')]: {
                marginRight: 0,
            },
        },

        titleText: {
            textTransform: 'uppercase',
            paddingBottom: 8,
        },

        typesContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            columnGap: 8,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',

            backgroundColor: theme.palette.background.paper,
            paddingTop: 8,
        },

        typesContainerBreak: {
            flexBasis: '100%',
            height: 1,
            marginTop: 20,
            marginBottom: 20,

            /**
             * Media query-based gradient width.
             * Extra Large devices (>=1600px) is the default. Order is important.
             */
            // Copy material-ui Divider styles
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12) 40%, rgba(0, 0, 0, 0) 40%)',
            [sizes.widthMax('md')]: {
                background: `
                    linear-gradient(
                        to right, rgba(0, 0, 0, 0.12),
                        rgba(0, 0, 0, 0.12) 45%,
                        rgba(0, 0, 0, 0) 45%
                    )`,
            },
        },

        logTypeSelectBtn: {
            fontSize: theme.typography.sizes.button,
            fontWeight: 400,

            height: 52,
            marginBottom: 8,

            /**
             * Media query-based width.
             * Extra Large devices (>=1600px) is the default. Order is important.
             */
            maxWidth: 260,
            minWidth: 160,
            width: '20%',
            [sizes.widthMax('sm')]: {
                width: '22%',
            },

            '& .MuiButton-iconSizeMedium svg': {
                width: 34,
                height: 34,
            },
        },

        logTypeSelectAllNone: {
            fontSize: theme.typography.sizes.button,
            fontWeight: 400,

            height: 42,
            color: theme.palette.primary.contrastText,
            boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,

            /**
             * Media query-based width.
             * Extra Large devices (>=1600px) is the default. Order is important.
             */
            maxWidth: 260,
            minWidth: 160,
            width: '20%',
            [sizes.widthMax('sm')]: {
                width: '22%',
            },
        },

        logTypeText: {
            width: 114,
        },
    };
};

export default styles;
