/**
 * @file      ChartGridRow.js
 *
 * @brief     Row container for Installation native chart content.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

import ParamChart from './ParamChart';
import DateRangeSelector from './DateRangeSelector';
import ParameterTable from './ParameterTable';

import styles from './styles/ChartGridRowStyles';

const useStyles = makeStyles(styles);

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function ChartGridRow(props) {
    const {
        title,
        parameters,
        historicAlarms,
        fetchParamData,
        updateFlashMsg,
        showHideTimeMs,
        parameterLogs,
    } = props;
    const classes = useStyles();

    const [rowVisible, setRowVisible] = useState(false);
    const toggleRowVisible = () => setRowVisible(!rowVisible);

    /* Selected parameter ids array. */
    const [selectedParams, setSelectedParams] = useState([]);
    /* Selected date range. */
    const [dateRange, setDateRange] = useState({
        start: new Date(),
        end: new Date(),
    });
    /* Toggle y-axis origin on graph. */
    const [showYAxisOrigin, setShowYAxisOrigin] = useState(true);
    const toggleYAxisOrigin = () => {
        const toggledState = !showYAxisOrigin;
        setShowYAxisOrigin(toggledState);
    };
    /* Toggle alarms on graph. */
    const [showAlarmRegions, setShowAlarmRegions] = useState(false);
    const toggleAlarmRegions = () => {
        const toggledState = !showAlarmRegions;
        setShowAlarmRegions(toggledState);
    };

    const handlePlotRequest = () => {
        fetchParamData(selectedParams, dateRange);
    };

    return (
        <div className={classes.root}>
            <div className={classes.rowHeader}>
                <Typography
                    variant="button"
                    align="left"
                    display="inline"
                    className={classes.rowHeaderText}
                >
                    {title}
                </Typography>
                <Button
                    variant="text"
                    size="large"
                    className={classes.rowShowHideButton}
                    onClick={toggleRowVisible}
                    endIcon={(rowVisible) ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                >
                    {(rowVisible) ? 'Hide' : 'Show'}
                </Button>
            </div>
            <Collapse
                in={rowVisible}
                timeout={showHideTimeMs}
                collapsedHeight="0px"
                className={classes.content}
            >
                <div className={classes.flexContainer}>
                    <ParameterTable
                        parameters={parameters}
                        selected={selectedParams}
                        setSelected={setSelectedParams}
                        updateFlashMsg={updateFlashMsg}
                    />
                    <DateRangeSelector
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        showAlarmRegions={showAlarmRegions}
                        toggleAlarmRegions={toggleAlarmRegions}
                        showYAxisOrigin={showYAxisOrigin}
                        toggleYAxisOrigin={toggleYAxisOrigin}
                        plotEnabled={!!(selectedParams.length && dateRange.end > dateRange.start)}
                        handlePlotRequest={handlePlotRequest}
                        updateFlashMsg={updateFlashMsg}
                    />
                    <ParamChart
                        parameters={parameters}
                        graphData={parameterLogs}
                        historicAlarms={historicAlarms}
                        showAlarmRegions={showAlarmRegions}
                        showYAxisOrigin={showYAxisOrigin}
                    />
                </div>
            </Collapse>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}      title              Top banner display title.
 * @param {Object}      parameters         Parameter id-name associative array.
 * @param {Array}       historicAlarms     Historic alarms list.
 * @param {Function}    fetchParamData     Request parameter data to plot.
 * @param {Function}    updateFlashMsg     Flash message callback.
 * @param {number}      showHideTimeMs     Collapse animation time (ms).
 * @param {Array}       parameterLogs      Any requested datapoint values.
 */
ChartGridRow.propTypes = {
    title: PropTypes.string.isRequired,
    parameters: PropTypes.instanceOf(Object).isRequired,
    historicAlarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,

    fetchParamData: PropTypes.func.isRequired,
    updateFlashMsg: PropTypes.func.isRequired,
    showHideTimeMs: PropTypes.number.isRequired,

    parameterLogs: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,
};

export default ChartGridRow;
