/**
 * @file      AbsDateRangeSelect.js
 *
 * @brief     Absolute date range selection component.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';
import React from 'react';

import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';

import styles from './styles/AbsDateRangeSelectStyles';

const useStyles = makeStyles(styles);

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function AbsDateRangeSelect(props) {
    const { dateRange, setDateRange } = props;

    const classes = useStyles();

    const { start, end } = dateRange;

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() > selectedDate.getTime();
    };

    return (
        <>
            <ListItem key="datepicker-start" className={classes.intervalRow}>
                <ListItemText className={classes.intervalDesc} primary="Start" />
                <div className={classes.calendar}>
                    <DatePicker
                        selected={start}
                        onChange={(date) => setDateRange({ start: date, end })}
                        dateFormat="MMMM d, yyyy  HH:mm"
                        // maxDate={end} // slightly confusing when we don't see both calendars together
                        maxDate={new Date()}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        filterTime={filterPassedTime}
                        fixedHeight
                        popperPlacement="bottom-end"
                    />
                </div>
            </ListItem>
            <Divider key="datepicker-start-divide" className={classes.listDivider} variant="inset" component="li" />

            <ListItem key="datepicker-end" className={classes.intervalRow}>
                <ListItemText className={classes.intervalDesc} primary="End" />
                <div className={classes.calendar}>
                    <DatePicker
                        selected={end}
                        onChange={(date) => setDateRange({ start, end: date })}
                        dateFormat="MMMM d, yyyy  HH:mm"
                        maxDate={new Date()}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        filterTime={filterPassedTime}
                        fixedHeight
                        popperPlacement="bottom-end" // previously "left-start"
                    />
                </div>
            </ListItem>
            <Divider key="datepicker-end-divide" className={classes.listDivider} variant="inset" component="li" />
        </>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}    dateRange     Start and end JS Date objects.
 * @param {Function}  setDateRange  Update parent date range state.
 */
AbsDateRangeSelect.propTypes = {
    dateRange: PropTypes.shape({
        start: PropTypes.instanceOf(Object),
        end: PropTypes.instanceOf(Object),
    }).isRequired,
    setDateRange: PropTypes.func.isRequired,
};

export default AbsDateRangeSelect;
