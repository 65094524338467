/**
 * @file      ParameterTable.js
 *
 * @brief     Component to select parameters for plotting.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import InsertChartIcon from '@material-ui/icons/InsertChart';
import { makeStyles } from '@material-ui/core/styles';

import { sortTableRowsOnHeading } from 'helpers/globalConstants';

import styles from './styles/ParameterTableStyles';

const useStyles = makeStyles(styles);

const MAX_GRAPHABLE_PARAMS = 4;

/**
 * Generate parameter table rows.
 *
 * @param {Object}   classes             A hook returned by makeStyles to access custom classes.
 * @param {Object}   parameters          Parameter id-name associative array.
 * @param {string}   orderBy             Column heading id to sort rows on.
 * @param {string}   orderDir            Column sort direction in ['asc', 'desc'].
 * @param {Array}    selected            Selected parameter id array.
 * @param {Function} handleSelectToggle  Update parent selected ids array. Calls setSelected hook.
 * @param {string}   filterValue         Parameter name filter.
 *
 * @returns {Obejct}  A React node.
 */
function generateParamTableRows(
    classes,
    parameters,
    orderBy,
    orderDir,
    selected,
    handleSelectToggle,
    filterValue,
) {
    /* Parameters as a list of objects rather than id=>name associative array. */
    const paramList = [];
    /**
     * @FIXME MWP Mar-14-2021 Is there a better way to do this?
     */
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, val] of Object.entries(parameters)) {
        /* Parameter Ids returned from API will certainly be integers. */
        const integerId = parseInt(key, 10);
        paramList.push(
            {
                paramId: integerId,
                paramName: val,
                isSelected: selected.includes(integerId),
            },
        );
    }

    const lowerCaseFilterQuery = filterValue.toLowerCase();

    const rows = sortTableRowsOnHeading(paramList, orderBy, orderDir)
        .filter((p) => p.paramName.toLowerCase().includes(lowerCaseFilterQuery))
        .map((parameter) => {
            const { paramId, paramName, isSelected } = parameter;
            const labelId = `parameter-selection-row-${paramId}`;

            return (
                <TableRow
                    key={paramId}
                    hover
                    onClick={(event) => handleSelectToggle(event, paramId)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    selected={isSelected}
                >
                    <TableCell className={classes.tickboxCell} padding="checkbox">
                        <Checkbox
                            checked={isSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            checkedIcon={<InsertChartIcon />}
                        />
                    </TableCell>
                    <TableCell align="left" className={classes.parameterCell} component="td" id={labelId} scope="row">
                        {paramName}
                    </TableCell>
                </TableRow>
            );
        });

    return rows;
}

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function SortableTablebHead(props) {
    const {
        classes,
        numSelected,
        order,
        orderBy,
        filterValue,
        handleFilterParameters,
        onRequestSort,
        onSelectNoneClick,
    } = props;

    const createSortHandler = (property) => (event) => onRequestSort(event, property);

    return (
        <TableRow className={classes.tableHeadRow}>
            <TableCell
                padding="checkbox"
                className={classes.tickboxHead}
                sortDirection={orderBy === 'isSelected' ? order : false}
            >
                <Checkbox
                    disabled={numSelected === 0}
                    checked={numSelected > 0}
                    onChange={onSelectNoneClick}
                    checkedIcon={<InsertChartIcon />}
                    inputProps={{ 'aria-label': 'remove all selections' }}
                />
                <TableSortLabel
                    active={orderBy === 'isSelected'}
                    direction="desc"
                    onClick={createSortHandler('isSelected')}
                >
                    &nbsp;
                    {(orderBy === 'isSelected') && (
                        <span className={classes.visuallyHidden}>
                            sorting selected
                        </span>
                    )}
                </TableSortLabel>
            </TableCell>
            <TableCell
                align="left"
                className={classes.parameterHead}
                sortDirection={orderBy === 'paramName' ? order : false}
            >
                <TableSortLabel
                    active={orderBy === 'paramName'}
                    direction={orderBy === 'paramName' ? order : 'desc'}
                    onClick={createSortHandler('paramName')}
                >
                    Name
                    {(orderBy === 'paramName') && (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    )}
                </TableSortLabel>
                <span>
                    <form className={classes.filterForm} noValidate autoComplete="off" spellCheck="false">
                        <TextField
                            id="filter-params"
                            label="Filter"
                            value={filterValue}
                            onChange={handleFilterParameters}
                            variant="filled"
                            size="small"
                            margin="none"
                        />
                    </form>
                </span>
            </TableCell>
        </TableRow>
    );
}

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function ParameterTable(props) {
    const {
        parameters,
        selected,
        setSelected,
        updateFlashMsg,
    } = props;

    const classes = useStyles();

    /**
     * Row sorting and filtering.
     */
    const [orderDir, setOrderDir] = useState('asc');
    const [orderBy, setOrderBy] = useState('paramName');
    const [filterValue, setFilterValue] = useState('');

    const handleRequestSort = (event, property) => {
        /* Disallow sorting of selected checkboxes to bottom. */
        let isDescending = false;
        if (property !== 'isSelected') {
            isDescending = (orderBy === property && orderDir === 'desc');
        }
        setOrderDir(isDescending ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleFilterParameters = (event) => {
        setFilterValue(event.target.value);
    };

    const handleDeselectAllClick = () => {
        setSelected([]);
    };

    const handleSelectToggleClick = (event, id) => {
        let newSelected = [];

        if (selected.includes(id)) {
            newSelected = selected.filter((selectedId) => selectedId !== id);
        } else {
            newSelected = [id, ...selected];
        }

        /* This check should not be necessary but it can't hurt. */
        if (newSelected.length > MAX_GRAPHABLE_PARAMS) {
            updateFlashMsg(`Maximum graphable parameters ${MAX_GRAPHABLE_PARAMS}`);
        } else {
            setSelected(newSelected);
        }
    };

    const maxTableRows = 7;
    const numEmptyRows = maxTableRows - Object.keys(parameters).length; // scrollbar shown

    return (
        <div className={classes.root}>
            <Typography className={classes.titleText} variant="h5">
                Parameter Selection
            </Typography>
            <TableContainer className={classes.container}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="parameters table"
                >
                    <TableHead>
                        <SortableTablebHead
                            classes={classes}
                            numSelected={selected.length}
                            order={orderDir}
                            orderBy={orderBy}
                            filterValue={filterValue}
                            handleFilterParameters={handleFilterParameters}
                            onRequestSort={handleRequestSort}
                            onSelectNoneClick={handleDeselectAllClick}
                        />
                    </TableHead>
                    <TableBody>
                        {generateParamTableRows(
                            classes,
                            parameters,
                            orderBy,
                            orderDir,
                            selected,
                            handleSelectToggleClick,
                            filterValue,
                        )}
                        {/* {numEmptyRows > 0 && (
                            <TableRow style={{ height: (55 * numEmptyRows) }}>
                                <TableCell colSpan={2} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>
            {(numEmptyRows < 0)
                && (
                    <div className={classes.tableInnerShadow} />
                )}
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}   classes                   material-ui hook to access custom CSS classes.
 * @param {number}   numSelected               Total parameters selected for graphing.
 * @param {string}   order                     Row order direction - ascending or descending.
 * @param {string}   orderBy                   columnHeading id to order rows on.
 * @param {string}   filterValue               String to filter parameter names by.
 * @param {Function} handleFilterParameters    Set filterValue callback.
 * @param {Function} onRequestSort             Wrapper to call row ordering hooks.
 * @param {Function} onSelectNoneClick         Wrapper to call all parameter deselection hook.
 */
SortableTablebHead.propTypes = {
    classes: PropTypes.shape({
        filterForm: PropTypes.string,
        tableHeadRow: PropTypes.string,
        parameterHead: PropTypes.string,
        tickboxHead: PropTypes.string,
        visuallyHidden: PropTypes.string,
    }).isRequired,

    numSelected: PropTypes.number.isRequired,

    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    filterValue: PropTypes.string.isRequired,
    handleFilterParameters: PropTypes.func.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectNoneClick: PropTypes.func.isRequired,
};

/**
 * @param {Object}      parameters      Parameter id-name associative array.
 * @param {Array}       selected        Selected parameter id array.
 * @param {Function}    setSelected     useState hook for selected id array.
 * @param {Function}    updateFlashMsg  Flash message callback.
 */
ParameterTable.propTypes = {
    parameters: PropTypes.instanceOf(Object).isRequired,
    selected: PropTypes.arrayOf(PropTypes.number).isRequired,
    setSelected: PropTypes.func.isRequired,
    updateFlashMsg: PropTypes.func.isRequired,
};

export default ParameterTable;
