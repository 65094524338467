/**
 * @file      HistoricAlarmTable.js
 *
 * @brief     Historic alarms table.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { LOCALISED_TIME, dateFromUtcString, sortTableRowsOnHeading } from 'helpers/globalConstants';

import styles from './styles/HistoricAlarmTableStyles';

const useStyles = makeStyles(styles);
const defaultColumnSortId = 'start';

/**
 * Generate table rows for each historic alarm.
 *
 * @param {Object} classes   A hook returned by makeStyles to access custom classes.
 * @param {Array}  alarms    Historic alarms list.
 * @param {Object} params    Parameter id-name associative array.
 * @param {string} orderBy   Column heading id to sort rows on.
 * @param {string} orderDir  Column sort direction in ['asc', 'desc'].
 *
 * @returns {Obejct}  A React node.
 */
function generateAlarmTableRows(classes, alarms, params, orderBy, orderDir) {
    const { dateFormat, shortDateFormat, timeFormat } = LOCALISED_TIME;

    const rows = sortTableRowsOnHeading(alarms, orderBy, orderDir).map((alarm, idx) => {
        const {
            param,
            start,
            end,
            type,
            detail,
            // triggerVal,
        } = alarm;
        const shortDateStyles = clsx([classes.alarmDateContainer, classes.shortDate]);

        /**
         * Format dates elements.
         */
        const startLocalTime = dateFromUtcString(start);
        const triggerDateTimeDiv = (
            <div className={classes.alarmDateContainer}>
                <span>{ `${dateFormat.format(startLocalTime)}` }</span>
                <span>{ `@ ${timeFormat.format(startLocalTime)}` }</span>
            </div>
        );

        const alarmStartElemShortFormat = (
            <div className={shortDateStyles}>
                <span>{ `${shortDateFormat.format(startLocalTime)}` }</span>
                <span>{ ` @ ${timeFormat.format(startLocalTime)}`}</span>
            </div>
        );

        let clearDateTimeDiv = null;
        let startStopDateTimeDiv = null;

        /* Alarm currently active. */
        if (end === null) {
            const alarmIsActiveClearSpan = <span className={classes.alarmActiveSpan}>Active Now</span>;

            clearDateTimeDiv = (
                <div className={classes.alarmDateContainer}>
                    {alarmIsActiveClearSpan}
                </div>
            );

            startStopDateTimeDiv = (
                <div className={classes.alarmStartStopContainer}>
                    {alarmStartElemShortFormat}
                    <div>
                        {alarmIsActiveClearSpan}
                    </div>
                </div>
            );
        /* Alarm cleared. */
        } else {
            const endLocalTime = dateFromUtcString(end);
            clearDateTimeDiv = (
                <div className={classes.alarmDateContainer}>
                    <span>{ `${dateFormat.format(endLocalTime)}` }</span>
                    <span>{ `@ ${timeFormat.format(endLocalTime)}` }</span>
                </div>
            );

            startStopDateTimeDiv = (
                <div className={classes.alarmStartStopContainer}>
                    {alarmStartElemShortFormat}
                    <div className={shortDateStyles}>
                        <span>{ `${shortDateFormat.format(endLocalTime)}` }</span>
                        <span>{ ` @ ${timeFormat.format(endLocalTime)}`}</span>
                    </div>
                </div>
            );
        }

        /* Using array index as key is not ideal. */
        const key = `alarm${idx}`;
        return (
            <TableRow key={key}>
                <TableCell component="th" scope="row">
                    {params[param]}
                </TableCell>
                <TableCell align="left" className={classes.alarmStartStopCell}>{startStopDateTimeDiv}</TableCell>
                <TableCell align="left" className={classes.alarmTriggerCell}>{triggerDateTimeDiv}</TableCell>
                <TableCell align="left" className={classes.alarmClearCell}>{clearDateTimeDiv}</TableCell>
                <TableCell align="left" className={classes.alarmTypeCell}>{type}</TableCell>
                <TableCell align="left" className={classes.alarmDetailCell}>{detail}</TableCell>
            </TableRow>
        );
    });

    return rows;
}

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function SortableTableSubHead(props) {
    const {
        classes,
        columnHeadings,
        order,
        orderBy,
        onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => onRequestSort(event, property);

    return (
        <TableRow>
            {columnHeadings.map((column) => (
                <TableCell
                    key={column.id}
                    className={classes[column.className]}
                    sortDirection={orderBy === column.id ? order : false}
                >
                    {(column.sortable)
                        ? (
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={createSortHandler(column.id)}
                            >
                                {column.label}
                                {(orderBy === column.id) && (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                )}
                            </TableSortLabel>
                        ) : (column.label)}
                </TableCell>
            ))}
        </TableRow>
    );
}

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function HistoricAlarmTable(props) {
    const { shortTzName } = LOCALISED_TIME;
    const { historicAlarms, parameters, columnHeadings } = props;
    const classes = useStyles();

    /**
     * Row sorting by column.
     */
    const [orderDir, setOrderDir] = useState('desc');
    const [orderBy, setOrderBy] = useState(defaultColumnSortId);

    const handleRequestSort = (event, property) => {
        /* Sort 'virtual' column startStop by start date. */
        const orderProperty = (property === 'startStop') ? defaultColumnSortId : property;

        const isDesc = (orderBy === orderProperty && orderDir === 'desc');
        setOrderDir(isDesc ? 'asc' : 'desc');
        setOrderBy(orderProperty);
    };

    return (historicAlarms.length)
        ? (
            <div className={classes.container}>
                <TableContainer>
                    <Table className={classes.alarmTable} aria-label="historic alarms table">
                        <TableHead>
                            <TableRow className={classes.timeZoneRow}>
                                <TableCell align="right" className={classes.spanningCellEmpty}> </TableCell>
                                <TableCell align="center" colSpan={2}>
                                    {`Time (${shortTzName})`}
                                </TableCell>
                                <TableCell align="right" className={classes.spanningCellEmpty} colSpan={2}> </TableCell>
                            </TableRow>
                            <SortableTableSubHead
                                classes={classes}
                                columnHeadings={columnHeadings}
                                order={orderDir}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                        </TableHead>
                        <TableBody>
                            {generateAlarmTableRows(classes, historicAlarms, parameters, orderBy, orderDir)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
        : (
            <div className={classes.container}>
                <Typography className={classes.noAlarmsText} variant="body1">
                    No alarms triggered during the previous month.
                </Typography>
            </div>
        );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}   classes           material-ui hook to access custom CSS classes.
 * @param {Array}    columnHeadings    Table headings for each column.
 * @param {string}   order             Row order direction - ascending or descending.
 * @param {string}   orderBy           columnHeading id to order rows on.
 * @param {Function} onRequestSort     Wrapper to call row ordering hooks.
 */
SortableTableSubHead.propTypes = {
    classes: PropTypes.shape({
        visuallyHidden: PropTypes.string,
    }).isRequired,

    columnHeadings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,

    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

/**
 * @param {Array}    historicAlarms    Historic alarms list.
 * @param {Object}   parameters        Parameters id=>name associative array.
 * @param {Array}    columnHeadings    Table headings for each column.
 */
HistoricAlarmTable.propTypes = {
    historicAlarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,

    parameters: PropTypes.shape({
        param: PropTypes.number,
        start: PropTypes.string,
        end: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(null),
        ]),
        detail: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(null),
        ]),
        triggerVal: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.instanceOf(null),
        ]),
        type: PropTypes.string,
    }).isRequired,

    columnHeadings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default HistoricAlarmTable;
