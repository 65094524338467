/**
 * @file      useJwtState.js
 *
 * @brief     React hook to manipulate JSON Web Tokens.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import { useState, useEffect } from 'react';

import useCookieState from './useCookieState';

/**
 * Functions for manipulating JSON web tokens.
 *
 * @param {string} urlDomain    The host domain we are being served from.
 */
export default function useJwtState(urlDomain) {
    /**
     * Store a JSON web token in a cookie to provide persistence across refreshes.
     * Using cookies (with domain) provides slightly more protection against XSS than using localStorage.
     * See https://security.stackexchange.com/questions/179498/is-it-safe-to-store-a-jwt-in-sessionstorage/179507#179507
     *   for server-side headers/config.
     *
     * I don't believe the security argument for turning off cookie storage is strong but leave the option open.
     */
    const tokenCookieName = 'token';
    const storeInMemoryOnly = false; // turn on cookie storage
    const [apiTokenInMemoryOnlyState, setApiTokenInMemoryOnlyState] = useState();
    const [apiTokenCookieState, setApiTokenCookieState] = useCookieState(tokenCookieName, urlDomain);

    /* Reserve state for token claims. */
    // eslint-disable-next-line no-unused-vars
    const [tokenIssuerState, setTokenIssuerState] = useState();
    const [tokenExpirationState, setTokenExpirationState] = useState();
    const [tokenInstallationsState, setTokenInstallationsState] = useState([]);
    const [tokenRolesState, setTokenRolesState] = useState([]);
    const [tokenDashboardState, setTokenDashboardState] = useState('default');

    /* Development settings. */
    const develEnvironment = (process.env.REACT_APP_ENV === 'development');
    // Throws TypeError on invalid URL. No try-catch. Fix development .env.
    const develUrl = new URL(process.env.REACT_APP_DEVEL_ROOT_URL);
    const develHostName = develUrl.hostname;

    /**
     * Dashboard API token getter.
     *
     * @return {string}  JSON web token string or null if unset.
     */
    function getApiToken() {
        return (storeInMemoryOnly) ? apiTokenInMemoryOnlyState : apiTokenCookieState;
    }

    /**
     * Dashboard API token setter.
     *
     * @param {string} newToken   JSON web token string.
     */
    function setApiToken(newToken) {
        // *** DEBUG ***
        // console.log(`useJwtState.setApiToken() called with new token \n${newToken}`);

        if (storeInMemoryOnly) {
            setApiTokenInMemoryOnlyState(newToken);
        } else {
            setApiTokenCookieState(newToken);
        }
    }

    /**
     * Dashboard user privileges getter.
     *
     * @returns {Object} User privileges defined in token claims.
     */
    function getUserPrivilegesFromToken() {
        return {
            dashboard: tokenDashboardState,
            installations: tokenInstallationsState,
            roles: tokenRolesState,
        };
    }

    /**
     * Decode JWT claims and return a subset.
     * Returned claims may be undefined if argument is not a valid dashboard token.
     *
     * @param {string} jwt    A JSON web token.
     *
     * @return {Object}    Containing a subset of token claims:
     *                        iss            Token Issuer claim. A URL string.
     *                        exp            Token Expiration claim. Unix epoch seconds.
     *                        installations  Token Custom claim. An array of integer installation ids.
     *                        roles          Token Custom claim. An array of role permissions.
     *                        dashboard      Token Custom claim. The dashboard this token is authorised for.
     */
    function parseTokenClaims(jwt) {
        /* Token claims of interest to us. */
        const undefinedClaims = {
            iss: undefined,
            exp: undefined,
            installations: undefined,
            roles: undefined,
            dashboard: undefined,
        };

        try {
            const payloadB64UrlEncoded = jwt.split('.')[1];
            const payloadB64 = payloadB64UrlEncoded.replace('-', '+').replace('_', '/');
            const tokenClaims = JSON.parse(window.atob(payloadB64));
            const {
                iss,
                exp,
                installations,
                roles,
                dashboard,
            } = tokenClaims;

            // console.log('useJwtState.parseTokenClaims()');
            // console.log(tokenClaims);

            return {
                iss,
                exp,
                installations,
                roles,
                dashboard,
            };
        } catch (e) {
            /**
             * Testing: e instanceof TypeError, DOMException, SyntaxError
             * would determine exactly what went wrong - but we aren't interested.
             */
            return undefinedClaims;
        }
        /* Unreachable */
    }

    /**
     * When token state changes, parse new token and update claims state.
     */
    useEffect(() => {
        // *** DEBUG ***
        // eslint-disable-next-line max-len
        // console.log('%cuseJwtState.useEffect()%c - set token claims state.', 'background:paleturquoise; color:black;', 'color:mediumturquoise;');

        /* Calling getApiToken() here will cause state update loop. */
        const token = (storeInMemoryOnly) ? apiTokenInMemoryOnlyState : apiTokenCookieState;
        const {
            iss,
            exp,
            installations,
            roles,
            dashboard,
        } = parseTokenClaims(token);

        /* Some or all token claims may be undefined. */
        setTokenIssuerState(iss);
        setTokenExpirationState(exp);
        setTokenInstallationsState(installations);
        setTokenRolesState(roles);
        setTokenDashboardState(dashboard);
    }, [apiTokenInMemoryOnlyState, apiTokenCookieState, storeInMemoryOnly]);

    /**
     * Check that the current token state has not expired and contains (some of the) expected claims.
     *
     * @return {boolean}  The token claims are superficially valid.
     */
    function currentTokenIsSuperficiallyValid() {
        // console.log(`useJwtState.currentTokenIsSuperficiallyValid() - checking claims for domain ${urlDomain}`);

        const token = getApiToken();
        const {
            iss,
            exp,
            installations,
            roles,
        } = parseTokenClaims(token);

        const unixSecondsNow = Math.floor(Date.now() / 1000);
        const tokenNotYetExpired = Number.isInteger(exp) && (exp > unixSecondsNow);

        let tokenIssuerDomainMatchesUrl;
        try {
            const issuerHostname = new URL(iss).hostname; // TypeError on invalid url
            /**
             * @NOTE Devel steps. Also match devel server hostname (where tokens will be issued).
             */
            tokenIssuerDomainMatchesUrl = (develEnvironment)
                ? (issuerHostname === urlDomain) || (issuerHostname === develHostName)
                : (issuerHostname === urlDomain);
        } catch (e) {
            tokenIssuerDomainMatchesUrl = false;
        }

        /**
         * Allow empty installations array.
         * TODO: Add logic to support: installation ranges (N-M), exclusions (!P), and parent entities ('everything')
         */
        const tokenInstallationsIsValid = Array.isArray(installations);

        // let tokenRolesAreValid = Array.isArray(roles) && roles.includes('dash');
        const tokenRolesAreValid = Array.isArray(roles) && roles.length;

        return tokenNotYetExpired && tokenIssuerDomainMatchesUrl && tokenInstallationsIsValid && tokenRolesAreValid;
    }

    /**
     * Determine if token expiration state is still valid.
     *
     * @return {boolean}  Token expiry timestamp comes chronologically after the current time.
     */
    function currentTokenHasNotExpired() {
        const unixSecondsNow = Math.floor(Date.now() / 1000);
        const tokenExpiryTime = tokenExpirationState;

        return Number.isInteger(tokenExpiryTime) && (tokenExpiryTime > unixSecondsNow);
    }

    return {
        getApiToken,
        setApiToken,
        getUserPrivilegesFromToken,
        currentTokenIsSuperficiallyValid,
        currentTokenHasNotExpired,
    };
}
