/**
 * @file      EventLogTypeList.js
 *
 * @brief     Component to select eventlog types for display.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import styles from './styles/EventLogTypeListStyles';

const useStyles = makeStyles(styles);

/**
 * Generate eventlog type selection nodes.
 *
 * @param {Object}   classes             A hook returned by makeStyles to access custom classes.
 * @param {Array}    typeNames           EventLog friendly names.
 * @param {Object}   typeIcons           Friendly name-material-ui icon associative array.
 * @param {Array}    selected            Selected event type (friendly) names array.
 * @param {Function} handleSelectToggle  Update parent selected eventlog types array. Calls setSelected hook.
 *
 * @returns {Obejct}  A React node.
 */
function generateEventLogTypeNodes(
    classes,
    typeNames,
    typeIcons,
    selected,
    handleSelectToggle,
) {
    const nodes = typeNames.map((name) => {
        const key = `eventlog-type-${name.replace(/ /g, '-')}`;
        const icon = typeIcons[name];
        const currentlySelected = selected.includes(name);

        return (
            <Button
                key={key}
                className={classes.logTypeSelectBtn}
                variant={currentlySelected ? 'contained' : 'outlined'}
                color="primary"
                disableElevation
                startIcon={icon}
                onClick={() => handleSelectToggle(name)}
            >
                <span className={classes.logTypeText}>
                    {name}
                </span>
            </Button>
        );
    });

    return nodes;
}

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function EventLogTypeList(props) {
    const {
        typeNames,
        typeIcons,
        selected,
        setSelected,
    } = props;

    // *** DEBUG ***
    // console.log('RENDER EVENTLOG TYPES');

    const allTypesAreSelected = (typeNames.length === selected.length);
    const zeroTypesAreSelected = (selected.length === 0);

    const classes = useStyles();

    const handleSelectToggleClick = (type) => {
        let newSelected = [];

        if (selected.includes(type)) {
            newSelected = selected.filter((selectedType) => selectedType !== type);
        } else {
            newSelected = [type, ...selected];
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = () => {
        setSelected([...typeNames]);
    };

    const handleSelectNoneClick = () => {
        setSelected([]);
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.titleText} variant="h5">
                Categories
            </Typography>
            <div className={classes.types}>
                <div className={classes.typesContainer}>
                    <Button
                        key="eventlog-type-all"
                        className={classes.logTypeSelectAllNone}
                        disabled={allTypesAreSelected}
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleSelectAllClick}
                    >
                        Select All
                    </Button>
                    <Button
                        key="eventlog-type-none"
                        className={classes.logTypeSelectAllNone}
                        disabled={zeroTypesAreSelected}
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleSelectNoneClick}
                    >
                        Select None
                    </Button>
                    <div className={classes.typesContainerBreak} />
                    {generateEventLogTypeNodes(
                        classes,
                        typeNames,
                        typeIcons,
                        selected,
                        handleSelectToggleClick,
                    )}
                </div>
            </div>
        </div>
    );
}

/**
 * @param {Array}       typeNames       EventLog friendly names.
 * @param {Object}      typeIcons       Material-UI icon nodes associative array.
 * @param {Array}       selected        Selected event type (friendly) names array.
 * @param {Function}    setSelected     useState hook for selected event types array.
 */
EventLogTypeList.propTypes = {
    typeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    typeIcons: PropTypes.objectOf(PropTypes.node).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelected: PropTypes.func.isRequired,
};

export default EventLogTypeList;
