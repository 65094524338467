/**
 * @file      InstallationTableStyles.js
 *
 * @brief     InstallationsTable component styling.
 *            Passed down to child Components (EntityInstallationsSubTable, EntityInstallationsRow).
 *            It would probably be prefferable to split this into three.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import Colour from 'color';
import { makeStyles } from '@material-ui/core/styles';

import theme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const pulseRowColour = Colour(theme.palette.primary.main).fade(0.4).string();
const pulseRowHighlight = Colour(theme.palette.primary.main).fade(0.8).string();
const MAT_UI_TABLE_HEAD_HEIGHT = 58;
const MARGIN_DOUBLE = theme.space.margin.defaultVertical * 2;

/**
 * These styles contain an awful lot of pixel pushing to squeeze
 *   table rows down to portrait phone sizes (< 576px).
 * When making changes to element styles, be sure that the table row width
 *   (defined as 100%) remains equal to 360px when displayed on e.g. Moto G4.
 */
const styles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'start center',
    },

    allTablesContainer: {
        marginTop: theme.space.margin.defaultVertical,
        marginBottom: MARGIN_DOUBLE,
        /**
         * Media query-based width.
         * Extra Large devices (>=1600px) is the default. Order is important.
         */
        width: '76%',
        maxWidth: 1400,
        [sizes.widthMax('xl')]: {
            width: '85%',
        },
        [sizes.widthMax('lg')]: {
            width: '92%',
        },
        [sizes.widthMax('md')]: {
            width: '95%',
        },
        [sizes.widthMax('sm')]: {
            width: '100%',
        },
        [sizes.widthMax('xs')]: {
            marginTop: 0,
        },
    },

    /**
     * EntityInstallationsSubTable begin.
     */
    parentEntityContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        marginBottom: theme.space.margin.defaultVertical,
    },

    /* Parent Entity grouping. */
    parentEntityControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',

        width: 300,
        height: MAT_UI_TABLE_HEAD_HEIGHT,
        borderRadius: '12px 0 0 0',
        padding: 0,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderBottom: '1px solid rgba(224, 224, 224, 1)',

        boxShadow: `
            0px 2px 4px -1px rgba(0,0,0,0.2),
            0px 4px 5px 0px rgba(0,0,0,0.14),
            0px 1px 10px 0px rgba(0,0,0,0.12)`,

        '& .MuiTypography-button': {
            fontSize: theme.typography.sizes.rowHeading,
        },

        /* Full width on portrait phone. */
        [sizes.widthMax('xs')]: {
            width: '100%',
            borderRadius: 0,
            boxShadow: 'none',
        },
    },
    parentText: {
        maxWidth: '55%',
        marginLeft: 16,

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    parentShowHideButton: {
        width: 96,
        padding: '8px 16px 8px 8px',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },

        /* Align label and icon. */
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        fontSize: theme.typography.sizes.rowHeading,
    },

    /**
     * Table Container and Rows.
     */
    tableContainer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,

        '& .MuiTableCell-head': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.table.headRow,
            fontSize: theme.typography.sizes.tableHead,

            paddingLeft: 16,
            paddingRight: 16,
            /* Reduce padding on portrait phone. */
            [sizes.widthMax('xs')]: {
                paddingLeft: 4,
                paddingRight: 4,
            },
        },
        '& .MuiTableCell-body': {
            color: theme.palette.text.primary,
            fontSize: theme.typography.sizes.tableBody,

            paddingLeft: 16,
            paddingRight: 16,
            /* Reduce padding on portrait phone. */
            [sizes.widthMax('xs')]: {
                paddingLeft: 4,
                paddingRight: 4,
            },
        },

        '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
            backgroundColor: theme.palette.table.darkRow,
        },
        '& .MuiTableRow-root:hover': {
            backgroundColor: theme.palette.table.darkRow,
        },
    },
    table: {
        minWidth: 360,
    },
    tablePagination: {
        width: '100%',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.table.headRow,
        fontSize: theme.typography.sizes.tableHead,

        '& .MuiTypography-body2': {
            fontSize: theme.typography.sizes.tableHead,
        },
        '& .MuiTablePagination-toolbar': {
            paddingLeft: 8,
        },
        '& .MuiTablePagination-caption': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .MuiTablePagination-input': {
            fontSize: theme.typography.sizes.tableHead,
            /* Reduce margin on portrait phone. */
            [sizes.widthMax('xs')]: {
                marginLeft: 0,
                marginRight: 12,
            },
        },
        '& .MuiTablePagination-actions': {
            /* Reduce margin on portrait phone. */
            [sizes.widthMax('xs')]: {
                marginLeft: 4,
            },
        },
    },

    tableRow: {
        background: 'transparent',
        cursor: 'default',
        /* NOTE: Expected row height is 67px (height 66 plus border bottom). */
    },
    tableRowPulse: {
        background: `linear-gradient(
            to right,
            transparent,
            transparent 45%,
            ${pulseRowColour} 60%,
            ${pulseRowHighlight} 60%,
            ${pulseRowHighlight} 60.05%,
            transparent 60.05%
        )`,
        backgroundSize: '300% 100%',

        /* @keyframes | duration | timing-function | delay | iteration-count | direction | fill-mode | play-state */
        animation: '$row-pulse-animation 1800ms ease-in 0s 1 normal forwards running',
    },
    '@keyframes row-pulse-animation': {
        '0%': {
            backgroundPosition: '90% 50%',
        },
        '100%': {
            backgroundPosition: '15% 50%',
        },
    },

    /**
     * Table Header cells from EntitySubTableHead.
     * Commented-out backgroundColors to help formatting.
     */
    tableHeaderName: {
        // backgroundColor: 'rgba(255, 0, 0, 1) !important',
        textAlign: 'left',

        [sizes.widthMax('xs')]: {
            paddingRight: '0 !important',
        },
    },
    tableHeaderRctType: {
        // backgroundColor: 'rgba(255, 255, 0, 1) !important',
        textAlign: 'left',

        /* Hide RCT type at smaller page widths. */
        [sizes.widthMax('md')]: {
            display: 'none',
        },
    },
    tableHeaderAlarms: {
        // backgroundColor: 'rgba(255, 0, 255, 1) !important',
        textAlign: 'center',
        minWidth: 75,

        /* Hide 'Alarm' heading at smaller page widths. */
        [sizes.widthMax('xs')]: {
            width: 38,
            minWidth: 38,
            '& span': {
                display: 'none',
            },
        },
    },
    tableHeaderDatapost: {
        // backgroundColor: 'rgba(0, 255, 255, 1) !important',
        textAlign: 'left',
        whiteSpace: 'normal', // Allow text to flow over 2 lines.
    },
    tableHeaderVpnContact: {
        // backgroundColor: 'rgba(0, 0, 255, 1) !important',
        textAlign: 'left',
        [sizes.widthMax('sm')]: {
            textAlign: 'center',
        },
    },

    /**
     * Table data cells from EntityInstallationsRow.
     */
    tableCellName: {
        // backgroundColor: 'rgba(255, 0, 0, 0.5) !important',
        textAlign: 'left',

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        width: '20%',
        minWidth: 135,
        [sizes.widthMax('xs')]: {
            width: 135,
            paddingRight: '0 !important',
        },
    },
    tableCellRctType: {
        // backgroundColor: 'rgba(255, 255, 0, 0.5) !important',
        textAlign: 'left',
        width: '12%',

        /* Hide RCT type at smaller page widths. */
        [sizes.widthMax('md')]: {
            display: 'none',
        },
    },
    tableCellAlarms: {
        // backgroundColor: 'rgba(255, 0, 255, 0.5) !important',
        padding: 0,
        textAlign: 'center',
        width: '8%',
        /* Limit width on portrait phone. */
        [sizes.widthMax('xs')]: {
            width: 44,
            padding: '0px 2px !important',
        },

        '& .MuiBadge-root': {
            /* Pulse animation blending. */
            mixBlendMode: 'multiply',

            marginRight: 20,
            /* Align icon at smaller page widths. */
            [sizes.widthMax('xs')]: {
                textAlign: 'left',
                marginRight: 0,
            },
        },
        '& .MuiBadge-badge': {
            borderRadius: '50%',
            right: 15,
            top: 4,
            backgroundColor: `${theme.palette.vpn.dead}`,
            border: `2px solid ${theme.palette.background.paper}`,
            fontSize: `${theme.typography.sizes.tooltip}`,
            padding: 6,
        },
        '& .MuiSvgIcon-root': {
            height: 30,
            width: 30,
            paddingRight: 10,
        },
    },
    tableCellDatapost: {
        // backgroundColor: 'rgba(0, 255, 255, 0.5) !important',
        /* minWidth defined by fixed-width spans below. */
        paddingTop: 0,
        paddingBottom: 0,
        textAlign: 'left',
        width: '32%',

        '& div': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',

            /* Long date text. */
            '& span:nth-of-type(1)': {
                width: 160,
            },
            /* Short date text. */
            '& span:nth-of-type(2)': {
                width: 100,
                display: 'none',
            },
            /* @Time text. */
            '& span:nth-of-type(3)': {
                width: 70,
            },
            /* Use short date format at smaller widths. */
            [sizes.widthMax('sm')]: {
                '& span:nth-of-type(1)': {
                    display: 'none',
                },
                '& span:nth-of-type(2)': {
                    display: 'inline',
                },
            },
        },
    },
    tableCellVpnContact: {
        // backgroundColor: 'rgba(0, 0, 255, 0.5) !important',
        /* minWidth defined header (VPN plus sort icon). */
        textAlign: 'left',
        width: '28%',

        paddingTop: 0,
        paddingBottom: 0,
        /* Centre icon on portrait phone. */
        [sizes.widthMax('xs')]: {
            padding: '0px !important',
        },

        '& div': {
            padding: '16px 0',

            /* Pulse animation blending. */
            mixBlendMode: 'multiply',

            /* Align icon and text spans. */
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',

            /* Centre icon on portrait phone. */
            [sizes.widthMax('sm')]: {
                justifyContent: 'space-around',
            },

            '& .MuiSvgIcon-root': {
                height: 26,
                width: 26,
                padding: '2px 16px 0 16px',
                [sizes.widthMax('sm')]: {
                    padding: '2px 8px 0 8px',
                },
            },
            '& span:nth-of-type(2)': {
                width: 154,
                /* Hide text on portrait phone. */
                [sizes.widthMax('sm')]: {
                    width: 0,
                    display: 'none',
                },
            },
        },
    },

    /* Hidden header aria label for column ordering. */
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

export default styles;
