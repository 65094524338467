/**
 * @file      AuthenticatedRoute.js
 *
 * @brief     Route wrapper for API token checks.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/**
 * Wrap a React Component with an API token check. This check is not adding extra security.
 * Rather, we seek to to give a better user experience by:
 *   avoiding server round-trips when we obviously aren't authorised.
 *   rendering blank pages, never filled with data because every request is denied.
 *
 * @param {Component} component                           A React Component.
 * @param {Function}  getApiToken                         Token state hook.
 * @param {Function}  currentTokenIsSuperficiallyValid    Returns true if token claims state looks valid for domain.
 *
 * @returns {Route}  A react-router-dom Route.
 *
 */
function AuthenticatedRoute({
    component: Component,
    getApiToken,
    getUserPrivilegesFromToken,
    currentTokenIsSuperficiallyValid,
    componentProps,
    ...rest
}) {
    const tokenValid = currentTokenIsSuperficiallyValid();

    if (!tokenValid) {
        console.error('Token not valid for request.');
    }

    /*
     * I'm not sure it's possible to avoid spreading {...rest} and {...componentProps}.
     * We could extract everything we need from routeProps:
     *   { classes, history, location, match, ... }
     */
    return (
        <Route
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            render={(routeProps) => (tokenValid
                ? (
                    <Component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...routeProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...componentProps}
                        token={getApiToken()}
                        userPrivileges={getUserPrivilegesFromToken()}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: routeProps.location },
                        }}
                    />
                )
            )}
        />
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}   component                           A React Component.
 * @param {Function} getApiToken                         Dashboard API token getter.
 * @param {Function} getUserPrivilegesFromToken          Dashboard token privileges getter.
 * @param {Function} currentTokenIsSuperficiallyValid    Dashboard API token claims checking.
 * @param {Object}   componentProps                      Props passed directly to a specific component.
 */
AuthenticatedRoute.propTypes = {
    component: PropTypes.instanceOf(Object).isRequired,
    getApiToken: PropTypes.func.isRequired,
    getUserPrivilegesFromToken: PropTypes.func.isRequired,
    currentTokenIsSuperficiallyValid: PropTypes.func.isRequired,
    componentProps: PropTypes.instanceOf(Object),
};

AuthenticatedRoute.defaultProps = {
    componentProps: {},
};

export default AuthenticatedRoute;
