/**
 * @file      windowSizes.js
 *
 * @brief     Functions to generate CSS at-media queries. JSS format.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */

/**
 * Reference: Bootstrap Breakpoints
 * Small devices (landscape phones, 576px and up)
 * @media (min-width: 576px) { ... }
 *
 * Medium devices (tablets, 768px and up)
 * @media (min-width: 768px) { ... }
 *
 * Large devices (desktops, 992px and up)
 * @media (min-width: 992px) { ... }
 *
 * Extra large devices (large desktops, 1200px and up)
 * @media (min-width: 1200px) { ... }
 */

/**
 * Return Material-UI responsive styles.
 * Minimum phone width we support in PageRootStyles is 360px.
 */
const sizes = {
    widthMin(size) {
        // this size and greater
        const gtSizes = {
            xs: '576px',
            sm: '768px',
            md: '992px',
            lg: '1200px',
            xl: '1600px',
        };
        return `@media screen and (min-width: ${gtSizes[size]})`;
    },
    widthMax(size) {
        // this size and smaller
        const ltSizes = {
            xs: '576px',
            sm: '768px',
            md: '992px',
            lg: '1200px',
            xl: '1600px',
        };
        return `@media screen and (max-width: ${ltSizes[size]})`;
    },
};

export default sizes;
