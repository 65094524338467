/**
 * @file      Overview.js
 *
 * @brief     Overview route controller.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { Component, forwardRef } from 'react';

import { withStyles } from '@material-ui/core';

import {
    PAGE_TITLE_PREFIX,
    API_ROUTES,
    fetchAuthorisedResource,
    tokenErrorsReturned,
    parseResponseErrorsToString,
} from 'helpers/globalConstants';

import AppHeader from 'global_components/AppHeader';
import FlashMessage from 'global_components/FlashMessage';
import styles from 'global_styles/PageRootStyles';

import InstallationsTable from './InstallationsTable';

const ForwardReferencedAppHeader = forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AppHeader scrollRef={ref} {...props} />
));

/**
 * React class-based component.
 */
class Overview extends Component {
    constructor() {
        super();
        /* Cancel all subscriptions and asynchronous tasks in componentWillUnmount method.
         * https://stackoverflow.com/questions/52061476/
         */
        this._isMounted = false;

        /* Create ref for parent div. */
        this.myRef = React.createRef();

        this.state = {
            entities: {},
            installations: {},
            pulse: {},
            tableRows: {},
            loaded: false,
            flashError: '',
            flashInfo: '',
        };
        this.pulseIntervalTimer = null;
        this.fetchInitialState = this.fetchInitialState.bind(this);
        this.fetchHeartbeat = this.fetchHeartbeat.bind(this);
        this.formatInstallationTableRows = this.formatInstallationTableRows.bind(this);
        this.formatHeartbeat = this.formatHeartbeat.bind(this);
        this.handleHeartbeatError = this.handleHeartbeatError.bind(this);
        this.handleInstallationNav = this.handleInstallationNav.bind(this);
        this.clearDatapostInfoMsg = this.clearDatapostInfoMsg.bind(this);
    }

    /**
     * Lifecycle methods.
     */
    componentDidMount() {
        const { pageTitle } = this.props;
        document.title = `${pageTitle} | ${PAGE_TITLE_PREFIX}`;
        this._isMounted = true;
        this.fetchInitialState();
    }

    componentDidUpdate(prevProps, prevState) {
        /* Successfully loaded initial state. Poll API for updates. */
        const { loaded } = this.state;
        const { pulseFrequencyMs } = this.props;

        if (loaded && !prevState.loaded) {
            this.pulseIntervalTimer = setInterval(
                () => this.fetchHeartbeat(),
                pulseFrequencyMs,
            );
        }
    }

    componentWillUnmount() {
        /* Clear heartbeat polling timer and set isMounted flag false to prevent memory leaks. */
        this._isMounted = false;
        clearInterval(this.pulseIntervalTimer);
        this.pulseIntervalTimer = null;
    }

    /**
     * Fetch initial component state needed to generate a table of installations.
     *
     * @return {Array}  Return an array of promises.
     *                  Each promise resolves to an object { success: boolean, data: Object }
     */
    fetchInitialState = () => {
        const { apiRoutes, token, history } = this.props;

        /**
         * Make multiple requests in parallel.
         * Promise.all() will resolve when all of the input promises have resolved.
         */
        const apiResponses = Promise.all(apiRoutes.map((route) => {
            const apiRoutesKey = Object.values(route)[0]; // each apiRoute object contains exactly one key-value pair.
            return fetchAuthorisedResource(API_ROUTES[apiRoutesKey], token);
        }));

        apiResponses.then((responses) => {
            /* Create arrays parallel to apiResponses. */
            const stateKeys = apiRoutes.map((route) => Object.keys(route)[0]);
            const apiRouteKeys = apiRoutes.map((route) => Object.values(route)[0]);

            const newComponentState = { flashError: '', loaded: false };
            let tokenNotValid = false;

            responses.forEach((res, idx) => {
                const stateKey = stateKeys[idx];
                const routeKey = apiRouteKeys[idx];
                const { dataKey } = API_ROUTES[routeKey];

                if (res.success) {
                    // *** DEBUG ***
                    // console.log(res.data[dataKey]);
                    newComponentState[stateKey] = res.data[dataKey];
                } else {
                    if (tokenErrorsReturned(res.data)) {
                        tokenNotValid = true;
                    }

                    const errorsSummary = parseResponseErrorsToString(res.data, true); // include debug console output
                    /* If multiple requests fail, we only save the errors summary from the final failed request. */
                    newComponentState.flashError = errorsSummary;
                    newComponentState[stateKey] = {};
                }
            });

            if (tokenNotValid) {
                /* Without interogating token error string we can be pretty sure it requires us to re-authenticate. */
                history.push('/login');
            }

            if (!newComponentState.flashError.length) {
                newComponentState.loaded = true;

                const installationTableRows = this.formatInstallationTableRows(newComponentState);
                newComponentState.tableRows = installationTableRows;
            }

            /* If component is no longer mounted we probably navigated away from the page mid-fetch. */
            /* @TODO Possibly make this a helper function setStateIfMounted() */
            if (this._isMounted) {
                this.setState({ ...newComponentState });
            }
        });
    }

    /**
     * Query API and update this.state.tableRows with fresh information.
     */
    fetchHeartbeat = () => {
        const { token } = this.props;
        const stateKey = 'pulse';
        const route = API_ROUTES.installationsPulse;
        const { dataKey } = route;

        fetchAuthorisedResource(route, token).then((res) => {
            const updatedComponentState = { flashError: '' };
            let tokenNotValid = false;

            if (res.success) {
                // *** DEBUG ***
                // console.log(res.data[dataKey]);
                updatedComponentState[stateKey] = res.data[dataKey];
            } else {
                if (tokenErrorsReturned(res.data)) {
                    tokenNotValid = true;
                }
                const errorsSummary = parseResponseErrorsToString(res.data, true);
                updatedComponentState.flashError = errorsSummary;
            }

            if (tokenNotValid) {
                /* Without interogating token error string we can be pretty sure it requires us to re-authenticate. */
                clearInterval(this.pulseIntervalTimer);
                this.pulseIntervalTimer = null;
                updatedComponentState.flashError = 'Re-Authentication required';
            }

            /* If component is no longer mounted we probably navigated away from the page mid-heartbeat. */
            if (this._isMounted) {
                if (!updatedComponentState.flashError.length) {
                    /* Resource request success. Update table state. */
                    this.setState((st) => {
                        const installationTableRows = this.formatHeartbeat(
                            updatedComponentState[stateKey],
                            st.tableRows,
                        );
                        return {
                            ...st,
                            [stateKey]: updatedComponentState[stateKey],
                            tableRows: installationTableRows,
                            flashError: updatedComponentState.flashError,
                        };
                    });
                } else {
                    /* We have encountered an error. Update error message state only. */
                    this.setState((st) => ({ ...st, flashError: updatedComponentState.flashError }));
                }
            }
        });
    }

    /**
     * Create arrays of table row data grouped by parent entity.
     *
     * @param {Object} componentState    State containing: installations, entities and pulse returned from API.
     *
     * @return {Array}  Table data with which to set state.
     */
    formatInstallationTableRows = (componentState) => {
        const { entities, installations, pulse } = componentState;
        const tableData = {};

        const parentEntities = {};

        entities.forEach((ent) => {
            const { id, name } = ent;
            if (!Object.prototype.hasOwnProperty.call(parentEntities, id)) {
                parentEntities[id] = name;
            }
        });

        /* Group installations by parent entity. */
        installations.forEach((installation) => {
            const parentEntityName = parentEntities[installation.entity.parentId];

            if (!tableData[parentEntityName]) {
                tableData[parentEntityName] = [];
            }

            /* NOTE: This ordering of installations (under parent entity) MUST remain invariant.
             * EntityInstallationSubTable will then sort installations by some criterion (default: last datapost).
             */
            tableData[parentEntityName].push({
                name: installation.entity.name,
                id: installation.id,
                entityId: installation.entity.id,
                rctType: installation.netrixserialnumber.split('/')[0],
            });
        });

        /* Add formatted pulse data and return. */
        return this.formatHeartbeat(pulse, tableData);
    }

    /**
     * Update installation table rows with heartbeat information.
     *
     * @param {Object} pulse                Installation update pulse data returned from API.
     * @param {Object} existingTableRows    Arrays of (incomplete/outdated) installation rows grouped by parent entity.
     *
     * @return {Object}  A new Object containing arrays of completed/updated installation rows, grouped by parent.
     */
    formatHeartbeat = (pulse, existingTableRows) => {
        /* Compare each VPN connection with constant datetime. */
        const epochSecondsNow = Math.floor(Date.now() / 1000);

        const updatedTableRows = {};
        /**
         * @FIXME MWP Dec-06-2020
         * https://github.com/airbnb/javascript/issues/1271#issuecomment-283736133
         */
        // eslint-disable-next-line no-restricted-syntax
        for (const [parentName, installationRows] of Object.entries(existingTableRows)) {
            const updatedInstRowsForEntity = installationRows.map((row) => {
                const {
                    datapost,
                    vpnConnect,
                    vpnContact,
                    vpnWorkingCount,
                    alarmCount,
                } = pulse[row.id];
                const vpnActive = (vpnWorkingCount > 0) ? 'true' : 'false';

                /**
                 * Where database fields: {lastcontact, vpnConnectedAt, vpnLastWorkedAt} are empty,
                 *   API heartbeat keys: {datapost, vpnConnect, vpnContact} will be false.
                 * The subraction below will evaluate to epochSecondsNow.
                 */
                const vpnSecondsSinceContact = epochSecondsNow - vpnContact;

                return {
                    ...row,
                    datapostEpochSeconds: datapost,
                    vpnActive,
                    vpnConnectEpochSeconds: vpnConnect,
                    vpnLastContactEpochSeconds: vpnContact,
                    vpnSecondsSinceContact,
                    alarmCount,
                };
            });
            updatedTableRows[parentName] = updatedInstRowsForEntity;
        }
        return updatedTableRows;
    }

    /**
     * Navigation callback passed down to FlashMessage (errors).
     */
    handleHeartbeatError = () => {
        const { history } = this.props;
        /* Refresh the browser (rather than re-render react which would probably be preverable).
         * If the cause of the error was token expiry, re-loading AuthenticatedRoute will redirect to /login. */
        history.go(0);
    }

    /**
     * Navigation callback passed down to EntityInstallationsRow components.
     * Called on table row click.
     */
    handleInstallationNav = (installationId, datapostEpochSeconds) => {
        const { history } = this.props;
        const flashInfo = (datapostEpochSeconds === false) ? 'Unit is yet to datapost' : '';
        if (flashInfo && this._isMounted) {
            this.setState({ flashInfo });
        } else {
            history.push(`/installation/${installationId}`);
        }
    }

    /**
     * Callback passed down to FlashMessage (info).
     */
    clearDatapostInfoMsg = () => {
        if (this._isMounted) {
            this.setState({ flashInfo: '' });
        }
    }

    render() {
        const { classes, pageTitle, userPrivileges } = this.props;
        const { dashboard } = userPrivileges;
        const {
            loaded,
            tableRows,
            flashError,
            flashInfo,
        } = this.state;

        return (
            <div ref={this.myRef} className={classes.root}>
                <div className={classes.header}>
                    <ForwardReferencedAppHeader
                        ref={this.myRef}
                        titleMsg={pageTitle}
                        userLoggedIn
                        dashName={dashboard}
                    />
                </div>
                <div className={classes.main}>
                    {loaded
                        && (
                            <InstallationsTable
                                tableRowsByParentEntity={tableRows}
                                showInstallation={this.handleInstallationNav}
                            />
                        )}
                    {/* Loading message. */}
                    <FlashMessage
                        isOpenCondition={!loaded}
                        onCloseCallback={null}
                        flashType="loading"
                        messageNode="Loading"
                    />
                    {/* Error messages. */}
                    <FlashMessage
                        isOpenCondition={!!flashError}
                        autoHideAfterMs={120000} // 2 minutes
                        onCloseCallback={this.handleHeartbeatError}
                        flashType="error"
                        messageNode={flashError}
                    />
                    {/* Info messages. */}
                    <FlashMessage
                        isOpenCondition={!!flashInfo}
                        autoHideAfterMs={5000}
                        onCloseCallback={this.clearDatapostInfoMsg}
                        flashType="info"
                        messageNode={flashInfo}
                    />
                </div>
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {string} pageTitle           Browser tab title.
 * @param {string} token               Dashboard API token.
 * @param {Array}  userPrivileges      Parsed token claims.
 * @param {Object} history             react-router-dom history.
 * @param {Array}  apiRoutes           routeKey lookup array.
 * @param {number} pulseFrequencyMs    Table data refresh frequency (ms).
 * @param {Object} classes             JSS classes from withStyles.
 */
Overview.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    userPrivileges: PropTypes.shape({
        dashboard: PropTypes.string.isRequired,
        installations: PropTypes.arrayOf(PropTypes.number).isRequired,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        go: PropTypes.func.isRequired,
    }).isRequired,

    apiRoutes: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.string),
    ),
    pulseFrequencyMs: PropTypes.number,

    classes: PropTypes.shape({
        root: PropTypes.string,
        header: PropTypes.string,
        main: PropTypes.string,
    }).isRequired,
};

/**
 * Default props are resolved by React before PropTypes typechecking.
 */
Overview.defaultProps = {
    /* Keys are members of this.state, Values (routeKeys) in API_ROUTES. */
    apiRoutes: [
        { entities: 'allEntities' },
        { installations: 'visibleInstallations' },
        { pulse: 'installationsPulse' },
    ],
    pulseFrequencyMs: 30000,
};

export default withStyles(styles)(Overview);
