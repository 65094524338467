/**
 * @file      EntityInstallationsRow.js
 *
 * @brief     An Installation table row.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import Colour from 'color';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import Badge from '@material-ui/core/Badge';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import SignalCellularNullIcon from '@material-ui/icons/SignalCellularNull';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import SignalCellularConnectedNoInternet2BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet2Bar';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import WarningIcon from '@material-ui/icons/Warning';

import { LOCALISED_TIME, clamp, dtToHumanReadableDescription } from 'helpers/globalConstants';

import theme from 'global_styles/globalDexMaterialTheme';

const VPN_DEAD_TIME_SECONDS = 600;
const VPN_LIVE_COLOUR = Colour.rgb(theme.palette.vpn.sherbertLive);
const VPN_DEAD_COLOUR = Colour.rgb(theme.palette.vpn.sherbertDead);
const { dateFormat, shortDateFormat, timeFormat } = LOCALISED_TIME;

/**
 * Row formatting. Generator function.
 *
 * @return {Object}  Functions to format table cell data for a particular column.
 */
const fieldFormatting = () => ({
    // name: (value) => value,
    // rctType: (value) => value,
    // entityId: (value) => value.toFixed(4),

    datapostEpochSeconds: (value) => {
        /* value will be Boolean false if installation has never dataposted. */
        let datapostNode = (
            <div>
                <span>No datapost</span>
            </div>
        );
        if (value) {
            const d = new Date(value * 1000);
            datapostNode = (
                <div>
                    <span>{ `${dateFormat.format(d)}` }</span>
                    <span>{ `${shortDateFormat.format(d)}` }</span>
                    <span>{ `@ ${timeFormat.format(d)}` }</span>
                </div>
            );
        }
        return datapostNode;
    },

    alarmCount: (value) => {
        const alarmsAreActive = (value > 0);
        return (alarmsAreActive)
        && (
            <Tooltip
                disableFocusListener
                enterDelay={200}
                placement="bottom"
                title={`Active alarm count: ${value}`}
            >
                <Badge badgeContent={value}>
                    <WarningIcon />
                </Badge>
            </Tooltip>
        );
    },

    vpnSecondsSinceContact: (vpnSecondsSinceContact, vpnConnectEpochSeconds, vpnLastContactEpochSeconds, vpnActive) => {
        const vpnHasNeverWorked = !vpnLastContactEpochSeconds;
        let vpnLastContactIcon;
        let vpnLastContactColour;

        if (vpnConnectEpochSeconds) {
            /* VPN connection in the not-too-distant past. */
            const deadPercentage = clamp(vpnSecondsSinceContact / VPN_DEAD_TIME_SECONDS, 0, 1);
            vpnLastContactColour = VPN_LIVE_COLOUR.mix(VPN_DEAD_COLOUR, deadPercentage).hex();
            vpnLastContactIcon = (vpnActive)
                ? <SignalCellular4BarIcon />
                : <SignalCellularConnectedNoInternet2BarIcon />;
        } else if (vpnHasNeverWorked) {
            /* Never had a VPN connection. */
            vpnLastContactColour = theme.palette.vpn.neverConnected;
            vpnLastContactIcon = <SignalCellularNullIcon />;
        } else {
            /* VPN connection in the dim and distant past. */
            vpnLastContactColour = theme.palette.vpn.goneAway;
            vpnLastContactIcon = <SignalCellularConnectedNoInternet0BarIcon />;
        }

        const vpnContactFormatted = dtToHumanReadableDescription(vpnSecondsSinceContact, vpnLastContactEpochSeconds);

        return (
            <div style={{ backgroundColor: vpnLastContactColour }}>
                <span>{vpnLastContactIcon}</span>
                <span>{vpnContactFormatted}</span>
            </div>
        );
    },
});

/**
 * React functional Component.
 *
 * @param {Object} props    React props.
 */
function EntityInstallationsRow(props) {
    const {
        classes,
        columnHeadings,
        row,
        showInstallation,
    } = props;
    const formats = fieldFormatting();

    /**
     * Navigate view to this Installation or
     *   display error message if installation is yet to datapost.
     *
     * @param {Event}          event                 The click/touch event that triggered us.
     * @param {string}         installationName      Installation name.
     * @param {number|boolean} datapostEpochSeconds  Last datapost time or false.
     */
    const handleClick = (evt, installationId, datapostEpochSeconds) => {
        evt.preventDefault();
        showInstallation(installationId, datapostEpochSeconds);
    };

    /**
     * Hook to manually retrieve previous props or state.
     *
     * @param {Object} value  Prop or state identifier.
     */
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    /* Test animation using VPN contact. */
    // const prevVpnContactEpochSec = usePrevious(row.vpnLastContactEpochSeconds);
    // const vpnNewPulse = prevVpnContactEpochSec && (prevVpnContactEpochSec !== row.vpnLastContactEpochSeconds);

    const prevDatapostEpochSec = usePrevious(row.datapostEpochSeconds);
    const nextDatapostEpochSec = row.datapostEpochSeconds;
    const datapostNewPulse = nextDatapostEpochSec && (nextDatapostEpochSec > prevDatapostEpochSec);

    /**
     * @FIXME MWP 16-Jun 2021: Is this still an issue?
     * If we re-order the parent (sub)table while this CSS animation class is applied to the row,
     *   AND the row lands at the bottom of the table - the animation re-fires.
     * Not a huge problem: datapost every ~20mins, table update every 30secs - classes not applied for long.
     */
    // if (datapostNewPulse) {
    //     // eslint-disable-next-line max-len
    //     console.log(`datapostPulse ${row.id}. Prev: ${prevDatapostEpochSec} Current: ${nextDatapostEpochSec}`);
    // }

    const rowClass = datapostNewPulse ? classes.tableRowPulse : classes.tableRow;

    return (
        <TableRow
            hover
            role="checkbox"
            onClick={(event) => handleClick(event, row.id, row.datapostEpochSeconds)}
            tabIndex={-1}
            className={rowClass}
        >
            {columnHeadings.map((column) => {
                const { id } = column;
                let value = row[id];
                // VPN column has different formatting signature
                let columnIsVpn = false;
                if (id === 'vpnSecondsSinceContact') {
                    columnIsVpn = true;
                    value = formats[id](
                        value,
                        row.vpnConnectEpochSeconds,
                        row.vpnLastContactEpochSeconds,
                        row.vpnActive,
                    );
                }
                return (
                    <TableCell
                        key={id}
                        className={column.cellClassName}
                    >
                        {(formats[id] && !columnIsVpn) ? formats[id](value) : value}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Array}    columnHeadings      Table headings and styles for each column.
 * @param {Object}   row                 Installation table row data formatted for display.
 * @param {Function} showInstallation    Navigation callback for installation view.
 * @param {Object}   classes             material-ui hook to access custom CSS classes.
 */
EntityInstallationsRow.propTypes = {
    columnHeadings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            cellClassName: PropTypes.string.isRequired,
            headerClassName: PropTypes.string.isRequired,
        }),
    ).isRequired,

    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        entityId: PropTypes.number.isRequired,
        rctType: PropTypes.string.isRequired,
        datapostEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnActive: PropTypes.oneOf(['true', 'false']).isRequired,
        vpnConnectEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnLastContactEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnSecondsSinceContact: PropTypes.number.isRequired,
    }).isRequired,

    showInstallation: PropTypes.func.isRequired,

    classes: PropTypes.shape({
        tableRow: PropTypes.string,
        tableRowPulse: PropTypes.string,
    }).isRequired,
};

export default EntityInstallationsRow;
