/**
 * @file      PasswordForm.js
 *
 * @brief     Login password form.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';

import { sleep } from 'helpers/globalConstants';

import styles from './styles/LoginFormStyles';

/**
 * React class-based component.
 */
class PasswordForm extends Component {
    constructor() {
        super();
        this.state = {
            formValue: '',
            errorMsg: '',
            showPassword: false,
        };
        this.textInputRef = React.createRef();

        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTabInput = this.handleTabInput.bind(this);
        this.submitIsDisabled = this.submitIsDisabled.bind(this);
    }

    /**
     * Lifecycle methods.
     */
    componentDidMount = () => {
        /* If we are initial step. */
        const { index } = this.props;
        if (index === 0) {
            sleep(200).then(() => {
                this.textInputRef.current.focus();
            });
        }
    }

    componentDidUpdate = (prevProps) => {
        /* Active state has changed. Wait for any CSS transitions to finish, then focus text input. */
        const { isActive } = this.props;
        if (isActive && !prevProps.isActive) {
            sleep(450).then(() => {
                // console.log("focus password form");
                this.textInputRef.current.focus();
            });
        }
    }

    handleClickShowPassword = () => {
        const { showPassword } = this.state;
        const showPasswordState = !showPassword;
        this.setState((st) => (
            { ...st, showPassword: showPasswordState }
        ));
    };

    handleMouseDownPassword = (evt) => {
        evt.preventDefault();
    };

    handleChange = (evt) => {
        const newFormValue = evt.target.value;
        const emptyErrorMsg = '';

        this.setState({
            [evt.target.name]: newFormValue,
            errorMsg: emptyErrorMsg,
        });
    }

    handleSubmit = (evt) => {
        evt.preventDefault();
        this.textInputRef.current.blur(); // remove focus from text field

        const { errorMsg } = this.state;
        const { index, handleSubmit } = this.props;

        const valueIsValid = !errorMsg.length;

        if (valueIsValid) {
            const data = { ...this.state, index };
            handleSubmit(data);
        }
    }

    handleTabInput = (evt) => {
        /* Prevent focus change on TAB. keyCode is deprecated but TAB is 9 everywhere. */
        if (evt.keyCode === 9) {
            evt.preventDefault();
        }
    }

    submitIsDisabled = () => {
        const { formValue, errorMsg } = this.state;
        return (errorMsg.length || !formValue.length);
    }

    render() {
        const {
            classes,
            description,
            placeholderValue,
            isFirstStep,
            isLastStepToComplete,
            handleBack,
        } = this.props;
        const { formValue, errorMsg, showPassword } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    <div className={classes.titleLogo} />
                    <Typography htmlFor="password-form-input" variant="h5" className={classes.titleText}>
                        {description}
                    </Typography>
                </div>

                <form onSubmit={this.handleSubmit} className={classes.form} autoComplete="off" spellCheck="false">
                    <TextField
                        id="password-form-input"
                        name="formValue"
                        value={formValue}
                        aria-describedby={description}
                        placeholder={placeholderValue}
                        fullWidth
                        label={errorMsg}
                        error={!!(errorMsg.length)}
                        size="medium"
                        variant="filled"
                        onChange={this.handleChange}
                        onKeyDown={this.handleTabInput}
                        inputRef={this.textInputRef}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>),
                        }}
                    />
                </form>

                <div className={classes.buttonHolder}>
                    {(!isFirstStep) && (
                        <Button
                            onClick={handleBack}
                            className={classes.button}
                            size="large"
                            variant="contained"
                        >
                            Back
                        </Button>

                    )}
                    <Button
                        disabled={this.submitIsDisabled()}
                        onClick={this.handleSubmit}
                        className={classes.button}
                        size="large"
                        variant="contained"
                    >
                        {isLastStepToComplete ? 'Login' : 'Next'}
                    </Button>
                </div>
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {number}   index                    Form index in array of steps required to complete login.
 * @param {string}   description              Login step end-user description.
 * @param {string}   placeholderValue         Form placeholder text.
 * @param {boolean}  isActive                 This login step is currently active.
 * @param {boolean}  isFirstStep              This login step is the first step.
 * @param {boolean}  isLastStepToComplete     All other login step have been completed.
 * @param {Function} handleSubmit             Submit form value to parent and increment login step.
 * @param {Function} handleBack               Navigate to previous login step.
 * @param {Object}   classes                  JSS classes from withStyles.
 */
PasswordForm.propTypes = {
    index: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    placeholderValue: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isFirstStep: PropTypes.bool.isRequired,
    isLastStepToComplete: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,

    classes: PropTypes.shape({
        root: PropTypes.string,
        title: PropTypes.string,
        titleLogo: PropTypes.string,
        titleText: PropTypes.string,
        form: PropTypes.string,
        buttonHolder: PropTypes.string,
        button: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(PasswordForm);
