/**
 * @file      InstallationGrid.js
 *
 * @brief     Installation view top level container.
 *            Renders three rows of information:
 *              StatusGridRow, HistoricAlarmsGridRow, ChartGridRow
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ChartGridRow from './ChartGridRow';
import DXHeroBanner from './DXHeroBanner';
import HistoricAlarmGridRow from './HistoricAlarmGridRow';
import StatusGridRow from './StatusGridRow';

import styles from './styles/InstallationGridStyles';

const useStyles = makeStyles(styles);

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function InstallationGrid(props) {
    const {
        name,
        model,
        alarms,
        historicAlarms,
        parameters,
        vpn,
        fetchParamData,
        updateFlashMsg,
        mimicAvailable,
        toggleMimicOpen,
        handleEventlogNav,
        parameterLogs,
    } = props;
    const classes = useStyles();

    return (
        <div className={classes.gridRoot}>
            <DXHeroBanner
                name={name}
                model={model}
            />
            <div className={classes.gridContent}>
                <StatusGridRow
                    title="Status"
                    model={model}
                    alarms={alarms}
                    parameters={parameters}
                    vpn={vpn}
                    mimicAvailable={mimicAvailable}
                    toggleMimicOpen={toggleMimicOpen}
                    handleEventlogNav={handleEventlogNav}
                    showHideTimeMs={500}
                />
                <HistoricAlarmGridRow
                    title="Historic Alarms"
                    showHideTimeMs={500}
                    historicAlarms={historicAlarms}
                    parameters={parameters}
                />
                <ChartGridRow
                    title="Charts"
                    parameters={parameters}
                    historicAlarms={historicAlarms}
                    fetchParamData={fetchParamData}
                    updateFlashMsg={updateFlashMsg}
                    showHideTimeMs={750}
                    parameterLogs={parameterLogs}
                />
            </div>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}      name               Installation name.
 * @param {string}      model              RTU model.
 * @param {Array}       alarms             Active alarm list.
 * @param {Array}       historicAlarms     Historic alarms list.
 * @param {Object}      parameters         Parameter id-name associative array.
 * @param {Object}      vpn                VPN pulse info.
 * @param {Function}    fetchParamData     Request parameter data to plot callback.
 * @param {Function}    updateFlashMsg     Flash message callback.
 * @param {Boolean}     mimicAvailable     Mimic URI exists.
 * @param {Function}    toggleMimicOpen    Close mimic button callback.
 * @param {Function}    handleEventlogNav  Navigate to eventlogs for installation.
 * @param {Array}       parameterLogs      Any requested datapoint values.
 */
InstallationGrid.propTypes = {
    name: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,

    alarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,
    historicAlarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,

    parameters: PropTypes.instanceOf(Object).isRequired,

    vpn: PropTypes.instanceOf(Object).isRequired,

    fetchParamData: PropTypes.func.isRequired,
    updateFlashMsg: PropTypes.func.isRequired,
    mimicAvailable: PropTypes.bool.isRequired,
    toggleMimicOpen: PropTypes.func.isRequired,
    handleEventlogNav: PropTypes.func.isRequired,

    parameterLogs: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,
};

export default InstallationGrid;
