/**
 * @file      StatusGridRow.js
 *
 * @brief     Row container for Installation status content.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

import StatusContent from './StatusContent';

import styles from './styles/RowStyles';

const useStyles = makeStyles(styles);

/**
 * Return a React functional component.
 *
 * @param {Object} props    React props.
 */
function StatusGridRow(props) {
    const {
        title,
        model,
        showHideTimeMs,
        alarms,
        parameters,
        vpn,
        mimicAvailable,
        toggleMimicOpen,
        handleEventlogNav,
    } = props;
    const classes = useStyles();

    const [rowVisible, setRowVisible] = useState(true);
    const toggleRowVisible = () => setRowVisible(!rowVisible);

    return (
        <div className={classes.root}>
            <div className={classes.rowHeader}>
                <Typography
                    variant="button"
                    align="left"
                    display="inline"
                    className={classes.rowHeaderText}
                >
                    {title}
                </Typography>
                <Button
                    variant="text"
                    size="large"
                    className={classes.rowShowHideButton}
                    onClick={toggleRowVisible}
                    endIcon={(rowVisible) ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                >
                    {(rowVisible) ? 'Hide' : 'Show'}
                </Button>
            </div>
            <Collapse
                in={rowVisible}
                timeout={showHideTimeMs}
                collapsedHeight="0px"
                className={classes.content}
            >
                <StatusContent
                    model={model}
                    alarms={alarms}
                    parameters={parameters}
                    vpn={vpn}
                    mimicAvailable={mimicAvailable}
                    toggleMimicOpen={toggleMimicOpen}
                    handleEventlogNav={handleEventlogNav}
                />
            </Collapse>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}    title              Top banner display title.
 * @param {string}    model              RTU model.
 * @param {Array}     alarms             Active alarm list.
 * @param {Object}    parameters         Parameter id-name associative array.
 * @param {Object}    vpn                VPN pulse object.
 * @param {Boolean}   mimicAvailable     Mimic URI exists.
 * @param {Function}  toggleMimicOpen    Close mimic button callback.
 * @param {Function}  handleEventlogNav  Navigate to eventlogs for installation.
 * @param {number}    showHideTimeMs     Collapse animation time (ms).
 */
StatusGridRow.propTypes = {
    title: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,

    alarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,

    parameters: PropTypes.instanceOf(Object).isRequired,

    vpn: PropTypes.shape({
        datapostEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnActive: PropTypes.oneOf(['true', 'false']).isRequired,
        vpnConnectEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnLastContactEpochSeconds: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number,
        ]).isRequired,
        vpnSecondsSinceContact: PropTypes.number.isRequired,
    }).isRequired,

    mimicAvailable: PropTypes.bool.isRequired,
    toggleMimicOpen: PropTypes.func.isRequired,
    handleEventlogNav: PropTypes.func.isRequired,
    showHideTimeMs: PropTypes.number.isRequired,
};

export default StatusGridRow;
