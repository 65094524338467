/**
 * @file      NotFoundCardStyles.js
 *
 * @brief     404 Not Found component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2023-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        cardHolder: {
            width: '100%',
            height: 340,
            /* Align Card. */
            display: 'grid',
            placeItems: 'center',

            [sizes.widthMax('xs')]: {
                backgroundColor: theme.palette.background.paper,
                placeItems: 'start center',
            },
        },
        notFoundCard: {
            /* Media query-based width. */
            width: 650,
            minWidth: 320,
            /* Large devices (>=1200px) is the default. Order is important. */
            [sizes.widthMax('lg')]: {
                width: 600,
            },
            [sizes.widthMax('md')]: {
                width: 500,
            },
            [sizes.widthMax('xs')]: {
                width: '100%',
                borderRadius: 0,
                boxShadow: 'none',
            },
        },
        avatarSquare: {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.dark,
        },
        backButton: {
            color: theme.palette.primary.main,
        },
    };
};

export default styles;
