/**
 * @file      ParamChart.js
 *
 * @brief     Parameter graph Component.
 *            Uses a React wrapper https://www.npmjs.com/package/echarts-for-react
 *            for ECharts https://github.com/apache/echarts
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

/**
 * Import ECharts.
 * @FIXME MWP 20-Jun-2021
 * Try updating ECharts library to version 5.
 * More options for formatting. Possible SVG renderer fixes.
 *
 * Import core library, then required modules only.
 *   import ReactEchartsCore from 'echarts-for-react/lib/core';
 */
import echarts from 'echarts/lib/echarts';

import Fade from '@material-ui/core/Fade';

import { makeStyles } from '@material-ui/core/styles';

import { LOCALISED_TIME, dateFromUtcString } from 'helpers/globalConstants';
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';

import styles from './styles/ParamChartStyles';

const useStyles = makeStyles(styles);

/**
 * ECharts options object for a line graph.
 * See: https://echarts.apache.org/v4/en/option.html
 *
 * @param {Object}  parameters   Parameter id name lookup.
 * @param {Array}   data         Data values for parameters.
 *                               [{
 *                                   paramId: 12,
 *                                   logs:    [{time: "2021-02-11 20:44:56", avg: "26.699584960938"}, ...],
 *                               }]
 * @param {Array}   alarms       Historic alarms array. Possibly empty.
 * @param {boolean} yAxisOrigin  Display y-axis origin.
 *
 * @return {Object}  EChart options object.
 */
const getOption = (parameters, data, alarms = [], yAxisOrigin = true) => {
    /**
     * Skip rendering the chart completely if data arrays are empty.
     * Testing only for data.length we could render an empty chart instead.
     */
    const logLengths = data.map((gd) => gd.logs.length);
    const allDataArraysAreEmpty = (data.length && logLengths.every((len) => len === 0));
    if (!data.length || allDataArraysAreEmpty) {
        return {};
    }

    const theme = globalDexMaterialTheme;
    const {
        shortTzName,
        dateFormat,
        timeFormat,
        graphFormat,
    } = LOCALISED_TIME;

    // let graphStart = new Date();
    // let graphEnd = new Date();
    // if (logs.length) {
    //     const firstLogDateForParam = dateList[0];
    //     const lastLogDateForParam = dateList[dateList.length - 1];

    //     if (firstLogDateForParam < graphStart) {
    //         graphStart = firstLogDateForParam;
    //     }
    //     if (lastLogDateForParam > graphEnd) {
    //         graphEnd = lastLogDateForParam;
    //     }
    // }

    const numParams = data.length;
    /* Create parallel arrays. */
    const paramValues = [];
    const paramNames = [];
    const alarmsGroupedByParam = [];

    data.forEach((paramToPlot) => {
        const { paramId, logs } = paramToPlot;
        const paramName = parameters[paramId];

        paramValues.push(logs.map((log) => [
            dateFromUtcString(log.time), // toISOString() toUTCString()
            Number.parseFloat(log.avg).toFixed(2),
        ]));
        paramNames.push(paramName);

        /**
         * We could also filter by date inside graph min-max.
         * eCharts ignores any regions outside graphed range.
         */
        alarmsGroupedByParam.push(alarms
            .filter((alarm) => alarm.param === paramId)
            .map((filteredAlrm) => ({
                type: filteredAlrm.type.charAt(0).toUpperCase() + filteredAlrm.type.slice(1),
                start: dateFromUtcString(filteredAlrm.start),
                end: filteredAlrm.end ? dateFromUtcString(filteredAlrm.end) : new Date(), // handle active alarms
            })));
    });

    return {
        title: {
            top: 6,
            left: 24,
            text: 'Parameter value over time',
            fontFamily: theme.typography.fontFamily,
        },

        toolbox: {
            /* Canvas renderer required to fix hover emphasis. */
            show: true,
            showTitle: true,
            itemSize: 22,
            itemGap: 22,
            right: 16,
            top: 6,
            iconStyle: {
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
                shadowColor: theme.palette.background.transparent,
                shadowOffsetX: 2,
                shadowOffsetY: 2,
                shadowBlur: 4,
            },
            emphasis: {
                iconStyle: {
                    borderColor: theme.palette.primary.light,
                },
            },
            feature: {
                restore: { title: 'Reset Zoom' },
                saveAsImage: {
                    title: 'Save As Image',
                    name: 'dexdyne-dash-chart',
                    pixelRatio: 2,
                    backgroundColor: '#fff',
                    excludeComponents: ['title', 'toolbox', 'dataZoom'],
                },
            },
        },

        backgroundColor: new echarts.graphic.RadialGradient(0.3, 0.3, 0.8, [{
            offset: 0,
            color: '#f7f8fa',
        }, {
            offset: 1,
            color: '#cdd0d5',
        }]),

        xAxis: {
            type: 'time',
            // data: dateList,
            min: 'dataMin',
            max: 'dataMax',
            splitNumber: 4,
            axisTick: {
                inside: true,
                length: 5,
            },
            axisLabel: {
                formatter: ((value) => graphFormat.format(value)),
                rotate: 12,
                // width: 50,
                // overflow: 'breakAll',
            },
            boundaryGap: ['20%', '20%'],
            textStyle: {
                fontFamily: theme.typography.fontFamily,
            },
            name: `Time (${shortTzName})`,
            nameLocation: 'center',
            nameGap: 32,
            nameTextStyle: {
                fontSize: theme.typography.sizes.calendar.chartAxisLabel,
                fontFamily: theme.typography.fontFamily,
            },
        },

        yAxis: {
            scale: true,
            min: (yAxisOrigin) ? 0 : 'dataMin',
            splitLine: {
                show: true,
                lineStyle: { type: 'dashed' },
            },
            boundaryGap: ['5%', '5%'],
            textStyle: {
                fontFamily: theme.typography.fontFamily,
            },
        },

        grid: {
            top: 70,
            bottom: 95,
            left: 70,
            right: 50,
        },

        legend: {
            show: true,
            itemSize: 24,
            itemGap: 24,
            left: 24,
            top: 32,
            data: paramNames,
        },

        tooltip: {
            trigger: 'axis',
            padding: 8,
            showDelay: 50,
            hideDelay: 50,
            backgroundColor: theme.palette.tooltip.background,
            color: theme.palette.tooltip.text,
            textStyle: {
                fontFamily: theme.typography.fontFamily,
            },
            formatter: ((params) => {
                let output = '<div>';
                const seriesNameSpanStyles = 'style="width:170px; float:left;"';
                // eslint-disable-next-line no-restricted-syntax
                params.forEach((param) => {
                    const { seriesName, marker } = param;
                    // eslint-disable-next-line max-len
                    output += `<span ${seriesNameSpanStyles}>${marker} ${seriesName}</span> <span style="float:right;">${param.data[1]}</span><br>`;
                });

                const d = params[0].data[0];
                // eslint-disable-next-line max-len
                const hr = `<hr style="border:0; height:1px; background-image:linear-gradient(to right, ${theme.palette.background.transparent}, #fff, ${theme.palette.background.transparent});">`;
                return `${dateFormat.format(d)} ${timeFormat.format(d)} ${hr} ${output}</div>`;
            }),
        },

        dataZoom: [{
            type: 'inside',
            // type: 'slider',
            filterMode: 'filter', // 'none' prevents autoscale
            xAxisIndex: 0, // all x-axes should be the same
            start: 0, // graphStart also works
            end: 100, // graphEnd
            /* Mousewheel+modifier zoom configs prevent page scrolling.
             * See: https://github.com/apache/echarts/issues/10079
             */
            zoomOnMouseWheel: 'false',
            zoomLock: 'true',

            throttle: 100, // vary this with number of points
            // realtime: false,

            textStyle: {
                fontFamily: theme.typography.fontFamily,
                overflow: 'truncate',
            },
            /* labelFormatter only works with type 'slider' which doesn't support custom icons in v4. */
            // labelFormatter: (value, valueStr) => (value && graphFormat.format(value)),
        },
        {
            handleIcon: theme.path.graphPanHandle,
            handleSize: '90%',
            handleStyle: {
                color: theme.palette.primary.main,
                shadowBlur: 1,
                shadowColor: theme.palette.background.paper,
                shadowOffsetX: 1,
                shadowOffsetY: 1,
            },
        },
        ],

        series: paramValues.map((values, idx) => {
            const plotLine = {
                data: values,
                name: `${paramNames[idx]}`,
                type: 'line',
                symbol: 'circle',
                symbolSize: 3,
                itemStyle: {
                    color: theme.palette.graph[idx % 4], // we expect a max of 4 data series
                },
                /* Historic alarm regions. */
                markArea: {
                    data: alarmsGroupedByParam[idx].map((alarm) => ([
                        {
                            name: alarm.type,
                            xAxis: alarm.start,
                        },
                        {
                            xAxis: alarm.end,
                        },
                    ])),
                },
            };
            if (numParams === 1) {
                plotLine.areaStyle = {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: theme.palette.graph[idx % 4],
                    }, {
                        offset: 1,
                        color: theme.palette.background.dexdyneGrey,
                    }]),
                };
            }
            return plotLine;
        }),
    };
};

/**
 * (Re)rendering EChart component is relatively expensive. Debounce resize handling.
 * https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
 *
 * @param {Function} func    Function.
 * @param {Number}   ms      Timeout ms.
 * @param {...any}   rest    Rest parameter for variadic funcion.
 */
function debounceFunction(func, ms, ...rest) {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(this, rest);
        }, ms);
    };
}

/**
 * Debug. Trace updates to changed props and log to console.
 * Call from render function.
 * https://stackoverflow.com/a/51082563
 *
 * @param {Object} props    React props.
 */
// eslint-disable-next-line no-unused-vars
function useTraceUpdate(props) {
    return true;
    // const prev = useRef(props);
    // useEffect(() => {
    //     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
    //         if (prev.current[k] !== v) {
    //             // eslint-disable-next-line no-param-reassign
    //             ps[k] = [prev.current[k], v];
    //         }
    //         return ps;
    //     }, {});
    //     if (Object.keys(changedProps).length > 0) {
    //         console.log('Changed props:', changedProps);
    //     }
    //     prev.current = props;
    // });
}

/**
 * Return a (memoized) React functional component.
 *
 * @param {Object} props    React props.
 */
const ParamChart = memo((props) => {
    const {
        parameters,
        historicAlarms,
        graphData,
        showAlarmRegions,
        showYAxisOrigin,
    } = props;
    const classes = useStyles();

    // useTraceUpdate(props);

    /* Reserve state for DOM ref object. ReactEcharts doesn't seem to play well with useRef(). */
    const [focusChartContainer] = useState(React.createRef());
    const [echartRef, setEchartRef] = useState(null);
    const [chartOpts, setChartOpts] = useState({});

    /**
     * Generate updated chart options object.
     * This sets state from props, which is poor form.
     * But it avoids repeated calls to getOption from ReactEcharts.
     */
    useEffect(() => {
        const alarms = showAlarmRegions ? historicAlarms : [];
        setChartOpts(getOption(parameters, graphData, alarms, showYAxisOrigin));
    }, [parameters, graphData, historicAlarms, showAlarmRegions, showYAxisOrigin]);

    /* Debounce chart resizes. */
    useEffect(() => {
        let chartInstance = null;
        let debouncedChartResize = null;
        if (echartRef) {
            chartInstance = echartRef.getEchartsInstance();
            debouncedChartResize = debounceFunction(chartInstance.resize, 200);
        }

        const handleResize = () => {
            if (chartInstance) {
                debouncedChartResize();
            }
        };

        /* Listen for window resize events. */
        window.addEventListener('resize', handleResize);
        /* Cleanup listener from previous calls on unmount. */
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [echartRef]);

    /* Scroll to chart */
    useEffect(() => {
        if (graphData.length) {
            if (focusChartContainer && focusChartContainer.current) {
                focusChartContainer.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [graphData, focusChartContainer]);

    return (
        <Fade in={!!graphData.length} timeout={500}>
            <div className={classes.container} ref={focusChartContainer}>
                <ReactEcharts
                    ref={(e) => setEchartRef(e)} // Intial triple-render
                    option={chartOpts}
                    notMerge
                    style={{ height: '100%', width: '100%' }}
                    lazyUpdate
                    opts={{ renderer: 'canvas' }} // svg is more performant but causes gfx issues.
                />
            </div>
        </Fade>
    );
});

/**
 * Typecheck props in development mode.
 *
 * @param {Object}      parameters        Parameter id-name associative array.
 * @param {Array}       historicAlarms    Historic alarms list.
 * @param {Array}       graphData         Datapoint values for graphing.
 * @param {boolean}     showAlarmRegions  Visibility of historic alarms on graph.
 * @param {boolean}     showYAxisOrigin   Visibility of y-axis origin on graph.
 */
ParamChart.propTypes = {
    parameters: PropTypes.instanceOf(Object).isRequired,
    historicAlarms: PropTypes.arrayOf(
        PropTypes.instanceOf(Object),
    ).isRequired,

    graphData: PropTypes.arrayOf(
        PropTypes.shape({
            paramId: PropTypes.number.isRequired,
            logs: PropTypes.arrayOf(PropTypes.shape({
                time: PropTypes.string.isRequired,
                avg: PropTypes.string.isRequired,
            })),
        }),
    ).isRequired,

    showAlarmRegions: PropTypes.bool.isRequired,
    showYAxisOrigin: PropTypes.bool.isRequired,
};

export default ParamChart;
