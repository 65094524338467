/**
 * @file      Login.js
 *
 * @brief     Login route controller.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';

import {
    PAGE_TITLE_PREFIX,
    API_ROUTES,
    fetchResource,
    parseResponseErrorsToString,
} from 'helpers/globalConstants';

import AppHeader from 'global_components/AppHeader';
import styles from 'global_styles/PageRootStyles';

import VerticalStepLogin from './VerticalStepLogin';

/**
 * React class-based component.
 */
class Login extends Component {
    constructor() {
        super();
        this.handleApiTokenRoute = this.handleApiTokenRoute.bind(this);
    }

    /**
     * Lifecycle methods.
     */
    componentDidMount() {
        const { pageTitle } = this.props;
        document.title = `${pageTitle} | ${PAGE_TITLE_PREFIX}`;
    }

    /**
     * Make a post request for an API token.
     * On success, automatically redirect to Overview.
     *
     * @param {string} username
     * @param {string} password
     *
     * @returns {string}    POST error message. Empty string indicates success.
     */
    async handleApiTokenRoute(username, password) {
        const route = API_ROUTES.tokenGeneration;
        const postData = {
            lifetimeHours: 2,
            username,
            password,
        };

        return fetchResource(route, postData)
            .then((response) => {
                const { setApiToken, history } = this.props;
                const { success, data } = response;

                /* Flash error message string. */
                let errorMsg = '';

                if (success) {
                    /* Set App token state and redirect to dashboard. */
                    setApiToken(data.accessToken);
                    /* A non-declarative way to force redirect. */
                    history.push('/overview');
                } else if (data?.status === 'Unauthorized') {
                    errorMsg = 'Invalid username or password';
                } else {
                    errorMsg = parseResponseErrorsToString(data, true);
                }
                return errorMsg;
            });
    }

    render() {
        const { classes, pageTitle } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <AppHeader titleMsg={pageTitle} userLoggedIn={false} />
                </div>
                <div className={classes.main}>
                    <VerticalStepLogin
                        loginSuccess={this.handleApiTokenRoute}
                    />
                </div>
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}   pageTitle      Browser tab title.
 * @param {Function} setApiToken    Dashboard API token state setter hook.
 * @param {Object}   history        react-router-dom history.
 * @param {Object}   classes        JSS classes from withStyles.
 */
Login.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    setApiToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,

    classes: PropTypes.shape({
        root: PropTypes.string,
        header: PropTypes.string,
        main: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(Login);
