/**
 * @file      DateRangeSelectorStyles.js
 *
 * @brief     EventLog DateRangeSelector component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
/* eslint-disable max-len */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        root: {
            // relative to EventLogGrid.flexContainer
            flexGrow: 2,
            margin: 16,
            minWidth: 540,
            maxWidth: 600,

            /**
             * Media query-based width.
             * Extra Large devices (>=1600px) is the default. Order is important.
             */
            [sizes.widthMax('lg')]: {
                /* Overflow below 1200px. */
                width: 600,
                marginRight: 32, // scroll bar
            },
            [sizes.widthMax('sm')]: {
                width: 'calc(100% - 32px)',
                minWidth: 360,
            },
            [sizes.widthMax('xs')]: {
                flexGrow: 0,
                width: 328,
                minWidth: 328,
                marginRight: 0,
                /* Limit number of buttons on portrait phone. */
                '& .MuiListItemSecondaryAction-root': {
                    right: 4,
                    '& button:nth-of-type(n+4)': {
                        display: 'none',
                    },
                },
            },
        },

        titleText: {
            textTransform: 'uppercase',
            paddingBottom: 8,
        },

        plotButton: {
            width: 120,
            color: theme.palette.primary.contrastText,
            boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,

            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:disabled': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
                boxShadow: `0 3px 6px 0 ${theme.palette.text.disabled}`,
            },
        },

        /* Date picking. */
        datePickerContainer: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },

        /* Relative. */
        rangeTypeTab: {
            width: '100%',
            backgroundColor: theme.palette.table.headRow,

            '& .MuiButtonBase-root': {
                color: theme.palette.primary.main,
                textTransform: 'uppercase',
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.sizes.button,
                fontWeight: 400,
                textAlign: 'center',
            },

            '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
                height: '0.2em',
            },

            '& .MuiTouchRipple-root': {
                color: theme.palette.primary.transparent,
            },
        },

        intervalRow: {
            height: 80,
            [sizes.widthMax('xs')]: {
                /* Reduce padding on portrait phone. */
                paddingLeft: 4,
                paddingRight: 4, // matches MuiListItemSecondaryAction-root
            },
        },
        intervalDesc: {
            color: theme.palette.text.primary,

            '& .MuiTypography-root': {
                fontSize: theme.typography.sizes.para,
            },
            /* Hide descriptions on portrait phone. */
            '& p': {
                [sizes.widthMax('xs')]: {
                    display: 'none',
                },
            },
        },
        listDivider: {
            //
        },
    };
};

export default styles;
