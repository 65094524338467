/**
 * @file      index.js
 *
 * @brief     Mobile dashboard entry point.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from 'App';
import * as serviceWorker from './serviceWorker';

const develEnvironment = (process.env.REACT_APP_ENV === 'development');

if (develEnvironment) {
    const asciLogo = `

Powered by                                                                          
________  _______      ___    ___ ________      ___    ___ ________   _______       
|\\   ___ \\|\\  ___ \\    |\\  \\  /  /|\\   ___ \\    |\\  \\  /  /|\\   ___  \\|\\  ___ \\     
\\ \\  \\_|\\ \\ \\   __/|   \\ \\  \\/  / | \\  \\_|\\ \\   \\ \\  \\/  / | \\  \\\\ \\  \\ \\   __/|    
 \\ \\  \\ \\\\ \\ \\  \\_|/__  \\ \\    / / \\ \\  \\ \\\\ \\   \\ \\    / / \\ \\  \\\\ \\  \\ \\  \\_|/__  
  \\ \\  \\_\\\\ \\ \\  \\_|\\ \\  /     \\/   \\ \\  \\_\\\\ \\   \\/  /  /   \\ \\  \\\\ \\  \\ \\  \\_|\\ \\ 
   \\ \\_______\\ \\_______\\/  /\\   \\    \\ \\_______\\__/  / /      \\ \\__\\\\ \\__\\ \\_______\\
    \\|_______|\\|_______/__/ /\\ __\\    \\|_______|\\___/ /        \\|__| \\|__|\\|_______|
                       |__|/ \\|__|             \\|___|/                              
`;
    console.log(`%c${asciLogo}`, 'background:aliceblue; color:dodgerblue;');

    ReactDOM.render(
        /**
         * @NOTE Devel steps. Enable StrictMode for extra debugging.
         */
        <React.StrictMode>
            <App />
        </React.StrictMode>,

        document.getElementById('root'),
    );
} else {
    ReactDOM.render(
        <App />,

        document.getElementById('root'),
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
