/**
 * @file      AppHeader.js
 *
 * @brief     AppBar and Drawer Components.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @NOTE      See VerticalStepLogin.js for module import ordering.
 *
 * @author    Malcolm Padley
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ViewListIcon from '@material-ui/icons/ViewList';

import { makeStyles } from '@material-ui/core/styles';

import { RMS_ROOT_URL } from 'helpers/globalConstants';

import styles from './styles/AppHeaderStyles';

const useStyles = makeStyles(styles);

/**
 * React functional Component.
 *
 * @param {Object} props    React props.
 */
function AppHeader(props) {
    const classes = useStyles();
    const {
        titleMsg,
        userLoggedIn,
        dashName,
        scrollRef,
    } = props;

    const [drawOpenState, setDrawOpenState] = useState(false);

    const toggleDrawerOpen = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawOpenState(!drawOpenState);
    };

    /**
     * AppBar scrolling opacity.
     */
    const defaultAppBarClassName = 'appBar';
    const [navBarStyleName, setNavBarStyleName] = useState(defaultAppBarClassName);

    useEffect(() => {
        let timer;

        const handleScroll = () => {
            clearTimeout(timer);

            const transparentScrollThresholdPixels = 100;
            const debounceIntervalMs = 100;

            const scrollPixels = scrollRef.current.scrollTop;

            let cssClassName = defaultAppBarClassName;

            if (scrollPixels > transparentScrollThresholdPixels) {
                cssClassName = 'appBarTransparent';
            }

            timer = setTimeout(() => {
                timer = null;
                // console.log(`Apply class '${cssClassName}' to navbar`);
                setNavBarStyleName(cssClassName);
            }, debounceIntervalMs);
        };

        if (scrollRef) {
            scrollRef.current.addEventListener('scroll', handleScroll);
        }
        /* Not necessary to cleanup event listener on unmount. */
        // return () => {
        //     scrollRef.current.removeEventListener('scroll', handleScroll);
        // };
    }, [scrollRef]);

    return (
        <div className={classes.root}>
            <AppBar style={{ position: 'fixed' }} className={classes[navBarStyleName]}>
                <Toolbar>
                    {/* Menu Drawer only shown if logged-in. */}
                    {userLoggedIn
                    && (
                        <IconButton
                            className={classes.menuButton}
                            aria-label="menu"
                            edge="start"
                            color="inherit"
                            onClick={toggleDrawerOpen()}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <div className={classes.flexContainer}>
                        <div className={classes.dexdyneText} />
                        <div className={classes.locationText}>
                            <Typography variant="h5">
                                {titleMsg}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            {userLoggedIn
            && (
                <Drawer
                    anchor="left"
                    open={drawOpenState}
                    onClose={toggleDrawerOpen()}
                >
                    <div className={classes.drawerContent}>
                        <div className={classes.drawerTitle}>
                            <div className={classes.logoHolder}>
                                <div className={classes.drawerDexdyneLogo} />
                                <div className={clsx([classes.dexdyneText, classes.drawerDexdyneText])} />
                            </div>
                            <div className={classes.drawerClose}>
                                <IconButton onClick={toggleDrawerOpen()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={classes.listHolder}>
                            <Divider />
                            <List>
                                <ListItem
                                    button
                                    key="overview"
                                    component={NavLink}
                                    to="/overview"
                                    className={classes.link}
                                    activeClassName={classes.linkActive}
                                >
                                    <ListItemIcon>
                                        <ViewListIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Overview" />
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem
                                    button
                                    key="desktop"
                                    component="a"
                                    href={`${RMS_ROOT_URL}/${dashName}`}
                                    className={classes.link}
                                >
                                    <ListItemIcon>
                                        <DesktopWindowsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Desktop Site" />
                                </ListItem>
                                <ListItem
                                    button
                                    key="support"
                                    component="a"
                                    href="https://support.dexdyne.com/open.php"
                                    className={classes.link}
                                >
                                    <ListItemIcon>
                                        <LiveHelpIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Support" />
                                </ListItem>
                                <ListItem
                                    button
                                    key="api"
                                    component="a"
                                    href={`${RMS_ROOT_URL}/api/v1/doc`}
                                    className={classes.link}
                                >
                                    <ListItemIcon>
                                        <DescriptionIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="API Documentation" />
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem
                                    button
                                    key="logout"
                                    component={NavLink}
                                    to="/logout"
                                    className={classes.link}
                                >
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItem>
                            </List>
                        </div>
                        <div className={classes.drawerFooter}>
                            <p>Powered by Dexdyne ©2023</p>
                        </div>
                    </div>
                </Drawer>
            )}
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}  titleMsg      Header title to display.
 * @param {boolean} userLoggedIn  Display content for logged-in user.
 * @param {String}  dashName      Dashboard (database) name.
 * @param {Object}  scrollRef     Parent Component ref. Possibly null for no scroll.
 */
AppHeader.propTypes = {
    titleMsg: PropTypes.string.isRequired,
    userLoggedIn: PropTypes.bool.isRequired,
    dashName: PropTypes.string,
    scrollRef: PropTypes.instanceOf(Object),
};

AppHeader.defaultProps = {
    dashName: 'default', // redirect to default (large) dashboard
    scrollRef: null,
};

export default AppHeader;
