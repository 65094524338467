/**
 * @file      RelDateRangeSelectStyles.js
 *
 * @brief     RelDateRangeSelect component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
/* eslint-disable max-len */
import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    const theme = globalDexMaterialTheme;
    return {
        intervalRow: {
            height: 80,
            [sizes.widthMax('xs')]: {
                /* Reduce padding on portrait phone. */
                paddingLeft: 4,
                paddingRight: 4, // matches MuiListItemSecondaryAction-root
            },
        },
        intervalDesc: {
            color: theme.palette.text.primary,

            '& .MuiTypography-root': {
                fontSize: theme.typography.sizes.para,
            },
            /* Hide descriptions on portrait phone. */
            '& p': {
                [sizes.widthMax('xs')]: {
                    display: 'none',
                },
            },
        },
        intervalButton: {
            marginLeft: 8,
            fontSize: theme.typography.sizes.button,
            fontWeight: 400,
            textAlign: 'center',
        },
        listDivider: {
            //
        },
    };
};

export default styles;
