/**
 * @file      Installation.js
 *
 * @brief     Installation route controller.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Component, forwardRef } from 'react';

import { withStyles } from '@material-ui/core';

import {
    PAGE_TITLE_PREFIX,
    utcStringFromDate,
    apiRoutesForInstallation,
    apiRoutesForInstallationParameter,
    apiRoutesForEntity,
    fetchResource,
    fetchAuthorisedResource,
    tokenErrorsReturned,
    parseResponseErrorsToString,
} from 'helpers/globalConstants';

import AppHeader from 'global_components/AppHeader';
import FlashMessage from 'global_components/FlashMessage';

import styles from 'global_styles/PageRootStyles';

import InstallationGrid from './InstallationGrid';
import MimicPopover from './MimicPopover';

const ForwardReferencedAppHeader = forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AppHeader scrollRef={ref} {...props} />
));

/**
 * React class-based component.
 */
class Installation extends Component {
    constructor(props) {
        super(props);
        /* Cancel all subscriptions and asynchronous tasks in componentWillUnmount method.
         * https://stackoverflow.com/questions/52061476/
         */
        this._isMounted = false;

        /* Create ref for parent div. */
        this.myRef = React.createRef();

        /**
         * URL parameter 'id' is an integer - validated by react-router.
         * If it does not correspond to an (authorised) installation,
         *   the API request will fail and we can display an error.
         */
        const { match } = this.props;
        const installationId = parseInt(match.params.id, 10);
        this.API_ROUTES_FOR_INSTALLATION = apiRoutesForInstallation(installationId);

        this.state = {
            id: installationId,
            entityId: null,
            name: null,
            rctType: null,
            /* Mimic availability. */
            mimicAvailable: false,
            mimicVisible: false,
            mimicUrl: '',
            /* Objects returned directly from API calls. */
            alarms: [],
            historicAlarms: [],
            installation: {},
            parameters: {},
            pulse: {},
            /* Parsed VPN connectivity. */
            vpn: {},
            /* API requests status. */
            loaded: false,
            flashError: '',
            /* Logs for graphing. */
            parameterLogs: [],
        };
        // this.pulseIntervalTimer = null;
        this.fetchInitialState = this.fetchInitialState.bind(this);
        this.fetchSecondaryState = this.fetchSecondaryState.bind(this);
        this.fetchParamData = this.fetchParamData.bind(this);
        this.parseInitialState = this.parseInitialState.bind(this);
        this.handleFlashMsgUpdate = this.handleFlashMsgUpdate.bind(this);
        this.clearFlashErrorMsg = this.clearFlashErrorMsg.bind(this);
        this.toggleMimicOpen = this.toggleMimicOpen.bind(this);
        this.handleEventlogNav = this.handleEventlogNav.bind(this);
    }

    /**
     * Lifecycle methods.
     */
    componentDidMount() {
        const { pageTitle } = this.props;
        document.title = `${pageTitle} | ${PAGE_TITLE_PREFIX}`;
        this._isMounted = true;
        this.fetchInitialState();
    }

    componentDidUpdate(prevProps, prevState) {
        const { pageTitle } = this.props;
        const { name, loaded } = this.state;

        /**
         * Fetch extra data from endpoints which might take (a lot) longer.
         * This series of: Mount -> fetchInitialState -> fetchSecondaryState
         * guarantees 3 render calls when we start up.
         */
        if (loaded && !prevState.loaded) {
            /* Update title with installation name. */
            document.title = `${name} ${pageTitle} | ${PAGE_TITLE_PREFIX}`;
            this.fetchSecondaryState();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Fetch initial data from API. Set Component state:
     *      alarms
     *      entityId
     *      flashError
     *      installation
     *      loaded
     *      name
     *      parameters
     *      pulse
     *      rctType
     *      vpn
     */
    fetchInitialState = () => {
        const { initialApiRoutes, token, history } = this.props;

        /**
         * Make multiple requests in parallel.
         * Promise.all() will resolve when all of the input promises have resolved.
         */
        const apiResponses = Promise.all(initialApiRoutes.map((route) => {
            const apiRoutesKey = Object.values(route)[0]; // each apiRoute object contains exactly one key-value pair.
            return fetchAuthorisedResource(this.API_ROUTES_FOR_INSTALLATION[apiRoutesKey], token);
        }));

        apiResponses.then((responses) => {
            /* Create arrays parallel to apiResponses. */
            const stateKeys = initialApiRoutes.map((route) => Object.keys(route)[0]);
            const apiRouteKeys = initialApiRoutes.map((route) => Object.values(route)[0]);

            let newComponentState = { flashError: '', loaded: false };
            let tokenNotValid = false;

            responses.forEach((res, idx) => {
                const stateKey = stateKeys[idx];
                const routeKey = apiRouteKeys[idx];
                const { dataKey } = this.API_ROUTES_FOR_INSTALLATION[routeKey];

                if (res.success) {
                    // *** DEBUG ***
                    // console.log(`${dataKey}`);
                    // console.log(res.data[dataKey]);
                    newComponentState[stateKey] = res.data[dataKey];
                } else {
                    if (tokenErrorsReturned(res.data)) {
                        tokenNotValid = true;
                    }

                    const errorsSummary = parseResponseErrorsToString(res.data, true); // include debug console output
                    /* If multiple requests fail, we only save the errors summary from the final failed request. */
                    newComponentState.flashError = errorsSummary;
                    newComponentState[stateKey] = {};
                }
            });

            if (tokenNotValid) {
                /**
                 * Token has expired OR user is not authorised OR installation does not exist.
                 * Go back to overview and let AuthenticatedRoute test token expiration status.
                 */
                history.push('/overview');
            }

            if (!newComponentState.flashError.length) {
                newComponentState.loaded = true;
                const parsedState = this.parseInitialState(newComponentState);
                newComponentState = { ...newComponentState, ...parsedState };
            }

            /* If component is no longer mounted we probably navigated away from the page mid-fetch. */
            if (this._isMounted) {
                this.setState(newComponentState);
            }
        });
    }

    /**
     * Fetch more data from API. Historic alarms query can be rather slow.
     * @TODO Mimic location is configurable.
     *       Query location and dashboard name when we pick up module settings for 'key indicators'.
     *       Mimic control permission will be saved in token at login.
     *
     * Set Component state:
     *      historicAlarms
     *      mimicAvailable
     *      mimicUrl
     */
    fetchSecondaryState = () => {
        const { token, userPrivileges } = this.props;
        const { entityId } = this.state;
        const { dashboard, roles } = userPrivileges;

        const mimicType = roles.includes('mimic-control') ? 'control' : 'monitor';
        const mimicRoute = apiRoutesForEntity(entityId, dashboard, mimicType).htmlMimic;

        const historicAlarmsRoute = this.API_ROUTES_FOR_INSTALLATION.historicAlarmsForInstallation;

        /* Request historic alarms for the last month. */
        const dateTimeNow = new Date();
        const dateTimeMinusOneMonth = new Date();
        dateTimeMinusOneMonth.setMonth(dateTimeMinusOneMonth.getMonth() - 1);

        const historicAlarmsPostData = {
            startDatetime: utcStringFromDate(dateTimeMinusOneMonth),
            endDatetime: utcStringFromDate(dateTimeNow),
        };

        /**
         * Make multiple requests in parallel.
         * Promise.all() will resolve when all of the input promises have resolved.
         */
        const apiResponses = Promise.all([
            fetchResource(mimicRoute),
            fetchAuthorisedResource(historicAlarmsRoute, token, historicAlarmsPostData),
        ]);

        apiResponses.then((responses) => {
            const [mimicRes, historicAlarmsRes] = responses;

            /* Test if HTML mimic URI exists. Throw away (HTML) data. */
            const mimicAvailable = mimicRes.success;
            const mimicUrl = (mimicAvailable) ? mimicRoute.url : '';

            /* Historic alarms API response. */
            let flashError = '';
            let historicAlarms = [];
            let tokenNotValid = false;

            if (historicAlarmsRes.success) {
                historicAlarms = historicAlarmsRes.data.alarms;
                // *** DEBUG ***
                // console.log('historicAlarms');
                // console.log(historicAlarms);
            } else {
                if (tokenErrorsReturned(historicAlarmsRes.data)) {
                    tokenNotValid = true;
                }
                flashError = parseResponseErrorsToString(historicAlarmsRes.data, true);
            }

            if (tokenNotValid) {
                /* Somehow we failed after API requests from fetchInitialState() succeeded. */
                // history.push('/overview');
            } else if (this._isMounted) {
                /* If component is no longer mounted we probably navigated away from the page mid-fetch. */
                this.setState({
                    mimicAvailable,
                    mimicUrl,
                    historicAlarms,
                    flashError,
                });
            }
        });
    }

    /**
     * Fetch datapoint logs from API.
     *
     * Set Component state: parameterLogs
     *
     * @param {Array}  parameterIds  An array of parameter ids.
     * @param {Object} dateRange     Date objects for range start and end.
     *
     */
    fetchParamData = (parameterIds, dateRange) => {
        if (this._isMounted) {
            this.setState({ flashError: 'Fetching graph data...' });
        }

        /* Date objects are local and contain timezone. Convert to UTC string. */
        const { start, end } = dateRange;
        const utcStart = utcStringFromDate(start);
        const utcEnd = utcStringFromDate(end);

        // *** DEBUG ***
        // console.log(`Fetch data for ${parameterIds.length} parameters. From ${utcStart} - ${utcEnd}`);

        const { token, history } = this.props;
        const { id } = this.state;
        const dataKey = 'logs'; // constant for all requests

        /**
         * Make multiple requests in parallel.
         * Promise.all() will resolve when all of the input promises have resolved.
         */
        const apiResponses = Promise.all(parameterIds.map((paramId) => {
            const route = apiRoutesForInstallationParameter(id, paramId).getLogsForParameter;
            const postData = {
                startDatetime: utcStart,
                endDatetime: utcEnd,
                interval: 5,
            };
            return fetchAuthorisedResource(route, token, postData);
        }));

        apiResponses.then((responses) => {
            let newComponentState = { parameterLogs: [], flashError: '' };
            let tokenNotValid = false;
            const logs = [];

            responses.forEach((res, idx) => {
                const paramId = parameterIds[idx];

                if (res.success) {
                    // *** DEBUG ***
                    // console.log(`Parameter ${paramId} logs retrieved`);
                    // console.log(res.data[dataKey]);
                    logs.push({
                        paramId,
                        logs: res.data[dataKey],
                    });
                } else {
                    if (tokenErrorsReturned(res.data)) {
                        tokenNotValid = true;
                    }

                    const errorsSummary = parseResponseErrorsToString(res.data, true); // include debug console output
                    /* If multiple requests fail, we only save the errors summary from the final failed request. */
                    newComponentState.flashError = errorsSummary;
                }
            });

            if (tokenNotValid) {
                /**
                 * Token became invalid sometime after first load and graph render.
                 * OR something completely unexpected happened on the backend.
                 * Reload this page. If the token is invalid we will end back at /login.
                 */
                console.error('Fetch logs token not valid');
                history.go(0);
            }

            if (!newComponentState.flashError.length) {
                /**
                 * Show message if all series are empty.
                 * Handled here rather than allowing child component to call handleFlashMsgUpdate
                 *   to avoid setting state during render function.
                 */
                const logLengths = logs.map((gd) => gd.logs.length);
                const fetchedDataIsEmpty = (logs.length && logLengths.every((len) => len === 0));
                const emptyLogsMsg = (fetchedDataIsEmpty) ? 'No data values found for time range.' : '';

                newComponentState = { ...newComponentState, parameterLogs: logs, flashError: emptyLogsMsg };
            }

            /* If component is no longer mounted we probably navigated away from the page mid-fetch. */
            if (this._isMounted) {
                this.setState(newComponentState);
            }
        });
    }

    /**
     * Parse API data.
     *
     * @param {Object} initialState    Returned API data: {installation, pulse, parameters, alarms}
     *
     * @return {Object}  A new Object to be (destructured and) used to setState().
     */
    parseInitialState = (initialState) => {
        const { installation, pulse } = initialState;
        const { id, entity, netrixserialnumber } = installation;

        /* pulse should contain a single object for this installation. */
        const {
            datapost,
            vpnConnect,
            vpnContact,
            vpnWorkingCount,
        } = pulse[id];

        const { name } = entity;
        const entityId = entity.id;
        const rctType = netrixserialnumber.split('/')[0].toLowerCase(); // dx3

        /**
         * Where database fields: {lastcontact, vpnConnectedAt, vpnLastWorkedAt} are empty,
         *   API heartbeat keys: {datapost, vpnConnect, vpnContact} will be false.
         * The subraction below will evaluate to epochSecondsNow.
         */
        const epochSecondsNow = Math.floor(Date.now() / 1000);
        const vpnSecondsSinceContact = epochSecondsNow - vpnContact;
        const vpnActive = (vpnWorkingCount > 0) ? 'true' : 'false';

        const vpnState = {
            datapostEpochSeconds: datapost,
            vpnActive,
            vpnConnectEpochSeconds: vpnConnect,
            vpnLastContactEpochSeconds: vpnContact,
            vpnSecondsSinceContact,
        };

        const parsedState = {
            name,
            entityId,
            rctType,
            vpn: vpnState,
        };

        return parsedState;
    }

    /* FlashMessage callback passed down to InstallationGrid. */
    handleFlashMsgUpdate = (message) => {
        if (this._isMounted) {
            this.setState((st) => ({ ...st, flashError: message }));
        }
    }

    clearFlashErrorMsg = () => {
        if (this._isMounted) {
            this.setState((st) => ({ ...st, flashError: '' }));
        }
    }

    toggleMimicOpen = () => {
        if (this._isMounted) {
            this.setState((st) => ({ ...st, mimicVisible: !st.mimicVisible }));
        }
    }

    handleEventlogNav = () => {
        const { history } = this.props;
        const { id } = this.state;
        const eventlogPath = `/installation/${id}/eventlog`;
        /* Unmount Installation and navigate to EventLog. */
        history.push(eventlogPath);
    }

    render() {
        const { classes, pageTitle, userPrivileges } = this.props;
        const { dashboard } = userPrivileges;
        const {
            id,
            name,
            rctType,
            alarms,
            historicAlarms,
            parameters,
            vpn,
            mimicAvailable,
            mimicVisible,
            mimicUrl,
            loaded,
            flashError,
            parameterLogs,
        } = this.state;

        const rootClasses = [classes.root];
        if (mimicVisible) rootClasses.push(classes.popoverBlur);

        return (
            <div ref={this.myRef} className={clsx(rootClasses)}>
                <div className={classes.header}>
                    <ForwardReferencedAppHeader
                        ref={this.myRef}
                        titleMsg={pageTitle}
                        userLoggedIn
                        dashName={dashboard}
                    />
                </div>
                <div className={classes.main}>
                    {loaded
                        && (
                            <InstallationGrid
                                id={id}
                                name={name}
                                model={rctType}
                                alarms={alarms}
                                historicAlarms={historicAlarms}
                                parameters={parameters}
                                vpn={vpn}
                                fetchParamData={this.fetchParamData}
                                updateFlashMsg={this.handleFlashMsgUpdate}
                                mimicAvailable={mimicAvailable}
                                toggleMimicOpen={this.toggleMimicOpen}
                                handleEventlogNav={this.handleEventlogNav}
                                parameterLogs={parameterLogs}
                            />
                        )}
                    {/* Loading message. */}
                    <FlashMessage
                        isOpenCondition={!loaded}
                        onCloseCallback={null}
                        flashType="loading"
                        messageNode="Loading"
                    />
                    {/* Error messages. */}
                    <FlashMessage
                        isOpenCondition={!!flashError}
                        autoHideAfterMs={5000}
                        onCloseCallback={this.clearFlashErrorMsg}
                        flashType="info"
                        messageNode={flashError}
                    />
                    {/* Mimic. */}
                    {mimicVisible
                    && (
                        <MimicPopover
                            name={name}
                            url={mimicUrl}
                            toggleMimicOpen={this.toggleMimicOpen}
                        />
                    )}
                </div>
            </div>
        );
    }
}

/**
 * Typecheck props in development mode.
 *
 * @param {string} pageTitle           Browser tab title.
 * @param {string} token               Dashboard API token.
 * @param {Array}  userPrivileges      Parsed token claims.
 * @param {Object} history             react-router-dom history.
 * @param {Object} match               react-router-dom match.
 * @param {Array}  initialApiRoutes    routeKey lookup array.
 * @param {Object} classes             JSS classes from withStyles.
 */
Installation.propTypes = {
    pageTitle: PropTypes.string.isRequired,

    token: PropTypes.string.isRequired,
    userPrivileges: PropTypes.shape({
        dashboard: PropTypes.string.isRequired,
        installations: PropTypes.arrayOf(PropTypes.number).isRequired,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,

    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        go: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.objectOf(PropTypes.string),
    }).isRequired,

    initialApiRoutes: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.string),
    ),

    classes: PropTypes.shape({
        root: PropTypes.string,
        popoverBlur: PropTypes.string,
        header: PropTypes.string,
        main: PropTypes.string,
    }).isRequired,
};

/**
 * Default props are resolved by React before PropTypes typechecking.
 */
Installation.defaultProps = {
    /* Keys are members of this.state, Values (routeKeys) returned from apiRoutesForInstallation. */
    initialApiRoutes: [
        { installation: 'singleInstallation' },
        { pulse: 'heartbeatForInstallation' },
        { parameters: 'paramsForInstallation' },
        { alarms: 'activeAlarmsForInstallation' },
    ],
};

export default withStyles(styles)(Installation);
