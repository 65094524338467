/**
 * @file      ParamChartStyles.js
 *
 * @brief     ParamChart component styling.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
// import globalDexMaterialTheme from 'global_styles/globalDexMaterialTheme';
import sizes from 'global_styles/windowSizes';

const styles = (customTheme) => {
    if (customTheme) {
        // no custom theme handling
    }
    // const theme = globalDexMaterialTheme;
    return {
        container: {
            marginTop: 16,
            marginBottom: 32,
            marginLeft: 16,

            minWidth: 360,
            maxWidth: 1200,
            /* Extra Large devices (>=1600px) is the default. Order is important. */
            width: '80%',
            /**
             * Chart div ratio at 'desktop' 1920x1080
             * Parent div (InstallationGridStyles) is 76% of screen width.
             * This container width is 80% of parent.
             * 1920*0.76*0.8/665 = 1.755
             */
            height: 665,

            [sizes.widthMax('xl')]: {
                width: '85%',
                /* Ratio at breakpoint: 1600*0.85*0.85/660 ~= 1.75 */
                height: 660,
            },
            [sizes.widthMax('lg')]: {
                marginLeft: 0,
                marginRight: 0,
                width: '100%',
                /* Ratio at breakpoint: 1200*0.92*1.0/630 ~= 1.75 */
                height: 630,
            },
            [sizes.widthMax('md')]: {
                width: '100%',
                /* Ratio at breakpoint: 992*0.95*1.0/540 ~= 1.745 */
                height: 540,
            },
            [sizes.widthMax('sm')]: {
                width: '100%',
                /* Ratio at breakpoint: 768*1.0*1.0/440 ~= 1.745 */
                height: 440,
            },
            [sizes.widthMax('xs')]: {
                width: '100%',
                /* Ratio at breakpoint: 576*1.0*1.0/330 ~= 1.745 */
                height: 330,
            },
        },
    };
};

export default styles;
