/**
 * @file      DateRangeSelector.js
 *
 * @brief     Components to select a relative or absolute date range.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import TimelineIcon from '@material-ui/icons/Timeline';
import WarningIcon from '@material-ui/icons/Warning';
import MinimizeIcon from '@material-ui/icons/Minimize';

import { makeStyles } from '@material-ui/core/styles';

import styles from './styles/DateRangeSelectorStyles';

const useStyles = makeStyles(styles);

/**
 * Generate a relative date range selection element.
 *
 * @param {Object}   classes               A hook returned by makeStyles to access custom classes.
 * @param {Object}   dateRange             Start and end epoch millisecond values.
 * @param {Function} setRelativeDateRange  Update parent date range state. Calls setDateRange hook.
 *
 * @returns {Obejct}  A React node.
 */
function generateRelativeSelection(classes, dateRange, setRelativeDateRange) {
    const { start, end } = dateRange;
    const currentDeltaMs = end - start;
    const dateTimeNow = new Date();
    const rangeEndsNow = (dateTimeNow - end) < 300000; // 5 minutes

    const intervals = [
        {
            name: 'Minutes',
            durationMs: 60000,
            lookback: [15, 30, 45],
        },
        {
            name: 'Hours',
            durationMs: 3600000,
            lookback: [1, 2, 6, 12],
        },
        {
            name: 'Days',
            durationMs: 86400000,
            lookback: [1, 2, 3, 4, 5, 6],
        },
        {
            name: 'Weeks',
            durationMs: 604800000,
            lookback: [1, 2, 4, 6],
        },
    ];

    return (
        intervals.map((intervalType) => {
            const { name, durationMs, lookback } = intervalType;
            return (
                <div key={`lookback-${name}`}>
                    <ListItem className={classes.intervalRow}>
                        <ListItemText className={classes.intervalDesc} primary={name} />
                        <ListItemSecondaryAction>
                            {lookback.map((delta) => {
                                const deltaMs = durationMs * delta;
                                const currentlySelected = rangeEndsNow && (currentDeltaMs === deltaMs);
                                return (
                                    <Button
                                        key={`lookback-${name}-${delta}`}
                                        className={classes.intervalButton}
                                        variant={currentlySelected ? 'contained' : 'outlined'}
                                        color="primary"
                                        disableElevation
                                        onClick={() => setRelativeDateRange(deltaMs)}
                                    >
                                        {delta}
                                    </Button>
                                );
                            })}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider
                        key={`lookback-${name}-divide`}
                        className={classes.listDivider}
                        variant="inset"
                        component="li"
                    />
                </div>
            );
        })
    );
}

/**
 * Generate an absolute date range selection element.
 *
 * @param {Object}   classes               A hook returned by makeStyles to access custom classes.
 * @param {Object}   dateRange             Start and end epoch millisecond values.
 * @param {Function} setAbsoluteDateRange  Update parent date range state. Calls setDateRange hook.
 *
 * @returns {Obejct}  A React node.
 */
function generateAbsoluteSelection(classes, dateRange, setAbsoluteDateRange) {
    const { start, end } = dateRange;

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() > selectedDate.getTime();
    };

    return (
        <>
            <ListItem key="datepicker-start" className={classes.intervalRow}>
                <ListItemText className={classes.intervalDesc} primary="Start" />
                <div className={classes.calendar}>
                    <DatePicker
                        selected={start}
                        onChange={(date) => setAbsoluteDateRange({ start: date, end })}
                        dateFormat="MMMM d, yyyy  HH:mm"
                        // maxDate={end} // slightly confusing when we don't see both calendars together
                        maxDate={new Date()}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        filterTime={filterPassedTime}
                        fixedHeight
                        popperPlacement="bottom-end"
                    />
                </div>
            </ListItem>
            <Divider key="datepicker-start-divide" className={classes.listDivider} variant="inset" component="li" />

            <ListItem key="datepicker-end" className={classes.intervalRow}>
                <ListItemText className={classes.intervalDesc} primary="End" />
                <div className={classes.calendar}>
                    <DatePicker
                        selected={end}
                        onChange={(date) => setAbsoluteDateRange({ start, end: date })}
                        dateFormat="MMMM d, yyyy  HH:mm"
                        maxDate={new Date()}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        filterTime={filterPassedTime}
                        fixedHeight
                        popperPlacement="bottom-end" // previously "left-start"
                    />
                </div>
            </ListItem>
            <Divider key="datepicker-end-divide" className={classes.listDivider} variant="inset" component="li" />
        </>
    );
}

/**
 * React functional component.
 *
 * @param {Object} props    React props.
 */
function DateRangeSelector(props) {
    const {
        dateRange,
        setDateRange,
        showAlarmRegions,
        toggleAlarmRegions,
        showYAxisOrigin,
        toggleYAxisOrigin,
        plotEnabled,
        handlePlotRequest,
        updateFlashMsg,
    } = props;

    const classes = useStyles();

    /* Reserve state. 0 is relative, 1 is absolute. */
    const [tabIdx, setTabIdx] = useState(0);

    /* Set DatePicker inputs readonly on tab change to prevent mobile keyboard popup. */
    useEffect(() => {
        const datePickers = document.getElementsByClassName('react-datepicker__input-container');
        Array.from(datePickers).forEach((elem) => elem.childNodes[0].setAttribute('readOnly', true));
    }, [tabIdx]);

    const handleRelativeDateChange = (deltaMs) => {
        /* Any timezone conversion for DB query will happen in Installation. */
        const end = new Date();
        const start = new Date(end.valueOf() - deltaMs);

        if (start >= end) {
            updateFlashMsg('Range start must come before end.');
        } else {
            setDateRange({ start, end });
        }
    };

    const handleAbsoluteDateChange = (newRange) => {
        const { start, end } = newRange;
        if (start >= end) {
            updateFlashMsg('Range start must come before end.');
        } else {
            setDateRange(newRange);
        }
    };

    const handleTabIdxChange = (event, newIdx) => {
        if ([0, 1].includes(newIdx)) {
            setTabIdx(newIdx);
        }
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.titleText} variant="h5">
                Time Range
            </Typography>

            <AppBar className={classes.rangeTypeTab} position="static" color="default" elevation={0}>
                <Tabs
                    value={tabIdx}
                    onChange={handleTabIdxChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Relative" />
                    <Tab label="Absolute" />
                </Tabs>
            </AppBar>

            <List className={classes.datePickerContainer}>
                {(tabIdx === 0)
                    ? generateRelativeSelection(classes, dateRange, handleRelativeDateChange)
                    : generateAbsoluteSelection(classes, dateRange, handleAbsoluteDateChange)}
                <ListItem key="list-toggle-alarms" className={classes.intervalRow}>
                    <ListItemText
                        className={classes.intervalDesc}
                        primary="Show Alarms"
                        secondary="View historic alarm regions"
                    />
                    <ListItemSecondaryAction>
                        <Tooltip
                            disableFocusListener
                            enterDelay={400}
                            placement="bottom"
                            title="Highlight any alarms from the previous month on the parameter graph."
                        >
                            <Button
                                className={classes.alarmToggle}
                                // disabled={} // date range outside previous month
                                variant={showAlarmRegions ? 'contained' : 'outlined'}
                                size="large"
                                startIcon={<WarningIcon />}
                                onClick={toggleAlarmRegions}
                                color="primary"
                                disableElevation
                            >
                                Alarms
                            </Button>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem key="list-toggle-y-origin" className={classes.intervalRow}>
                    <ListItemText
                        className={classes.intervalDesc}
                        primary="Toggle Origin"
                        secondary="Display y-axis zero"
                    />
                    <ListItemSecondaryAction>
                        <Button
                            className={classes.alarmToggle}
                            variant={showYAxisOrigin ? 'contained' : 'outlined'}
                            size="large"
                            startIcon={<MinimizeIcon />}
                            onClick={toggleYAxisOrigin}
                            color="primary"
                            disableElevation
                        >
                            Origin
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem key="list-plot-data" className={classes.intervalRow}>
                    <ListItemText
                        className={classes.intervalDesc}
                        primary="Graph Data"
                        secondary="Plot series for selected parameters"
                    />
                    <ListItemSecondaryAction>
                        <Button
                            className={classes.plotButton}
                            disabled={!plotEnabled}
                            variant="contained"
                            size="large"
                            startIcon={<TimelineIcon />}
                            onClick={handlePlotRequest}
                        >
                            Plot
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider key="plot-button-end-divide" className={classes.listDivider} variant="inset" component="li" />
            </List>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}      dateRange           Start and end Date objects.
 * @param {Function}    setDateRange        useState hook for date start-end range.
 * @param {boolean}     showAlarmRegions    Visibility of historic alarms on graph.
 * @param {Function}    toggleAlarmRegions  useState hook for historic alarm graphing.
 * @param {boolean}     showYAxisOrigin     Visibility of y-axis origin.
 * @param {Function}    toggleYAxisOrigin   useState hook for y-axis origin toggle.
 * @param {boolean}     plotEnabled         Selected parameters and date range are OK.
 * @param {Function}    handlePlotRequest   Request parameter data to plot.
 * @param {Function}    updateFlashMsg      Flash message callback.
 */
DateRangeSelector.propTypes = {
    dateRange: PropTypes.shape({
        start: PropTypes.instanceOf(Object),
        end: PropTypes.instanceOf(Object),
    }).isRequired,
    setDateRange: PropTypes.func.isRequired,
    showAlarmRegions: PropTypes.bool.isRequired,
    toggleAlarmRegions: PropTypes.func.isRequired,
    showYAxisOrigin: PropTypes.bool.isRequired,
    toggleYAxisOrigin: PropTypes.func.isRequired,
    plotEnabled: PropTypes.bool.isRequired,
    handlePlotRequest: PropTypes.func.isRequired,
    updateFlashMsg: PropTypes.func.isRequired,
};

export default DateRangeSelector;
