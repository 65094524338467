/**
 * @file      globalConstants.js
 *
 * @brief     Helper functions.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2023. All Rights Reserved.
 *
 * @FIXME MWP 19-Jun-2021 This file needs to be split into (at least) three:
 *        network/route-helpers, time-helpers, other-helpers.
 *
 * @author    Malcolm Padley
 */
import axios from 'axios';
import axiosRetry from 'axios-retry';

const ACCOUNT_NAME = {
    regex: new RegExp(/^[A-Za-z0-9][A-Za-z0-9_.@-]*$/),
    maxChars: 255,
};

const develEnvironment = (process.env.REACT_APP_ENV === 'development');
/**
 * @NOTE Devel steps. Switch root URL to development server with http-rest-api.
 */
const RMS_ROOT_URL = (develEnvironment) ? process.env.REACT_APP_DEVEL_ROOT_URL : `https://${window.document.domain}`;
const APP_BASENAME = process.env.REACT_APP_RELATIVE_URL_STARTS;
const PAGE_TITLE_PREFIX = 'Dexdyne Enterprise Dashboard';

/**
 * Routes and response object keys as defined in API docs.
 * @NOTE Route keys (e.g. tokenGeneration) should be unique across *all* routes.
 *
 * @param {string}  url           URL of the resource to request data from.
 * @param {string}  method        HTTP request method in ['get', 'post', 'put', 'patch', 'delete']
 * @param {integer} responseOk    Expected HTTP response code on success.
 * @param {integer} retries       The number of times to retry on failure.
 * @param {string}  dataKey       Returned data key containing information of interest.
 *
 */
const API_ROUTES = {
    /* Auth. */
    // This route also sets a session cookie for the large dashboard.
    tokenGeneration: {
        url: `${RMS_ROOT_URL}/api/v1/token/dash/login`,
        method: 'post',
        responseOk: 200,
        retries: 2,
        dataKey: 'accessToken',
    },
    dashboardLogout: {
        url: `${RMS_ROOT_URL}/api/v1/token/dash/logout`,
        method: 'get',
        responseOk: 201,
        retries: 2,
        dataKey: null,
    },
    /* List available options. */
    allEntities: {
        url: `${RMS_ROOT_URL}/api/v1/entities`,
        method: 'get',
        responseOk: 200,
        retries: 2,
        dataKey: 'entities',
    },
    eventlogTypes: {
        url: `${RMS_ROOT_URL}/api/v1/dash/eventlog/types`,
        method: 'get',
        responseOk: 200,
        retries: 2,
        dataKey: 'types',
    },
    /* For installations authorized in token. */
    visibleInstallations: {
        url: `${RMS_ROOT_URL}/api/v1/dash/installations`,
        method: 'get',
        responseOk: 200,
        retries: 2,
        dataKey: 'installations',
    },
    installationsPulse: {
        url: `${RMS_ROOT_URL}/api/v1/dash/heartbeat`,
        method: 'get',
        responseOk: 200,
        retries: 0,
        dataKey: 'pulse',
    },
};

/* Parameterised Routes and response object keys as defined in API docs. */
const apiRoutesForInstallation = (id) => ({
    singleInstallation: {
        url: `${RMS_ROOT_URL}/api/v1/installations/${id}`,
        method: 'get',
        responseOk: 200,
        retries: 2,
        dataKey: 'installation',
    },
    paramsForInstallation: {
        url: `${RMS_ROOT_URL}/api/v1/installations/${id}/parameters`,
        method: 'get',
        responseOk: 200,
        retries: 2,
        dataKey: 'parameters',
    },
    activeAlarmsForInstallation: {
        url: `${RMS_ROOT_URL}/api/v1/installations/${id}/alarms`,
        method: 'get',
        responseOk: 200,
        retries: 2,
        dataKey: 'alarms',
    },
    historicAlarmsForInstallation: {
        url: `${RMS_ROOT_URL}/api/v1/installations/${id}/alarms/historic`,
        method: 'post',
        responseOk: 200,
        retries: 1,
        dataKey: 'alarms',
    },
    heartbeatForInstallation: {
        url: `${RMS_ROOT_URL}/api/v1/dash/heartbeat/${id}`,
        method: 'get',
        responseOk: 200,
        retries: 0,
        dataKey: 'pulse',
    },
    eventlogForInstallation: {
        url: `${RMS_ROOT_URL}/api/v1/dash/eventlog/${id}`,
        method: 'post',
        responseOk: 200,
        retries: 2,
        dataKey: 'logs',
    },
});

const apiRoutesForInstallationParameter = (instId, paramId) => ({
    getLogsForParameter: {
        url: `${RMS_ROOT_URL}/api/v1/installations/${instId}/parameters/${paramId}/log/bucket`,
        method: 'post',
        responseOk: 200,
        retries: 2,
        dataKey: 'logs',
    },
});

const apiRoutesForEntity = (entityId, dashboardName, mimicType = 'monitor') => ({
    /**
     * @NOTE MWP 25-Apr-2023
     * Selection of monitor/control based on JWT roles 'mimic-monitor'/'mimic-control'.
     * Requests sent with session cookie and processed by the large dashboard.
     */
    htmlMimic: {
        /**
         * Temporary mimic URI for use during development.
         * Skip mimic request auth to work around for cross-site issues.
         *
         * Extra separating forward slashes to match regular dashboard
         *   and allow the mimic JS to pick out the entity id.
         *
         * Add the following to Apache configuration:
         *     # Temporary mimic mod_alias.
         *     #
         *     Alias /mimic/live-access/la/c /etc/dexdyne/enterprise/mimics
         *     <Directory /etc/dexdyne/enterprise/mimics>
         *         Require all granted
         *     </Directory>
         */
        // url: `${RMS_ROOT_URL}/mimic/live-access/la/c/${dashboardName}/${entityId}/${mimicType}/main.html`,

        /* Production mimic URI. Requires large dashboard session cookie. */
        // eslint-disable-next-line max-len
        // url: `${RMS_ROOT_URL}/${dashboardName}/live-access/la/live-access/livem/${entityId}/_/${mimicType}/main.html`,

        /**
         * @NOTE Devel steps. Switch to debug mimic URL.
         */
        url: (develEnvironment)
            ? `${RMS_ROOT_URL}/mimic/live-access/la/c/${dashboardName}/${entityId}/${mimicType}/main.html`
            : `${RMS_ROOT_URL}/${dashboardName}/live-access/la/live-access/livem/${entityId}/_/${mimicType}/main.html`,
        method: 'get',
        responseOk: 200,
        retries: 1,
    },
});

/**
 * Local datetime formatting. Usage:
 *   const d = new Date(epochSeconds);
 *   const dStr = `${dateFormat.format(d)} @ ${timeFormat.format(d)} (${shortTzName})`;
 */
const LOCALISED_TIME = {
    shortTzName: /.*\s(.+)/.exec((new Date()).toLocaleDateString(navigator.language, { timeZoneName: 'short' }))[1],
    shortDateFormat: Intl.DateTimeFormat(navigator.language, { dateStyle: 'short' }),
    dateFormat: Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric' }),
    longDateFormat: Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'long', day: 'numeric' }),
    timeFormat: Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric', hour12: false }),
    graphFormat: Intl.DateTimeFormat(navigator.language, {
        // weekday: 'short',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        // timeZone: 'utc',
    }),
};

/**
 * Describe when a unix-timestamped event occurred. Relative to some implicit baseline (probably ~now).
 *
 * @param {number} timeDeltaSec         Event delta from some baseline. Unix epoch seconds.
 * @param {number} eventDateEpochSec    Event timestamp. Unix epoch seconds.
 *
 * @return {string}  Human-readable description of when this event ocurred.
 */
function dtToHumanReadableDescription(timeDeltaSec, eventDateEpochSec) {
    if (!eventDateEpochSec) {
        return 'Never Connected';
    }

    const dtMin = Math.round(timeDeltaSec / 60);
    const dtHour = Math.round(timeDeltaSec / 3600);
    const dtDay = Math.round(timeDeltaSec / 86400);

    let timeStr = '';

    if (dtMin < 2) {
        timeStr = 'Just Now';
    } else if (dtMin < 60) {
        timeStr = `${dtMin} minutes ago`;
    } else if (dtHour < 24) {
        timeStr = `${dtHour} hour${(dtHour > 1) ? 's' : ''} ago`;
    } else if (dtDay < 30) {
        timeStr = `${dtDay} day${(dtDay > 1) ? 's' : ''} ago`;
    } else {
        timeStr = new Date(eventDateEpochSec * 1000).toLocaleDateString();
    }
    return timeStr;
}

/**
 * Generate a local Date object from UTC datetime string.
 *
 * @param {string}  UTC string without timezone. Format 'YYYY-MM-DD HH:mm:ss'.
 *
 * @return {Object}  Date object.
 *
 */
function dateFromUtcString(dateTime) {
    /**
     * Create local Date object from database string. PostgreSQL format '2021-01-01 21:30:00'.
     *
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
     * Parsing of date strings with the Date constructor is discouraged due to browser differences.
     * However, support for ISO 8601 formats differs only in that date-only strings may be treated as UTC or local.
     */
    const dateAndTime = dateTime.split(' ');
    const utcIso8601 = `${dateAndTime[0]}T${dateAndTime[1]}Z`; // ISO 8601 format (UTC) '2021-01-01T21:30:00Z'
    return new Date(utcIso8601); // Local time
}

/**
 * Generate a UTC datetime string compatible with API from a Date object.
 *
 * @param {Object} date  Date object.
 *
 * @return {string}  UTC string without timezone: 'YYYY-MM-DD HH:mm:ss'
 */
function utcStringFromDate(date) {
    const dateStr = date.toISOString();

    /** toISOString() returns a string which is always 24 or 27 characters long.
     *  YYYY-MM-DDTHH:mm:ss.sssZ or ±YYYYYY-MM-DDTHH:mm:ss.sssZ
     */
    const splitStartChar = (dateStr.length === 27) ? 3 : 0;

    return dateStr.slice(splitStartChar, -5).split('T').join(' ');
}

/**
 * Clamp a number.
 *
 * @param {number} num    Number to clamp.
 * @param {number} min    Clamp num if less than min.
 * @param {number} max    Clamp num if greater than max.
 *
 * @return {number}
 */
function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}

/**
 * Promise to sleep.
 *
 * @param {number} time    Sleep time in milliseconds.
 */
function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * Generate an exponential delay for POST request retries.
 * Used by axios-retry.
 *
 * @param {number} retryNumber    The number of retries attempted so far.
 */
function retryDelayMs(retryNumber = 0) {
    // const seconds = Math.pow(2, retryNumber) * 1000;
    const seconds = (2 ** retryNumber) * 1000;
    const randomMs = 1000 * Math.random();
    // console.log(`retry ms ${seconds + randomMs}`);
    return seconds + randomMs;
}

/**
 * Use axios to make a request.
 *
 * @param {Object} route       Object in API_ROUTES.
 * @param {Object} headers     Request headers object.
 * @param {Object} postData    Object containing POST data. Null for GET requests.
 *
 * @returns {Promise}  Resolves to: { success: boolean, data: Object }
 *
 */
async function requestResource(route, headers, postData = null) {
    /* Axios request config. */
    const {
        url,
        method,
        responseOk,
        retries,
    } = route;

    const axiosConfig = {
        baseURL: url,
        method,
        headers,
        validateStatus: (status) => (status < 500), // Resolve if status code is less than 500
        // ...(postData ? { data: postData } : {}),
    };

    const client = axios.create(axiosConfig);

    axiosRetry(client, {
        retries,
        retryDelay: retryDelayMs,
        retryCondition: axiosRetry.isRetryableError, // Retry on Network Error and 5XX responses
    });

    const returnedResource = { success: null, data: null };
    /* Post data added to axiosConfig above does not get sent as request payload. */
    const pd = (postData) ? { data: postData } : {};

    return client.request(pd)
        .then((response) => {
            if (response.status === responseOk) {
                returnedResource.success = true;
            } else {
                /* Response (4XX). data.errors Object should be populated. */
                returnedResource.success = false;
            }
            returnedResource.data = response.data;
            return returnedResource;
        })
        .catch((error) => {
            if (error.response) {
                /* Response (5XX). Defined invalid for axios-retry above. data.errors Object might be populated. */
                if (error.response.data.errors) {
                    returnedResource.data = error.response.data;
                } else {
                    /* Recreate json errors format expected from API. */
                    returnedResource.data = { errors: { server: ['Server error. No data returned.'] } };
                }

                /* DEBUG */
                // console.log(error.response.data);
                // console.log(error.response.headers);
            } else if (error.request) {
                /* No response was received from server. */
                returnedResource.data = { errors: { network: ['Network error. No response from API endpoint.'] } };

                /* DEBUG */
                // console.log('No response from API endpoint. Giving up.');
                // console.log(error.request);
            } else {
                /* Error triggered while setting up the request. */
                returnedResource.data = { errors: { axios: [error.message] } };

                /* DEBUG */
                // console.log('Error', error.message);
            }

            returnedResource.success = false;
            return returnedResource;
        });
}

/**
 * Make a request to an unprotected API route.
 *
 * @param {Object} route       Object in API_ROUTES.
 * @param {Object} postData    Object containing POST data. Null for GET requests.
 *
 * @returns {Promise}  Return the result from requestResource().
 */
function fetchResource(route, postData = null) {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    if (!Object.prototype.hasOwnProperty.call(route, 'url')) {
        return Promise.resolve({
            success: false,
            data: {
                errors: {
                    route: ['Unknown endpoint'],
                },
            },
        });
    }
    return requestResource(route, headers, postData);
}

/**
 * Make a request to a protected API route.
 *
 * @param {Object} route       Object in API_ROUTES.
 * @param {string} token       JWT token.
 * @param {Object} postData    Object containing POST data. Null for GET requests.
 *
 * @returns {Promise}  Return the result from requestResource().
 */
function fetchAuthorisedResource(route, token, postData = null) {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    if (!Object.prototype.hasOwnProperty.call(route, 'url')) {
        return Promise.resolve({
            success: false,
            data: {
                errors: {
                    route: ['Unknown endpoint'],
                },
            },
        });
    }
    /* Add artificial latency. */
    // return sleep(2000).then(() => requestResource(route, headers, postData));
    return requestResource(route, headers, postData);
}

/**
 * data.errors object contains 'token' key. This probably indicates an invalid JWT.
 *
 * @param {Object} data    Returned by requestResource().
 *                         data.errors object should contain array(s) of error strings.
 *
 * @return {boolean}  Token errors are present.
 *
 */
function tokenErrorsReturned(data) {
    // if data.errors contains 'token' we probably want to redirect to /login
    return !!data.errors && Object.keys(data.errors).includes('token');
}

/**
 * Parse data.errors object returned by API and summarise as a string for console/flash output.
 *
 * @param {Object}  data          Returned by requestResource().
 *                                data.errors object should contain array(s) of error strings.
 * @param {boolean} consoleLog    Log errors to console.
 *
 * @return {string}  Errors summary. Non-empty.
 *
 */
function parseResponseErrorsToString(data, consoleLog = false) {
    let consoleErrorMsg = 'API Request';
    let consoleErrorColors = []; // fancy console text formatting
    let flashErrorMsg = 'API Request.';

    if (!data.errors) {
        // We arrived here following an unsuccessful request but no errors object is present.
        // No idea what went wrong
        consoleErrorMsg += ' No errors object returned from request.';
        flashErrorMsg = consoleErrorMsg;
    } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, arr] of Object.entries(data.errors)) {
            consoleErrorMsg += `\n   %c${key}`;
            consoleErrorColors.push('background:white; color:red; text-decoration:underline;');

            consoleErrorMsg += arr.reduce((acc, val) => (`%c ${val}`), '');
            consoleErrorColors = consoleErrorColors.concat(Array(arr.length).fill('color:black;'));

            // for (let err of arr) {
            //     consoleErrorMsg += `%c ${err}`;
            //     consoleErrorColors.push('color:black;');
            // }

            flashErrorMsg += ` ${key} error.`;
        }
    }

    if (consoleLog) {
        console.error(consoleErrorMsg, ...consoleErrorColors);
    }

    return flashErrorMsg;
}

/**
 * Compare two Objects using a given property. Used as argument to Array.sort().
 * Assumes operands (a.orderBy, b.orderBy) have a comparison operator defined,
 *   otherwise we will end up comparing valueOf() / toString() values.
 *
 * @param {Object} a
 * @param {Object} b
 * @param {string} orderBy    Object key.
 *
 * @return {number}    [-1, 0, 1]
 */
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**
 * Sort table rows.
 *
 * @param {Array}  rows             Array of table row Objects to be sorted.
 * @param {string} sortParam        Row key to sort on.
 * @param {string} sortDirection    Direction in ['asc', 'desc'].
 *
 * @return {Array}    New array containing sorted table rows.
 */
function sortTableRowsOnHeading(rows, sortParam, sortDirection) {
    const comparator = (sortDirection === 'desc')
        ? (a, b) => descendingComparator(a, b, sortParam)
        : (a, b) => -descendingComparator(a, b, sortParam);

    const sortedRows = [...rows];
    /**
     * As of ES2019, Array.sort() is required to be stable. Already stable in browsers. See:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
     */
    return sortedRows.sort((a, b) => comparator(a, b));
}

export {
    dtToHumanReadableDescription,
    dateFromUtcString,
    utcStringFromDate,
    clamp,
    sleep,
    retryDelayMs,
    fetchResource,
    fetchAuthorisedResource,
    tokenErrorsReturned,
    parseResponseErrorsToString,
    apiRoutesForInstallation,
    apiRoutesForInstallationParameter,
    apiRoutesForEntity,
    sortTableRowsOnHeading,
    ACCOUNT_NAME,
    API_ROUTES,
    APP_BASENAME,
    LOCALISED_TIME,
    PAGE_TITLE_PREFIX,
    RMS_ROOT_URL,
};
