/**
 * @file      EntityInstallationsSubTable.js
 *
 * @brief     Installations table for a single parent entity.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { sortTableRowsOnHeading } from 'helpers/globalConstants';

import EntityInstallationsRow from './EntityInstallationsRow';

/**
 * React functional Component.
 *
 * @param {Object} props    React props.
 *
 * @returns {Object}  Material-UI TableHead component used in EntityInstallationsSubTable.
 */
function EntitySubTableHead(props) {
    const {
        classes,
        columnHeadings,
        order,
        orderBy,
        onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => onRequestSort(event, property);

    return (
        <TableHead>
            <TableRow>
                {columnHeadings.map((column) => (
                    <TableCell
                        key={column.id}
                        // padding={column.disablePadding ? 'none' : 'default'}
                        className={column.headerClassName}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'desc'}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                            {(orderBy === column.id) && (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

/**
 * React functional Component.
 *
 * @param {Object} props    React props.
 */
function EntityInstallationsSubTable(props) {
    const {
        classes,
        parentEntityName,
        columnHeadings,
        rows,
        showInstallation,
    } = props;

    /**
     * Table pagination.
     */
    const paginationOptions = [5, 10, 25];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(paginationOptions[0]);
    const paginationRequired = rows.length > paginationOptions[0];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    /**
     * Row sorting by column.
     */
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('datapostEpochSeconds');

    const handleRequestSort = (event, property) => {
        const isDesc = (orderBy === property && order === 'desc');
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    /**
     * Table collapse.
     */
    const [tableVisible, setTableVisible] = useState(true);
    const toggleTableVisible = () => setTableVisible(!tableVisible);

    return (
        <div className={classes.parentEntityContainer}>
            <div className={classes.parentEntityControl}>
                <Typography
                    variant="button"
                    align="left"
                    display="inline"
                    className={classes.parentText}
                >
                    {parentEntityName}
                </Typography>
                <Button
                    variant="text"
                    size="large"
                    className={classes.parentShowHideButton}
                    onClick={toggleTableVisible}
                    endIcon={(tableVisible) ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                >
                    {(tableVisible) ? 'Hide' : 'Show'}
                </Button>
            </div>

            <Collapse
                in={tableVisible}
                timeout={{ appear: 500, enter: 500, exit: 300 }}
                collapsedHeight="57px"
            >
                <Paper elevation={4} square>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="Installation Table">
                            <EntitySubTableHead
                                classes={classes}
                                columnHeadings={columnHeadings}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {sortTableRowsOnHeading(rows, orderBy, order)
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <EntityInstallationsRow
                                            key={row.id}
                                            classes={classes}
                                            columnHeadings={columnHeadings}
                                            row={row}
                                            showInstallation={showInstallation}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {(paginationRequired) && (
                        <TablePagination
                            className={classes.tablePagination}
                            rowsPerPageOptions={paginationOptions}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    )}
                </Paper>
            </Collapse>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {string}   parentEntityName    The common parent entity shared by all installations.
 * @param {Array}    columnHeadings      Table headings and styles for each column.
 * @param {Array}    rows                Installation table row data. Not examined here,
 *                                       simply passed to EntityInstallationsRow
 * @param {Function} showInstallation    Navigation callback passed down to EntityInstallationsRow.
 * @param {Object}   classes             material-ui hook to access custom CSS classes.
 */
EntityInstallationsSubTable.propTypes = {
    parentEntityName: PropTypes.string.isRequired,
    columnHeadings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            cellClassName: PropTypes.string.isRequired,
            headerClassName: PropTypes.string.isRequired,
        }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showInstallation: PropTypes.func.isRequired,

    classes: PropTypes.shape({
        parentEntityContainer: PropTypes.string,
        parentEntityControl: PropTypes.string,
        parentText: PropTypes.string,
        parentShowHideButton: PropTypes.string,
        tableContainer: PropTypes.string,
        visuallyHidden: PropTypes.string,
        tablePagination: PropTypes.string,
    }).isRequired,
};

/**
 * @param {Array}    columnHeadings    Table headings and styles for each column.
 * @param {string}   order             Row order direction - ascending or descending.
 * @param {string}   orderBy           columnHeading id to order rows on.
 * @param {Function} onRequestSort     Wrapper to call row ordering hooks.
 * @param {Object}   classes           material-ui hook to access custom CSS classes.
 */
EntitySubTableHead.propTypes = {
    columnHeadings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            cellClassName: PropTypes.string.isRequired,
            headerClassName: PropTypes.string.isRequired,
        }),
    ).isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,

    classes: PropTypes.shape({
        visuallyHidden: PropTypes.string,
    }).isRequired,

};

export default EntityInstallationsSubTable;
