/**
 * @file      InstallationsTable.js
 *
 * @brief     Top level table container.
 *
 * @copyright Copyright Dexdyne Ltd. 2020-2021. All Rights Reserved.
 *
 * @author    Malcolm Padley
 */
import PropTypes from 'prop-types';
import React from 'react';

import { LOCALISED_TIME } from 'helpers/globalConstants';

import EntityInstallationsSubTable from './EntityInstallationsSubTable';

import styles from './styles/InstallationTableStyles';

/**
 * Define column headings and classes.
 * 'id' values reference props.tableRowsByParentEntity.
 *
 * @param {Object} classes  A hook returned by makeStyles to access custom classes.
 *
 * @returns  Table columns object. Passed down to EntityInstallationsSubTable.
 */
const columnHeadings = (classes) => ([
    {
        id: 'name',
        label: 'Installation',
        cellClassName: classes.tableCellName,
        headerClassName: classes.tableHeaderName,
    },
    {
        id: 'rctType',
        label: 'Model',
        cellClassName: classes.tableCellRctType,
        headerClassName: classes.tableHeaderRctType,
    },
    {
        id: 'alarmCount',
        label: 'Alarm',
        cellClassName: classes.tableCellAlarms,
        headerClassName: classes.tableHeaderAlarms,
    },
    {
        id: 'datapostEpochSeconds',
        label: `Datapost (${LOCALISED_TIME.shortTzName})`,
        cellClassName: classes.tableCellDatapost,
        headerClassName: classes.tableHeaderDatapost,
    },
    {
        id: 'vpnSecondsSinceContact',
        label: 'VPN',
        cellClassName: classes.tableCellVpnContact,
        headerClassName: classes.tableHeaderVpnContact,
    },
]);

/**
 * React functional Component.
 *
 * @param {Object} props    React props.
 */
function InstallationsTable(props) {
    const classes = styles();
    const { tableRowsByParentEntity, showInstallation } = props;

    /* Strings sorted case-insensitive ascending */
    const parentEntityNamesSorted = Object.keys(tableRowsByParentEntity).sort((a, b) => {
        const aLow = a.toLowerCase();
        const bLow = b.toLowerCase();
        if (aLow > bLow) {
            return 1;
        }
        if (aLow < bLow) {
            return -1;
        }
        return 0;
    });

    return (
        <div className={classes.root}>
            <div className={classes.allTablesContainer}>
                {parentEntityNamesSorted.map((entityName) => (
                    <EntityInstallationsSubTable
                        key={entityName}
                        classes={classes}
                        parentEntityName={entityName}
                        columnHeadings={columnHeadings(classes)}
                        rows={tableRowsByParentEntity[entityName]}
                        showInstallation={showInstallation}
                    />
                ))}
            </div>
        </div>
    );
}

/**
 * Typecheck props in development mode.
 *
 * @param {Object}   tableRowsByParentEntity    Arrays of table row data grouped by parent entity key.
 * @param {Function} showInstallation           Navigation callback passed down to EntityInstallationsRow.
 */
InstallationsTable.propTypes = {
    tableRowsByParentEntity: PropTypes.instanceOf(Object).isRequired,
    showInstallation: PropTypes.func.isRequired,
};

export default InstallationsTable;
